import { ComponentType, useContext, useMemo } from 'react'
import { Stack } from '@mui/material'
import dayjs from 'dayjs'
import _isNumber from 'lodash/isNumber'
import { StepTypes } from '@cango-app/sdk/types'

import { Button, Modal, Text } from 'src/components'
import { TaskContext } from 'src/providers'
import { WarningState } from 'src/modules/my-tasks-v3/components/complete-task-cta/types'

type TimeWarningModalProps = {
	warningState: WarningState
	setWarningState: (state: WarningState) => void
}

export const TimeWarningModal: ComponentType<TimeWarningModalProps> = ({
	warningState,
	setWarningState,
}) => {
	const { task } = useContext(TaskContext)

	const remainingTime = useMemo(() => {
		if (
			!task?.created_at ||
			!warningState.recommendedTimeFormat ||
			!_isNumber(warningState.recommendedTimeNumber)
		) {
			return ''
		}
		const dueTime = dayjs
			.unix(task.created_at)
			.add(warningState.recommendedTimeNumber, warningState.recommendedTimeFormat)

		const diffDays = dueTime.diff(dayjs(), 'day')
		if (diffDays > 0) {
			return `${diffDays} ${diffDays === 1 ? 'day' : 'days'}`
		}
		return `${dueTime.diff(dayjs(), 'hour')} hour(s)`
	}, [warningState, task])

	const warningText = useMemo(() => {
		if (!warningState.when) {
			return ''
		}
		if (warningState.when === StepTypes.WhenDueTime.before) {
			return 'The remaining time for this task is:'
		}
		return 'You are trying to complete this task ahead of time. You still have'
	}, [warningState.when])

	return (
		<Modal
			open={warningState.openWarning}
			onClose={() =>
				setWarningState({
					hasBeenSeen: true,
					openWarning: false,
				})
			}
		>
			<Stack direction="column" spacing={2}>
				<Text variant="h5">Are you sure you want to complete this task?</Text>
				{!!task && <Text>{`${warningText} ${remainingTime}`}</Text>}
				<Button
					onClick={() =>
						setWarningState({
							hasBeenSeen: true,
							openWarning: false,
						})
					}
				>
					Close
				</Button>
			</Stack>
		</Modal>
	)
}
