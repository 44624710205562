import { ChainTypes } from '@cango-app/sdk/types'
import { Stack } from '@mui/material'
import { useCallback, useContext, useState } from 'react'
import { v4 } from 'uuid'
import _isEqual from 'lodash/isEqual'

import { Modal, Text, IconButton, Button, TextField, Box } from 'src/components'
import { TrashIcon } from 'src/assets/icons'
import { showSnackbar } from 'src/helpers/snackbarManager'

import { ChainContext } from '../../chain-provider'

type Props = {
	onClose: () => void
}

export const EditExternalReferencesModal = ({ onClose }: Props) => {
	const { chain, updateChain } = useContext(ChainContext)
	const [externalNodes, setExternalNodes] = useState<ChainTypes.ChainExternalReference[]>(
		chain?.external_references ?? [],
	)

	const handleLabelChange = useCallback(
		(node: ChainTypes.ChainExternalReference, newLabel: string) => {
			setExternalNodes((currentNodes) => {
				return currentNodes.map((currentNode) => {
					if (currentNode._id === node._id) {
						return {
							...currentNode,
							label: newLabel,
						}
					}
					return currentNode
				})
			})
		},
		[],
	)
	return (
		<Modal open={true} onClose={onClose}>
			<Stack direction="column" spacing={2} width={400}>
				<Text variant="h5">External References</Text>
				{!externalNodes.length && <Text textAlign="center">No external references</Text>}
				{externalNodes.map((node) => (
					<Box key={node._id} display="flex" flex={1} flexDirection="row">
						<TextField
							containerProps={{
								flex: 1,
							}}
							fullWidth
							value={node.label}
							onChange={(e) => handleLabelChange(node, e.target.value)}
						/>
						<IconButton
							onClick={() => {
								const filteredNodes = externalNodes.filter(({ _id }) => node._id !== _id)
								setExternalNodes(filteredNodes)
							}}
						>
							<TrashIcon />
						</IconButton>
					</Box>
				))}
				<Button
					variant="text"
					onClick={() => {
						setExternalNodes((currentExternalNodes) => [
							...currentExternalNodes,
							{
								_id: v4(),
								label: '',
							},
						])
					}}
				>
					Add new
				</Button>
				<Stack pt={4} direction="column" spacing={1}>
					<Button fullWidth variant="text" onClick={onClose}>
						Close
					</Button>
					<Button
						onClick={async () => {
							if (externalNodes.some(({ label }) => label.length < 1)) {
								showSnackbar('External reference label cannot be empty', { variant: 'error' })
								return
							}
							await updateChain({
								external_references: externalNodes,
							})
							onClose()
						}}
						disabled={_isEqual(chain?.external_references, externalNodes)}
					>
						Save
					</Button>
				</Stack>
			</Stack>
		</Modal>
	)
}
