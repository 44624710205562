/**
 * Cango Project Database View
 */

import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import React, { ComponentType, useMemo } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useGridApiRef } from '@mui/x-data-grid-premium'
import { useSelector } from 'react-redux'

import { Box } from 'src/components'
import { CoreTable } from 'src/modules/tables/core-table'
import { TableProvider } from 'src/providers'
import { RootState } from 'src/store/types'
import { selectors as tableSelectors } from 'src/store/modules/tables'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'

import useConfirmCellChange from '../../../hooks/use-confirm-cell-change'

dayjs.extend(relativeTime)

export const DatabaseTable: ComponentType = () => {
	const apiRef = useGridApiRef()
	const [CellChangeDialog, confirmChange] = useConfirmCellChange(apiRef)
	const projectTableId = useSelector(projectSelectors.getSelectedProjectTableId)
	const connectedTables = useSelector((state: RootState) =>
		tableSelectors.selectAllConnectedTables(state, projectTableId ?? ''),
	)
	const tableList = useSelector(tableSelectors.selectTableList)
	const [currentTab, setCurrentTab] = React.useState(projectTableId)

	const allTabs = useMemo(() => {
		if (!projectTableId) {
			return []
		}
		return [
			{
				_id: projectTableId,
				label: 'Project table',
			},
			...connectedTables,
		]
	}, [connectedTables, tableList, projectTableId])

	return (
		<>
			<Tabs sx={{ paddingX: 4 }} value={currentTab} onChange={(e, value) => setCurrentTab(value)}>
				{allTabs.map((tab) => (
					<Tab
						key={tab._id}
						value={tab._id}
						label={tab.label}
						id={`tab-${tab._id}`}
						aria-controls={`tabpanel-${tab._id}`}
					/>
				))}
			</Tabs>
			{!!currentTab && (
				<Box px={4}>
					<TableProvider tableId={currentTab}>
						<CoreTable
							apiRef={apiRef}
							rowReordering={false}
							changeConfirmation={{
								Dialog: CellChangeDialog,
								onChangeCallback: confirmChange,
							}}
							maxHeight="calc(100vh - 350px)"
						/>
					</TableProvider>
				</Box>
			)}
		</>
	)
}
