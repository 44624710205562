import { ComponentType, useContext, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { useFormContext } from 'react-hook-form'
import { TableTypes, StepTypes, TaskTypes } from '@cango-app/sdk/types'
import { v4 } from 'uuid'
import { useSelector, useDispatch } from 'react-redux'
import _uniqBy from 'lodash/uniqBy'
import _uniq from 'lodash/uniq'

import { useListOptions } from 'src/hooks/use-list-options'
import { TaskContext } from 'src/providers'
import { Button } from 'src/components'
import {
	actions as projectActions,
	selectors as projectSelectors,
} from 'src/store/modules/projects-v3'
import { selectors as userSelectors } from 'src/store/modules/user'
import useConfirmRoleChains from 'src/hooks/use-confirm-role-chains'
import { ArchiveButtons } from 'src/modules/my-tasks-v3/components/archive-buttons'
import { AsyncDispatchType, RootState } from 'src/store/types'
import { completeActiveTask } from 'src/modules/my-tasks-v3/components/complete-task-cta/complete-active-task'
import { createTasksFromDescendants } from 'src/modules/my-tasks-v3/components/complete-task-cta/createTasks'
import { actions as tableActions, selectors as tableSelectors } from 'src/store/modules/tables'
import { showSnackbar } from 'src/helpers/snackbarManager'

import { CustomInputForm, OnPostCompleteTaskProps, TaskDbChain } from './types'

type CompleteCtaProps = {
	onPreComplete: () => 'continue' | 'cancel'
	onPostComplete?: (data: OnPostCompleteTaskProps) => void
	isResolved: boolean
	mods: Record<string, string>
}

export const CompleteCta: ComponentType<CompleteCtaProps> = ({
	onPreComplete,
	onPostComplete,
	mods,
}) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const state = useSelector((state: RootState) => state)
	const { watch, handleSubmit } = useFormContext<CustomInputForm>()
	const { task, selectedOptions, setSelectedOptions, descendants } = useContext(TaskContext)
	const [isCompleting, setIsCompleting] = useState(false)
	const [RoleAssignmentDialog, onConfirmRoles] = useConfirmRoleChains(state)
	const projectTasks = useSelector(projectSelectors.getProjectTasks)
	const projectRoles = useSelector(projectSelectors.mappedProjectRolesWithUsers)
	const chainStarters = useSelector(projectSelectors.getChainStarters)
	const projectSteps = useSelector(projectSelectors.getProjectSteps)
	const userId = useSelector(userSelectors.getCurrentUserId)
	const projectId = useSelector(projectSelectors.getSelectedProjectId)
	const questionnaireAnswers = useSelector(projectSelectors.getQuestionnaireAnswers)
	const cachedQuestionnaireAnswers = useSelector(projectSelectors.getProjectCachedAnswers)

	const parentTasks = useSelector((state: RootState) =>
		projectSelectors.getParentTasksOfTask(state, task),
	)
	const chainTasks = useSelector((state: RootState) =>
		projectSelectors.getProjectTasksInChain(state, task),
	)

	const listOptions = useListOptions({
		optionsWithFilter: task?.step?.complete_options ?? {
			options: [],
			filter: { items: [] },
			column_options: undefined,
			table_id: undefined,
		},
		filterModel: task?.chain?.database_chain_logic?.filters,
	})
	const instanceName = watch('instanceName')

	const isSubmitDisabled = useMemo(() => {
		if (!task) {
			return true
		}

		if (task.isMultiUse && !listOptions.length && !instanceName) {
			return true
		}

		if (listOptions.length && !selectedOptions.length) {
			return true
		}

		return isCompleting || task.lifecycle.complete
	}, [task, listOptions, isCompleting, instanceName, selectedOptions])

	const handleArchiveProject = async () => {
		if (!task) return
		const archiveDate = dayjs().unix()
		try {
			await dispatch(
				projectActions.updateProject({
					projectId: task.project_id,
					update: { archived: { state: true, at: archiveDate } },
				}),
			)
		} catch (error) {
			showSnackbar('Error archiving project', { variant: 'error' })
		}
	}

	const handleCompleteTaskClick = async () => {
		if (!task || onPreComplete() === 'cancel' || !projectId) {
			return
		}

		try {
			setIsCompleting(true)

			const roleDbLogic: {
				[descendantId: string]: TaskDbChain[]
			} = {}

			for (const descendant of descendants) {
				const databaseLogic = descendant.database_chain_logic
				if (!databaseLogic || !descendant.step) {
					continue
				}
				const mappedColumns = tableSelectors.selectMappedColumns(state, databaseLogic.table)
				const column = mappedColumns.get(databaseLogic.column)
				const columnType = column?.type

				if (!columnType) {
					continue
				}

				if (columnType === TableTypes.FieldType.ROLE) {
					const confirmResponse = await onConfirmRoles(databaseLogic.table, {
						filter: {
							...databaseLogic,
							descendantId: descendant._id,
							stepName: descendant.step.name,
						},
					})

					if (confirmResponse.status === 'rejected') {
						throw new Error('Error confirming roles')
					}
					const contactsForRoles = confirmResponse.data
					roleDbLogic[descendant._id] = Object.keys(contactsForRoles).map((_contactId) => ({
						_id: v4(),
						prefix: _contactId,
						labels: contactsForRoles[_contactId],
						column,
						table: undefined,
					}))
				}
			}

			const { updatedTask, taskUpdates } = completeActiveTask({
				activeTask: task,
				completed_options: selectedOptions,
				userId,
			})

			const { newTasks, copyFilesPayload, chainsToFetch, newChains } = createTasksFromDescendants({
				descendants,
				selectedOptions,
				projectTasks,
				completingTask: updatedTask,
				roleDbLogic,
				mappedProjectUsers: projectRoles,
				chain_starters: chainStarters,
				blueprintSteps: projectSteps,
				chainTasks,
				instanceName,
				state,
				mods: Object.keys(mods).map((_id) => ({ _id, mod: mods[_id] })),
			})

			if (newChains.length && taskUpdates.lifecycle?.completed_meta) {
				taskUpdates.lifecycle.completed_meta[
					taskUpdates.lifecycle.completed_meta.length - 1
				].chains_created = _uniq(newChains.map(({ _id }) => _id))
			}

			if (cachedQuestionnaireAnswers.length) {
				await dispatch(
					projectActions.updateProject({
						projectId,
						questionaire_answers: questionnaireAnswers,
					}),
				).unwrap()
			}
			await dispatch(tableActions.saveAllProjectChanges()).unwrap()

			const response = await dispatch(
				projectActions.completeTask({
					taskId: task._id,
					projectId: task.project_id,
					taskUpdates: {
						...taskUpdates,
						_id: task._id,
					},
					newTasks,
					copyFilesPayload,
				}),
			).unwrap()

			if (!response) {
				throw new Error('Error completing task')
			}

			setSelectedOptions([])

			let goToTask: OnPostCompleteTaskProps['nextTask'] | undefined = descendants.reduce(
				(_goToTask: OnPostCompleteTaskProps['nextTask'] | undefined, _child) => {
					if (_goToTask) {
						return _goToTask
					}
					const childStepId = _child.step?._id
					const nextTask = parentTasks.find((_task) => _task.step?._id === childStepId)
					if (_child.multi_use_config && nextTask) {
						return nextTask
					}
					return undefined
				},
				undefined,
			)

			if (!goToTask) {
				if (newTasks.length === 1) {
					goToTask = newTasks[0]
				} else if (newTasks.length > 1) {
					const nextTaskFromSection = newTasks.find((_task) => _task.section === task.section)
					if (nextTaskFromSection) {
						goToTask = nextTaskFromSection
					} else {
						goToTask = newTasks[0]
					}
				} else {
					const chainTaskIds = chainTasks.map((_task) => _task._id)
					const availableIncompleteTasks = projectTasks.filter((_task) => {
						return !_task.lifecycle.complete && _task.assignees.some(({ user }) => user === userId)
					})
					goToTask = availableIncompleteTasks.find((_task) => chainTaskIds.includes(_task._id))
				}
			}

			const multiUseTasksCompleted =
				descendants.reduce((_acc: TaskTypes.PopulatedTask[], _desc) => {
					if (_desc.chain_endings) {
						_desc.chain_endings.forEach((_end) => {
							const taskWithDesc = chainTasks.find((_task) =>
								_task.step?.descendants.some((_desc) => _desc._id === _end),
							)
							if (!taskWithDesc) {
								return
							}
							const nextStepIsChainOrigin = taskWithDesc.step === _desc.step
							if (taskWithDesc?.isMultiUse && !nextStepIsChainOrigin) {
								_acc.push(taskWithDesc)
							}
						})
					}
					return _uniqBy(_acc, '_id')
				}, []) ?? []

			for (const _task of multiUseTasksCompleted) {
				await dispatch(
					projectActions.updateTask({
						taskId: _task._id,
						projectId: task.project_id,
						'lifecycle.complete': true,
					}),
				).unwrap()
			}

			if (chainsToFetch.length) {
				await dispatch(
					projectActions.getProjectSteps({
						chainIds: chainsToFetch,
					}),
				)
			}

			if (onPostComplete) {
				onPostComplete({
					payload: response,
					nextTask: goToTask,
				})
			}
		} catch (error) {
			showSnackbar('Error completing task', { variant: 'error' })
		} finally {
			setIsCompleting(false)
		}
	}
	const taskElements = [...(task?.actions ?? []), ...(task?.attachments ?? [])]
	if (taskElements.some((_action) => _action.type === StepTypes.Action.ActionEnum.Archive)) {
		return (
			<ArchiveButtons
				onArchiveProject={handleArchiveProject}
				onCompleteTask={handleSubmit(handleCompleteTaskClick)}
			/>
		)
	}

	return (
		<>
			{RoleAssignmentDialog}
			<Button
				disabled={isSubmitDisabled}
				onClick={handleSubmit(handleCompleteTaskClick)}
				sx={{
					mt: { laptop: 2 },
				}}
				isLoading={isCompleting}
			>
				Mark as completed
			</Button>
		</>
	)
}
