import { TablesSdk } from '@cango-app/sdk/api'
import {
	List,
	ListItemButton,
	ListItemText as MuiListItemText,
	ListItemTextProps,
	ListSubheader,
	Stack,
} from '@mui/material'
import { AxiosError } from 'axios'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Modal, Text, Button, Box } from 'src/components'
import { RouteId } from 'src/constants/routes'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { selectors as tableSelectors, actions as tableActions } from 'src/store/modules/tables'

type ElementError = {
	_id: string
	name: string
}

type Error = { tables: ElementError[]; chains: ElementError[]; projects: ElementError[] }

type TableReferences =
	| {
			tables: ElementError[]
			chains: ElementError[]
			projects: ElementError[]
	  }
	| undefined

type Props = {
	onClose: () => void
	tableId: string
}

const ListItemText = (props: ListItemTextProps) => {
	return (
		<MuiListItemText
			sx={{
				'& .MuiTypography-root': {
					fontSize: '12px',
				},
			}}
			{...props}
		/>
	)
}

export const DeleteTableModal = ({ tableId, onClose }: Props) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [isDeletingTable, setIsDeletingTable] = useState(false)
	const [tableReferences, setTableReferences] = useState<TableReferences>()
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const tableList = useSelector(tableSelectors.selectTableList)

	const handleClose = useCallback(() => {
		setTableReferences(undefined)
		onClose()
	}, [onClose])

	const onDeleteTable = useCallback(
		async (tableId: string) => {
			try {
				setIsDeletingTable(true)
				await TablesSdk.deleteTable(import.meta.env.VITE_API as string, authHeaders, tableId)
				dispatch(
					tableActions.updateTableListItem({
						tableId,
						data: {
							_action: 'delete',
						},
						previousList: tableList,
					}),
				)
				const firstTableId = tableList[0]?._id ?? ''
				const tableRoute = firstTableId ? `/${firstTableId}` : ''
				navigate(`/${RouteId.Tables}${tableRoute}`)
			} catch (error) {
				const tablesUsingThisTable = (error as AxiosError<Error>).response?.data?.tables
				const chainsUsingThisTable = (error as AxiosError<Error>).response?.data?.chains
				const projectsUsingThisTable = (error as AxiosError<Error>).response?.data?.projects

				if (tablesUsingThisTable && chainsUsingThisTable && projectsUsingThisTable) {
					setTableReferences({
						chains: chainsUsingThisTable,
						tables: tablesUsingThisTable,
						projects: projectsUsingThisTable,
					})
				}
			} finally {
				setIsDeletingTable(false)
			}
		},
		[navigate, authHeaders],
	)

	return (
		<Modal open={!!tableId} onClose={handleClose}>
			<Stack direction="column" spacing={1} width={450}>
				<Text variant="h5">Would you like to delete this table?</Text>
				<Text variant="caption">
					The table will be deleted if there is no projects, references, vlookups, lookups or single
					select lookups using it.
				</Text>
				{tableReferences && (
					<Box maxHeight={400} overflow="auto">
						<List>
							{tableReferences.tables.length > 0 && (
								<Box>
									<ListSubheader>This table is used by:</ListSubheader>
									{tableReferences.tables.map(({ _id, name }) => {
										return (
											<ListItemButton key={_id}>
												<ListItemText
													primary={name}
													onClick={() => {
														const route = `/${RouteId.Tables}/${_id}`
														navigate(route)
														handleClose()
													}}
												/>
											</ListItemButton>
										)
									})}
								</Box>
							)}
							{tableReferences.projects.length > 0 && (
								<Box>
									<ListSubheader>The following projects use this table:</ListSubheader>
									{tableReferences.projects.map(({ _id, name }) => {
										return (
											<ListItemButton key={_id}>
												<ListItemText
													primary={name}
													onClick={() => {
														const route = `/${RouteId.Project}/${_id}`
														navigate(route)
														handleClose()
													}}
												/>
											</ListItemButton>
										)
									})}
								</Box>
							)}
							{tableReferences.chains.length > 0 && (
								<Box>
									<ListSubheader>The following chains use this table:</ListSubheader>
									{tableReferences.chains.map(({ _id, name }) => {
										return (
											<ListItemButton key={_id}>
												<ListItemText
													primary={name}
													onClick={() => {
														navigate(`/${RouteId.Blueprint}/${_id}`)
														onClose()
													}}
												/>
											</ListItemButton>
										)
									})}
								</Box>
							)}
						</List>
					</Box>
				)}
				<Stack direction="column" spacing={2}>
					<Button isLoading={isDeletingTable} color="error" onClick={() => onDeleteTable(tableId)}>
						Delete
					</Button>
					<Button disabled={isDeletingTable} variant="outlined" onClick={handleClose}>
						Cancel
					</Button>
				</Stack>
			</Stack>
		</Modal>
	)
}
