/**
 * Address book view
 */

import React, { useState, ComponentType, useEffect } from 'react'
import capitalize from 'lodash/capitalize'
import { useDispatch, useSelector } from 'react-redux'
import { DataGridPremium, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-premium'
import { UserSdk } from '@cango-app/sdk/api'
import _orderBy from 'lodash/orderBy'
import { Stack } from '@mui/material'

import { Button, Box, Text } from 'src/components'
import { selectors as userSelectors, actions as userActions } from 'src/store/modules/user'
import { selectors as roleSelectors } from 'src/store/modules/roles'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { selectors as configSelectors } from 'src/store/modules/config'
import { readableList } from 'src/helpers/labels'
import { dataGridStandard } from 'src/helpers/ui'
import { NewUser } from 'src/assets/icons'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { usePermissions } from 'src/hooks/usePermissions'

import UserDrawer from './user-drawer'
import { UpdatePermissionModal } from './update-permission-modal'

enum UserTableColumns {
	name = 'name',
	permissions = 'permissions',
	assignments = 'assignments',
	roles = 'roles',
	email = 'email',
	phone = 'phone',
	//last_active = 'last active',
}

export const columns: GridColDef[] = Object.values(UserTableColumns).map((id) => ({
	field: id,
	headerName: capitalize(id),
}))

const Users: ComponentType = () => {
	const dispatch = useDispatch()
	const users = useSelector(userSelectors.getAllUsers)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const organisationId = useSelector(configSelectors.getOrganisationId)
	const [selectedUser, setSelectedUser] = useState<string | undefined>()
	const [mappedAssignments, setMappedAssignments] = useState<Map<string, number>>(new Map())
	const [showingUserDrawer, setShowUserDrawer] = useState(false)
	const mappedRoles = useSelector(roleSelectors.getMappedRoles)
	const [isLoadingAssignments, setIsLoadingAssignments] = useState(false)
	const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])
	const { permissions } = usePermissions()
	const [showUpdatePermissionModal, setShowUpdatePermissionModal] = useState(false)
	const isLoadingUsers = useSelector(userSelectors.isLoadingUsers)
	const rows = users.map((user) => {
		const organisationRoles =
			user.organisations.find(({ organisation }) => organisation === organisationId)?.roles ?? []
		return {
			id: user._id,
			name: user?.name + ' ' + user?.surname,
			permissions: `Level ${user.organisations[0].permissions?.name ?? ''}`,
			assignments: isLoadingAssignments ? 'Loading' : (mappedAssignments.get(user._id) ?? 'None'),
			roles: readableList(organisationRoles.map((role) => mappedRoles.get(role)?.label || '')),
			email: user?.email,
			phone: user?.phone,
			__reorder__: user.name,
		}
	})

	const columnsWithSharedFields = columns.map((column) => ({
		...column,
		flex: 1,
	}))

	const handleFetchAssignments = async () => {
		try {
			setIsLoadingAssignments(true)
			const response = await UserSdk.getAssignments(import.meta.env.VITE_API as string, authHeaders)
			setMappedAssignments(new Map(response.map(({ _id, assignments }) => [_id, assignments])))
		} catch (error) {
			showSnackbar('Unable to fetch assignments')
		} finally {
			setIsLoadingAssignments(false)
		}
	}

	useEffect(() => {
		handleFetchAssignments()
	}, [])
	return (
		<>
			{permissions && (
				<UpdatePermissionModal
					open={showUpdatePermissionModal}
					onClose={() => setShowUpdatePermissionModal(false)}
					onSubmit={async (permissionId) => {
						const selectedPermission = permissions.find(({ _id }) => permissionId === _id)
						if (!selectedPermission) return
						dispatch(
							userActions.updateUsersPermissions({
								permission: selectedPermission,
								userIds: rowSelectionModel as string[],
							}),
						)
						setRowSelectionModel([])
					}}
					permissions={permissions}
				/>
			)}
			<UserDrawer
				open={showingUserDrawer}
				userId={selectedUser}
				setShowUserDrawer={setShowUserDrawer}
				setSelectedUser={setSelectedUser}
				permissions={permissions}
			/>

			<Box pt={4} px={4} display="flex" justifyContent="space-between">
				<Text variant="h4">Users</Text>
				<Stack direction="row" spacing={2}>
					<Button
						variant="text"
						onClick={() => setShowUpdatePermissionModal(true)}
						disabled={!rowSelectionModel.length}
					>
						Update Permissions
					</Button>
					<Button
						onClick={() => setShowUserDrawer(true)}
						variant="contained"
						startIcon={<NewUser fill="#fff" />}
					>
						New User
					</Button>
				</Stack>
			</Box>

			<DataGridPremium
				checkboxSelection
				onRowSelectionModelChange={(newRowSelectionModel) => {
					setRowSelectionModel(newRowSelectionModel)
				}}
				rowSelectionModel={rowSelectionModel}
				rows={_orderBy(rows, 'name')}
				columns={columnsWithSharedFields}
				disableRowSelectionOnClick
				onRowClick={({ id }) => {
					setShowUserDrawer(true)
					setSelectedUser(String(id))
				}}
				sx={{ ...dataGridStandard, m: 4 }}
				getRowHeight={() => 'auto'}
				hideFooter
				loading={isLoadingUsers}
			/>
		</>
	)
}

export default Users
