import { TaskTypes } from '@cango-app/sdk/types'
import _cloneDeep from 'lodash/cloneDeep'
import dayjs from 'dayjs'
import _uniqBy from 'lodash/uniqBy'

export const completeActiveTask = ({
	activeTask,
	completed_options,
	userId,
}: {
	activeTask: TaskTypes.PopulatedTask
	completed_options: TaskTypes.CompletedOption[]
	userId: string
}): {
	updatedTask: TaskTypes.PopulatedTask
	taskUpdates: Partial<TaskTypes.Task>
} => {
	const taskUpdates: Partial<Pick<TaskTypes.Task, 'lifecycle' | 'isFlagged'>> = {}

	const lifeCycle: TaskTypes.Task['lifecycle'] = {
		...activeTask.lifecycle,
		completed_meta: [
			...(activeTask.lifecycle.completed_meta ?? []),
			{
				at: dayjs().unix(),
				by: userId,
			},
		],
		completed_options: activeTask.isMenu
			? _uniqBy([...(activeTask.lifecycle.completed_options ?? []), ...completed_options], '_id')
			: [...(activeTask.lifecycle.completed_options ?? []), ...completed_options],
		...(activeTask.isMultiUse ? {} : { complete: true }),
	}

	taskUpdates.isFlagged = false
	taskUpdates.lifecycle = lifeCycle

	const updatedTask = {
		..._cloneDeep(activeTask),
		...taskUpdates,
	}

	return {
		updatedTask,
		taskUpdates,
	}
}
