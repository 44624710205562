import { PermissionsTypes } from '@cango-app/sdk/types'
import { Stack } from '@mui/material'
import { useState } from 'react'

import { Modal, Text, Select, Button } from 'src/components'

type Props = {
	open: boolean
	onClose: () => void
	permissions: PermissionsTypes.Permissions[]
	onSubmit: (permissionId: string) => Promise<void>
}

export const UpdatePermissionModal = ({ onClose, onSubmit, open, permissions }: Props) => {
	const [permissionId, setPermissionId] = useState('')
	return (
		<Modal open={open} onClose={onClose}>
			<Stack direction="column" spacing={2}>
				<Text variant="h4">Update Permissions:</Text>
				<Select
					options={permissions.map(({ _id, name }) => ({
						_id,
						label: `Level ${name}`,
					}))}
					value={permissionId}
					onChange={(e) => setPermissionId(e.target.value as string)}
				/>
				<Button
					onClick={async () => {
						await onSubmit(permissionId)
						setPermissionId('')
						onClose()
					}}
				>
					Update
				</Button>
			</Stack>
		</Modal>
	)
}
