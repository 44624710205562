import { ComponentType, useCallback, useEffect, useState, useContext } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ChainsSdk } from '@cango-app/sdk/api'
import { PulseLoader } from 'react-spinners'

import { CircledCheckIcon, EditPenIcon, PlusIcon, TrashIcon } from 'src/assets/icons'
import { Box, Button, IconButton, Modal, Text, TextField } from 'src/components'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { colors } from 'src/theme/colors'

import { ChainContext } from './chain-provider'

const BrowseListItem: ComponentType<{
	chainId: string
	name: string
	onClick: () => void
	isSelected: boolean
	onUpdateChainName: (newName: string) => Promise<void>
	onDeleteChain: (chainId: string) => Promise<void>
}> = ({ name, onClick, isSelected, chainId, onUpdateChainName, onDeleteChain }) => {
	const { chainIdLoading } = useContext(ChainContext)
	const [isEditingName, setIsEditingName] = useState(false)
	const [chainName, setChainName] = useState(name)
	const [isUpdatingName, setIsUpdatingName] = useState(false)
	const [isChainDeleting, setIsChainDeleting] = useState(false)

	const handleUpdateName = async () => {
		if (chainName === name) {
			setIsEditingName(false)
			return
		}
		try {
			setIsUpdatingName(true)
			await onUpdateChainName(chainName)
			setIsEditingName(false)
			showSnackbar('Chain name updated', { variant: 'success' })
		} catch (error) {
			showSnackbar('Error updating chain name', { variant: 'error' })
		} finally {
			setIsUpdatingName(false)
		}
	}

	const handleDeleteChain = async () => {
		const shouldDelete = confirm('Are you sure you want to delete this chain?')
		if (!shouldDelete) return
		setIsChainDeleting(true)
		try {
			await onDeleteChain(chainId)
			showSnackbar('Chain deleted', { variant: 'success' })
		} catch (error) {
			showSnackbar('Error deleting chain', { variant: 'error' })
		} finally {
			setIsChainDeleting(false)
		}
	}

	if (isEditingName) {
		return (
			<Box mx={2} display="flex" mb={1}>
				<TextField
					value={chainName}
					onChange={(e) => setChainName(e.target.value)}
					fullWidth
					containerProps={{ flex: 1 }}
					disabled={isUpdatingName}
				/>
				<Box display="flex" alignItems="center">
					<IconButton
						onClick={handleDeleteChain}
						isLoading={isChainDeleting}
						disabled={isChainDeleting}
					>
						<TrashIcon />
					</IconButton>
					<IconButton
						onClick={handleUpdateName}
						isLoading={isUpdatingName}
						disabled={isUpdatingName}
					>
						<CircledCheckIcon />
					</IconButton>
				</Box>
			</Box>
		)
	}

	return (
		<ListItem sx={{ pt: 0, pb: 1 }}>
			<ListItemButton selected={isSelected} onClick={onClick} sx={{ borderRadius: 2 }}>
				{/* <ListItemIcon sx={{ minWidth: 40 }}>{icon}</ListItemIcon> */}
				<ListItemText primaryTypographyProps={{ fontSize: 14 }} primary={name} />
				<ListItemIcon>
					{chainIdLoading === chainId ? (
						<PulseLoader size={4} />
					) : (
						<IconButton
							onClick={(event) => {
								event.stopPropagation()
								setIsEditingName(true)
							}}
							sx={{ ml: 1 }}
						>
							<EditPenIcon stroke={colors.feldgrau['60']} width={16} />
						</IconButton>
					)}
				</ListItemIcon>
			</ListItemButton>
		</ListItem>
	)
}

export const ChainsNavigation: ComponentType<{ selectedChainId?: string }> = ({
	selectedChainId,
}) => {
	const navigate = useNavigate()
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const [isLoadingChains, setIsLoadingChains] = useState(false)
	const [isCreating, setIsCreating] = useState(false)
	const [chains, setChains] = useState<ChainsSdk.GetChainsResponse>([])
	const [isChainModalOpen, setIsChainModalOpen] = useState(false)
	const [newChainName, setNewChainName] = useState('')

	const handleGoToChain = useCallback((chainId: string) => {
		navigate(`/chains/${chainId}`)
	}, [])

	const handleFetchChains = useCallback(async () => {
		setIsLoadingChains(true)
		try {
			const response = await ChainsSdk.getAll(import.meta.env.VITE_API as string, authHeaders, {
				blueprints: 'false',
			})
			setChains(response)
		} catch (error) {
			showSnackbar('Failed to fetch chains', { variant: 'error' })
		} finally {
			setIsLoadingChains(false)
		}
	}, [authHeaders])

	const handleCreateNewChain = useCallback(
		async (name: string) => {
			setIsCreating(true)
			try {
				const response = await ChainsSdk.create(import.meta.env.VITE_API as string, authHeaders, {
					name,
					isBlueprint: false,
				})
				setChains((prev) => [...prev, response])
				showSnackbar('Chain created', { variant: 'success' })
				setIsChainModalOpen(false)
				setNewChainName('')
				navigate(`/chains/${response._id}`)
			} catch (error) {
				showSnackbar('Failed to create chain', { variant: 'error' })
			} finally {
				setIsCreating(false)
			}
		},
		[authHeaders],
	)

	const handleUpdateChainName = useCallback(
		async (chainId: string, newName: string) => {
			await ChainsSdk.update(import.meta.env.VITE_API as string, authHeaders, chainId, {
				name: newName,
			})
			setChains((prev) =>
				prev.map((chain) => (chain._id === chainId ? { ...chain, name: newName } : chain)),
			)
		},
		[authHeaders],
	)

	const handleDeleteChain = useCallback(
		async (chainId: string) => {
			await ChainsSdk.deleteChain(import.meta.env.VITE_API as string, authHeaders, chainId)
			setChains((prev) => prev.filter((chain) => chain._id !== chainId))
			navigate('/chains')
		},
		[authHeaders],
	)

	useEffect(() => {
		handleFetchChains()
	}, [])

	return (
		<>
			<Modal open={isChainModalOpen} onClose={() => setIsChainModalOpen(false)}>
				<Box display="flex" flexDirection="column">
					<TextField
						onChange={(e) => setNewChainName(e.target.value)}
						value={newChainName}
						placeholder="New chain name"
						sx={{ mb: 2 }}
					/>
					<Button
						onClick={() => handleCreateNewChain(newChainName)}
						isLoading={isCreating}
						disabled={!newChainName}
					>
						Create Chain
					</Button>
				</Box>
			</Modal>
			<Box width={300} overflow="scroll">
				<List>
					<ListItem>
						<Button
							onClick={() => setIsChainModalOpen(true)}
							isLoading={isCreating}
							startIcon={<PlusIcon stroke="#fff" />}
							fullWidth
						>
							New chain
						</Button>
					</ListItem>
					<ListItem>
						<Text variant="overline" fontWeight="bold">
							Browse
						</Text>
					</ListItem>
					{!!isLoadingChains && <PulseLoader size={6} />}
					{chains.map((chain) => (
						<BrowseListItem
							key={chain._id}
							chainId={chain._id}
							name={chain.name}
							onClick={() => handleGoToChain(chain._id)}
							isSelected={chain._id === selectedChainId}
							onUpdateChainName={(newName) => handleUpdateChainName(chain._id, newName)}
							onDeleteChain={handleDeleteChain}
						/>
					))}
				</List>
			</Box>
		</>
	)
}
