import { StepTypes } from '@cango-app/sdk/types'
import { ComponentType, PropsWithChildren, useContext, useMemo, useState } from 'react'
import { Badge, Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { Text, Button, Modal, IconButton } from 'src/components'
import { EditPenIcon, TrashIcon } from 'src/assets/icons'
import { TemplatesContext } from 'src/providers/templates-provider'
import { ViewsButton } from 'src/modules/tables/views-button'
import { colors } from 'src/theme/colors'
import { TableContext } from 'src/providers'

import { TemplateButton } from './template-button'
import { TemplateUploadFieldsV2 } from './template-upload-fields-v2'
import {
	RoleSelect,
	NoteInput,
	LinkInput,
	TaskReferences,
	ACTIONS_REQUIRING_ROLES,
	ACTIONS_REQUIRING_LINK,
} from './actions'
import { StepFormType } from './step-form-container'
import { SetResourcesFields } from './set-resources'

type Props = {
	type: StepTypes.Action.ActionEnum
	attachmentIndex: number
	chainIndex: number
	update: (attachment: StepTypes.Action.StepAction) => void
	attachment: StepTypes.Action.StepAction
}

const AttachmentButton: ComponentType<
	PropsWithChildren<{ onClick: () => void; badgeContent: string | number }>
> = ({ children, badgeContent, onClick }) => {
	return (
		<Button variant="text" onClick={onClick}>
			<Badge badgeContent={badgeContent} color="primary">
				{children}
			</Badge>
		</Button>
	)
}

export const AttachmentsFields: ComponentType<Props> = ({
	attachment,
	update,
	chainIndex,
	attachmentIndex,
}) => {
	const { watch } = useFormContext<StepFormType>()
	const [openAttachmentModal, setOpenAttachmentModal] = useState(false)
	const [watchLinks, watchTemplate, watchFileIds, watchTaskReferences] = watch([
		`chain_actions.${chainIndex}.attachments.${attachmentIndex}.links`,
		`chain_actions.${chainIndex}.attachments.${attachmentIndex}.template.template`,
		`chain_actions.${chainIndex}.attachments.${attachmentIndex}.file_ids`,
		`chain_actions.${chainIndex}.attachments.${attachmentIndex}.task_references`,
	])
	const { templates } = useContext(TemplatesContext)

	if (attachment.type === StepTypes.Action.ActionEnum.None) {
		return (
			<Box flex={1} display="flex" justifyContent="center" alignItems="center">
				<Text>Select an attachment type</Text>
			</Box>
		)
	}

	if (ACTIONS_REQUIRING_ROLES.includes(attachment.type)) {
		return <RoleSelect action={attachment} update={update} />
	}

	if (attachment.type === StepTypes.Action.ActionEnum.Note) {
		return <NoteInput action={attachment} update={update} />
	}

	if (attachment.type === StepTypes.Action.ActionEnum.SetResources) {
		return (
			<>
				<Button variant="text" onClick={() => setOpenAttachmentModal(true)}>
					Select View
				</Button>
				<Modal open={openAttachmentModal} onClose={() => setOpenAttachmentModal(false)}>
					<Box width="550px">
						<SetResourcesFields action={attachment} update={update} />
					</Box>
				</Modal>
			</>
		)
	}

	if (attachment.type === StepTypes.Action.ActionEnum.Template) {
		const foundTemplate = templates.find(({ _id }) => watchTemplate === _id)
		return (
			<>
				<Button variant="text" onClick={() => setOpenAttachmentModal(true)}>
					{foundTemplate ? foundTemplate.name : 'Select Template'}
				</Button>
				<Modal open={openAttachmentModal} onClose={() => setOpenAttachmentModal(false)}>
					<Box width="750px">
						<TemplateButton
							chainIndex={chainIndex}
							actionIndex={attachmentIndex}
							type="attachments"
						/>
					</Box>
				</Modal>
			</>
		)
	}

	if (ACTIONS_REQUIRING_LINK.includes(attachment.type)) {
		return (
			<>
				<AttachmentButton
					badgeContent={watchLinks ? watchLinks.length : 0}
					onClick={() => setOpenAttachmentModal(true)}
				>
					Edit links
				</AttachmentButton>
				<Modal open={openAttachmentModal} onClose={() => setOpenAttachmentModal(false)}>
					<Box width="750px">
						<LinkInput
							actionIndex={attachmentIndex}
							chainIndex={chainIndex}
							typeOfAction="attachments"
						/>
					</Box>
				</Modal>
			</>
		)
	}

	if (attachment.type === StepTypes.Action.ActionEnum.TaskReference) {
		return (
			<>
				<AttachmentButton
					badgeContent={watchTaskReferences ? watchTaskReferences.length : 0}
					onClick={() => setOpenAttachmentModal(true)}
				>
					Edit Task References
				</AttachmentButton>
				<Modal open={openAttachmentModal} onClose={() => setOpenAttachmentModal(false)}>
					<Box width="450px">
						<TaskReferences
							actionIndex={attachmentIndex}
							chainIndex={chainIndex}
							typeOfAction="attachments"
						/>
					</Box>
				</Modal>
			</>
		)
	}

	if (attachment.type === StepTypes.Action.ActionEnum.FileTemplate) {
		return (
			<>
				<AttachmentButton
					badgeContent={watchFileIds ? watchFileIds.length : 0}
					onClick={() => setOpenAttachmentModal(true)}
				>
					Edit Templated file
				</AttachmentButton>
				<Modal open={openAttachmentModal} onClose={() => setOpenAttachmentModal(false)}>
					<Box width="450px">
						<TemplateUploadFieldsV2 attachmentIndex={attachmentIndex} chainIndex={chainIndex} />
					</Box>
				</Modal>
			</>
		)
	}

	return null
}
