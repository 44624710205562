import { ComponentType, useCallback, useState } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CommsSdk } from '@cango-app/sdk/api'
import { PulseLoader } from 'react-spinners'
import { useEffectOnce } from 'usehooks-ts'
import Badge from '@mui/material/Badge'

import { Box, Text } from 'src/components'
import { ActiveTasksInboxIcon, CompletedTasksInboxIcon, ProjectsIcon } from 'src/assets/icons'
import { selectors as notesSelectors } from 'src/store/modules/notes'
import {
	selectors as projectSelectors,
	actions as projectActions,
} from 'src/store/modules/projects-v3'
import { colors } from 'src/theme/colors'

const BrowseListItem: ComponentType<{
	onClick: (newState: CommsSdk.InboxNavState | string) => void
	navState: CommsSdk.InboxNavState | string
	name: string
	icon: JSX.Element
	unreadCount: number
}> = ({ navState, onClick, name, icon, unreadCount }) => {
	const activeInboxNavState = useSelector(notesSelectors.getInboxNavState)
	const isLoadingInbox = useSelector(notesSelectors.isLoadingInbox)
	const isItemSelected = activeInboxNavState === navState

	return (
		<ListItem sx={{ pt: 0, pb: 1 }}>
			<ListItemButton
				selected={isItemSelected}
				onClick={() => onClick(navState)}
				sx={{ borderRadius: 2 }}
			>
				<ListItemIcon sx={{ minWidth: 40 }}>{icon}</ListItemIcon>
				<Badge badgeContent={unreadCount} color="info">
					<ListItemText primaryTypographyProps={{ fontSize: 14 }} primary={name} />
				</Badge>
				<ListItemIcon>
					{!!isLoadingInbox && isItemSelected && <PulseLoader size={4} />}
				</ListItemIcon>
			</ListItemButton>
		</ListItem>
	)
}

const ProjectList: ComponentType<{ onClick: (projectId: string) => void }> = ({ onClick }) => {
	const dispatch = useDispatch()
	const projects = useSelector(projectSelectors.getProjectsListForSelect)
	const [isLoadingProjects, setIsLoadingProjects] = useState(false)
	const unreadTaskIds = useSelector(notesSelectors.getTasksWithUnreadNotes)

	const fetchProjects = useCallback(async () => {
		setIsLoadingProjects(true)
		await dispatch(projectActions.getProjectNames())
		setIsLoadingProjects(false)
	}, [])

	useEffectOnce(() => {
		fetchProjects()
	})

	return (
		<List>
			<ListItem>
				<Text variant="overline" fontWeight="bold">
					Projects
				</Text>
			</ListItem>

			{isLoadingProjects && (
				<Box display="flex" justifyContent="center">
					<PulseLoader size={6} />
				</Box>
			)}
			{!isLoadingProjects &&
				projects.map((project) => (
					<BrowseListItem
						key={project._id}
						onClick={() => onClick(project._id)}
						navState={project._id}
						name={project.label}
						icon={<ProjectsIcon fontSize="small" fill={colors.feldgrau['80']} width={20} />}
						unreadCount={unreadTaskIds.filter(({ projectId }) => projectId === project._id).length}
					/>
				))}
		</List>
	)
}

export const InboxNavigation: ComponentType = () => {
	const navigate = useNavigate()
	const unreadTaskIds = useSelector(notesSelectors.getTasksWithUnreadNotes)

	const handleGoToInboxState = useCallback((newState: CommsSdk.InboxNavState | string) => {
		navigate(`/inbox/${newState}`)
	}, [])

	return (
		<Box width={300} overflow="scroll">
			<List>
				<List>
					<ListItem>
						<Text variant="overline" fontWeight="bold">
							Browse
						</Text>
					</ListItem>
					<BrowseListItem
						navState={CommsSdk.InboxNavState.MyMessages}
						onClick={handleGoToInboxState}
						name="My messages"
						icon={<ActiveTasksInboxIcon fontSize="small" />}
						unreadCount={unreadTaskIds.filter(({ isComplete }) => !isComplete).length}
					/>
					<BrowseListItem
						navState={CommsSdk.InboxNavState.AllMessages}
						onClick={handleGoToInboxState}
						name="All messages"
						icon={<CompletedTasksInboxIcon fontSize="small" />}
						unreadCount={unreadTaskIds.filter(({ isComplete }) => !!isComplete).length}
					/>
				</List>
				<ProjectList onClick={handleGoToInboxState} />
			</List>
		</Box>
	)
}
