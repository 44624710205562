import { V3ProjectSdk } from '@cango-app/sdk/api'
import { ComponentType, useMemo, useState } from 'react'
import _every from 'lodash/every'

import { Box, Button } from 'src/components'

type SubmitButtonsProps = {
	updates: V3ProjectSdk.TaskResetUpdates
	onResetTask: (updates: V3ProjectSdk.TaskResetUpdates) => Promise<void>
	onCancelClick: () => void
	disabled?: boolean
}

export const SubmitButtons: ComponentType<SubmitButtonsProps> = ({
	onResetTask,
	updates,
	onCancelClick,
	disabled,
}) => {
	const [isResetingTasks, setIsResetingTasks] = useState(false)

	const handleResetTask = async () => {
		setIsResetingTasks(true)
		await onResetTask(updates)
		setIsResetingTasks(false)
	}

	const buttonProps = useMemo((): {
		color: 'primary' | 'warning' | 'error'
		message: string
	} => {
		if (updates.tasksToDelete.length && updates.optionsAdded.length) {
			return {
				color: 'warning',
				message: 'Delete tasks displayed in red and create new selections',
			}
		}
		if (updates.tasksToDelete.length) {
			return {
				color: 'error',
				message: 'Delete all tasks displayed in red',
			}
		}
		if (updates.optionsAdded.length) {
			return {
				color: 'primary',
				message: 'Create new selections',
			}
		}
		return {
			color: 'primary',
			message: 'Submit',
		}
	}, [updates])

	const areButtonsDisabled = useMemo(() => {
		if (isResetingTasks) {
			return true
		}

		return _every(updates, (update) => !update.length)
	}, [isResetingTasks, updates])

	return (
		<Box display="flex">
			<Button
				color={buttonProps.color}
				onClick={handleResetTask}
				isLoading={isResetingTasks}
				disabled={disabled || areButtonsDisabled}
				sx={{ mr: 1 }}
			>
				{buttonProps.message}
			</Button>
			<Button
				variant="text"
				onClick={onCancelClick}
				size="small"
				disabled={disabled || areButtonsDisabled}
				color={buttonProps.color}
			>
				Cancel
			</Button>
		</Box>
	)
}
