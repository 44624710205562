import AlertTitle from '@mui/material/AlertTitle'
import React, { ComponentType, useContext, useMemo, useState } from 'react'
import { StepTypes } from '@cango-app/sdk/types'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { v4 } from 'uuid'
import Alert from '@mui/material/Alert'
import FormControl from '@mui/material/FormControl'

import { CreateConditions } from 'src/modules/chains/components/chain-dependency-edge/create-conditions'
import { ApplyOptionSelectionToDatabase } from 'src/modules/chains/components/chain-dependency-edge/apply-option-selection-to-database'
import { CreateCustomChain } from 'src/modules/chains/components/chain-dependency-edge/create-custom-chain'
import { CreateDependencyForEveryOption } from 'src/modules/chains/components/chain-dependency-edge/create-dependency-for-every-option'
import { OptionConditions } from 'src/modules/chains/components/chain-dependency-edge/option-conditions'
import { colors } from 'src/theme/colors'
import { DatabaseMods } from 'src/modules/chains/components/chain-dependency-edge/database-mods'
import { DependencyTypeSelection } from 'src/modules/chains/components/chain-dependency-edge/dependency-type-selection'
import { Box, Button, Divider, IconButton, Modal, Text } from 'src/components'
import { CloseIcon } from 'src/assets/icons'
import { getThreads } from 'src/modules/chains/components/utils'
import { ChainEndingsSelection } from 'src/modules/chains/components/chain-dependency-edge/chain-endings-selection'

import { ChainContext } from '../../chain-provider'

import { DependencyForm, DependencyModalProps, DependencyType } from './types'
import { DependencyFromDatabase } from './dependency-from-database'
import { DuplicateRowSelection } from './duplicate-row-selection'

const getSelectedDependencyOption = (
	option_condition: StepTypes.Descendant['option_condition'],
) => {
	if (!option_condition) {
		return DependencyType.WHEN_THIS_TASK_COMPLETES
	}

	return DependencyType.WHEN_AN_OPTION_SELECTED
}

const DependencyModalComponent: ComponentType<DependencyModalProps> = (props) => {
	const { onClose, source, onDeleteConnection, child } = props
	const [isUpdatingConnection, setIsUpdatingConnection] = useState(false)
	const { onUpdateConnection, nodeMap, nodes } = useContext(ChainContext)
	const { watch, setValue, handleSubmit } = useFormContext<DependencyForm>()

	const [optionCondition, formCreateForEveryOption] = watch([
		'option_condition',
		'createForEveryOption',
	])

	const fromNodeId = optionCondition?.from ?? source
	const fromNode = nodeMap.get(fromNodeId)

	const canShowCustomChainInput = useMemo(() => {
		if (!fromNode || fromNode?.data.isMenu) {
			return false
		}
		return (
			!!fromNode.data.complete_options?.options && !fromNode.data.complete_options.column_options
		)
	}, [fromNode])
	const canShowCreateForEveryOption = useMemo(() => {
		return (
			!!fromNode?.data.complete_options?.options.length ||
			!!fromNode?.data.complete_options?.column_options
		)
	}, [fromNode])
	const shouldShowWarningBox = useMemo(() => {
		return canShowCreateForEveryOption || canShowCustomChainInput
	}, [canShowCreateForEveryOption, canShowCreateForEveryOption])

	const allThreads = useMemo(() => {
		return getThreads({
			nodeMap,
			nodeId: source,
		})
	}, [nodes, source])

	const dependencyType = useMemo((): DependencyType => {
		return getSelectedDependencyOption(optionCondition)
	}, [optionCondition, formCreateForEveryOption])

	const handleSaveDependencyType = async (data: DependencyForm) => {
		if (!child.step && !child.chain_complete_point) {
			return
		}

		setIsUpdatingConnection(true)

		let updatedThread: StepTypes.Thread | null = null

		if (data.createForEveryOption || data.chainPrefix) {
			const prefix =
				fromNode?.data.complete_options?.column_options ||
				(data.chainPrefix ?? child.thread?.prefix ?? '')
			updatedThread = {
				_id: child.thread?._id || v4(),
				prefix,
				table: fromNode?.data.complete_options?.table_id || child.thread?.table,
			}
		} else if (!data.chainPrefix && !data.createForEveryOption) {
			updatedThread = null
		}

		await onUpdateConnection({
			_id: child._id,
			connection: { source, target: child.step },
			method: 'update',
			option_condition: data.option_condition,
			createForEveryOption: data.createForEveryOption,
			thread: updatedThread,
			database_chain_logic: data.database_chain_logic,
			multiUseConfig: data.multiUseConfig,
			databaseChanges: data.database_changes ?? null,
			chain_endings: data.chain_endings,
			create_conditions: data.create_conditions,
			duplication: data.duplication,
		})

		setIsUpdatingConnection(false)
		onClose()
	}

	const handleDependencyTypeChange = (previousType: DependencyType, newType: DependencyType) => {
		if (previousType === newType) {
			return
		}
		if (newType === DependencyType.WHEN_THIS_TASK_COMPLETES) {
			setValue('option_condition', undefined, { shouldDirty: true })
			setValue('createForEveryOption', false)
			setValue('chainPrefix', '')
			setValue('database_changes', null)
			return
		}

		if (previousType === DependencyType.WHEN_THIS_TASK_COMPLETES) {
			setValue('option_condition', {
				values: [],
				operator: StepTypes.ChildConditionOperator.Is,
			})
			setValue('createForEveryOption', false)
		}
	}

	return (
		<Modal open={true}>
			<Box maxWidth={700} minWidth={400}>
				<Box position="absolute" right={16}>
					<IconButton onClick={onClose}>
						<CloseIcon width={12} />
					</IconButton>
				</Box>
				<Text variant="h6" mb={2}>
					Change dependency type
				</Text>
				<Box sx={{ width: '100%' }}>
					<FormControl sx={{ width: 600 }}>
						<DependencyTypeSelection
							onChange={(newDependencyType) =>
								handleDependencyTypeChange(dependencyType, newDependencyType)
							}
							value={dependencyType}
						/>
						{dependencyType === DependencyType.WHEN_AN_OPTION_SELECTED && (
							<OptionConditions source={source} />
						)}
						{shouldShowWarningBox ? (
							<Box mb={2} border="1px solid" borderColor={colors.warning.main} borderRadius={1}>
								<Alert severity="warning" sx={{ mb: 2 }}>
									<AlertTitle>Only 1 of these options can be chosen</AlertTitle>
									These options have have conflicting chain logic.
								</Alert>
								<Box px={2} pb={2}>
									{canShowCreateForEveryOption && <CreateDependencyForEveryOption />}
									{canShowCustomChainInput && <CreateCustomChain />}
									<DependencyFromDatabase />
								</Box>
							</Box>
						) : (
							<>
								<DependencyFromDatabase />
							</>
						)}
						<ApplyOptionSelectionToDatabase />
						<DatabaseMods />
						<DuplicateRowSelection source={source} />
						<CreateConditions />

						<Divider sx={{ py: 2 }} />
						<Box mt={2} mb={3}>
							<ChainEndingsSelection allThreads={allThreads} />
						</Box>
					</FormControl>
				</Box>
				<Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mt: 1 }}>
					<Button
						onClick={handleSubmit(handleSaveDependencyType)}
						isLoading={isUpdatingConnection}
						disabled={isUpdatingConnection}
					>
						Save
					</Button>
					<Button variant="outlined" color="error" onClick={onDeleteConnection}>
						Delete connection
					</Button>
				</Box>
			</Box>
		</Modal>
	)
}

export const DependencyModal: ComponentType<DependencyModalProps> = (props) => {
	const { child } = props
	const formProps = useForm<DependencyForm>({
		defaultValues: {
			createForEveryOption: child.createForEveryOption ?? false,
			chainPrefix: child.thread?.prefix ?? '',
			database_chain_logic: child.database_chain_logic ?? undefined,
			option_condition: child.option_condition,
			multiUseConfig: child.multi_use_config,
			database_changes: child.database_changes,
			chain_endings: child.chain_endings ?? [],
			create_conditions: child.create_conditions,
			duplication: child.duplication ?? undefined,
		},
	})

	return (
		<FormProvider {...formProps}>
			<DependencyModalComponent {...props} />
		</FormProvider>
	)
}
