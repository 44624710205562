import { PermissionsSDK } from '@cango-app/sdk/api'
import { PermissionsTypes } from '@cango-app/sdk/types'
import { useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { showSnackbar } from 'src/helpers/snackbarManager'
import { selectors as authSelectors } from 'src/store/modules/auth'

export const usePermissions = () => {
	const [isLoadingPermissions, setIsLoadingPermissions] = useState(false)
	const [permissions, setPermissions] = useState<PermissionsTypes.Permissions[] | undefined>(
		undefined,
	)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)

	const fetchPermissions = useCallback(async () => {
		setIsLoadingPermissions(true)
		try {
			const response = await PermissionsSDK.getPermissions(
				import.meta.env.VITE_API as string,
				authHeaders,
			)
			setPermissions(response)
		} catch (error) {
			showSnackbar('Error getting permissions', { variant: 'error' })
		} finally {
			setIsLoadingPermissions(false)
		}
	}, [authHeaders])

	useEffect(() => {
		fetchPermissions()
	}, [])

	return {
		setPermissions,
		permissions,
		isLoadingPermissions,
	}
}
