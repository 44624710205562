import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import _trim from 'lodash/trim'
import React, { ComponentType, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Accordion from '@mui/material/Accordion'
import { v4 } from 'uuid'

import { DependencyForm } from 'src/modules/chains/components/chain-dependency-edge/types'
import { ThreadList } from 'src/components/section-tasks-v3/chains-list'
import { Box, Checkbox, IconButton, Text, TextField } from 'src/components'

export const CreateCustomChain: ComponentType = () => {
	const { control, watch, setValue } = useFormContext<DependencyForm>()
	const [isExpanded, setIsExpanded] = useState(false)
	const [customChain, databaseLogic] = watch(['chainPrefix', 'database_chain_logic'])
	const isCustomChainSelected = !!customChain && !databaseLogic

	const handleCheckboxClicked = (isChecked: boolean) => {
		if (isChecked) {
			setValue('chainPrefix', ' ', { shouldDirty: true })
			setValue('database_chain_logic', null)
			setIsExpanded(true)
			return
		}
		setValue('chainPrefix', '')
	}

	return (
		<Accordion expanded={isExpanded && isCustomChainSelected}>
			<AccordionSummary
				expandIcon={
					<IconButton onClick={() => setIsExpanded(!isExpanded)} disabled={!isCustomChainSelected}>
						{<ExpandMoreIcon />}
					</IconButton>
				}
			>
				<Box display="flex" alignItems="center">
					<Checkbox
						onChange={(e, isChecked) => {
							e.stopPropagation()
							handleCheckboxClicked(isChecked)
						}}
						checked={isCustomChainSelected}
					/>
					<Text>Create a custom chain name</Text>
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				<Controller
					control={control}
					name="chainPrefix"
					render={({ field: { value: chainPrefix, onChange: onChainPrefixChange } }) => {
						return (
							<TextField
								label="Give this chain a name (optional)"
								value={chainPrefix}
								onChange={(e) => onChainPrefixChange(e.target.value)}
								containerProps={{ sx: { my: 2 } }}
								helperText={
									<Box>
										<Text
											fontSize={12}
										>{`This will appear on all chains for clarity of which task belongs to which chain.`}</Text>
										{!!_trim(chainPrefix) && (
											<Box display="flex" alignItems="center">
												<Text fontSize={12} mr={1}>
													{'e.g.'}
												</Text>
												<ThreadList
													threads={[
														{
															_id: v4(),
															prefix: chainPrefix,
															color: '#c4def6',
															table: undefined,
														},
													]}
												/>
											</Box>
										)}
									</Box>
								}
							/>
						)
					}}
				/>
			</AccordionDetails>
		</Accordion>
	)
}
