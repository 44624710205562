import { useState, useMemo, useContext, ComponentType } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Stack } from '@mui/material'
import { ChainTypes, StepTypes } from '@cango-app/sdk/types'

import { ChainReferenceNode, StandardNode } from 'src/modules/chains/types'
import { ChainContext } from 'src/modules/chains/chain-provider'
import { Box, Select, CreatableTextField, Divider, Button, IconButton } from 'src/components'
import { getActionLabel } from 'src/helpers/labels'
import { TrashIcon } from 'src/assets/icons'

import { StepFormType } from '../step-form-container'

type Props = { actionIndex: number; chainIndex: number; typeOfAction: 'actions' | 'attachments' }

export const TaskReferences: ComponentType<Props> = ({ actionIndex, chainIndex, typeOfAction }) => {
	const { control, watch } = useFormContext<StepFormType>()
	const stepId = watch('_id')
	const { nodes, chain, updateChain } = useContext(ChainContext)
	const [externalNodes, setExternalNodes] = useState<ChainTypes.ChainExternalReference[]>(
		chain?.external_references ?? [],
	)
	const { fields, append, remove, update } = useFieldArray({
		control,
		name: `chain_actions.${chainIndex}.${typeOfAction}.${actionIndex}.task_references`,
	})

	const taskOptions = useMemo(() => {
		const filteredNodes = nodes.filter(
			(_node) =>
				(_node.type === 'standard' || _node.type === 'chainReference') &&
				_node.id !== stepId &&
				!_node.data.isSection,
		) as (StandardNode | ChainReferenceNode)[]
		const optionNodes = filteredNodes.map((_node) => ({
			_id: _node.id,
			label: _node.data.name,
			group: 'Internal',
		}))
		if (chain?.is_blueprint) {
			return optionNodes
		}
		return [
			...optionNodes,
			...externalNodes.map((externalNode) => ({
				...externalNode,
				group: 'External',
			})),
		]
	}, [nodes, externalNodes, chain?.is_blueprint])

	return (
		<>
			<Box>
				{fields.map((field, fieldIndex) => (
					<>
						<Box key={field.task}>
							<Stack direction="column" spacing={1}>
								<Box display="flex" alignItems="center">
									{chain?.is_blueprint ? (
										<Select
											value={field.task}
											onChange={(e) =>
												update(fieldIndex, { ...field, task: e.target.value as string })
											}
											label="Select a task"
											options={taskOptions}
											containerProps={{ width: 375 }}
										/>
									) : (
										<CreatableTextField
											label="Select a task"
											value={field.task}
											onChange={(newValue) => {
												if (typeof newValue === 'string') {
													update(fieldIndex, { ...field, task: newValue })
													return
												}
												setExternalNodes((currenExternalNodes) => [
													...currenExternalNodes,
													newValue,
												])
												update(fieldIndex, {
													...field,
													task: newValue._id,
												})
												updateChain({
													external_references: [
														...externalNodes,
														{
															...newValue,
														},
													],
												})
											}}
											options={taskOptions}
										/>
									)}
									<Box>
										<IconButton sx={{ mt: 3, ml: 1 }} onClick={() => remove(fieldIndex)}>
											<TrashIcon width={14} />
										</IconButton>
									</Box>
								</Box>
							</Stack>
							<Select
								multiple
								label="Fields"
								value={field.fields}
								onChange={(e) =>
									update(fieldIndex, {
										...field,
										fields: e.target.value as StepTypes.Action.TaskReferenceEnum[],
									})
								}
								options={Object.values(StepTypes.Action.TaskReferenceEnum).map((type) => ({
									_id: type,
									label: getActionLabel(type),
								}))}
								containerProps={{ maxWidth: 375, mb: 1 }}
							/>
						</Box>
						<Divider sx={{ mb: 2 }} />
					</>
				))}
				<Button
					variant="outlined"
					size="small"
					onClick={() => {
						append({
							task: '',
							fields: [],
						})
					}}
				>
					Add a task reference
				</Button>
			</Box>
		</>
	)
}
