import { ComponentType, MouseEvent, useContext, useState } from 'react'
import { Handle, Position, NodeProps } from '@xyflow/react'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Box, IconButton, Text } from 'src/components'
import { PlusIcon } from 'src/assets/icons'
import { RouteId } from 'src/constants/routes'
import { ChainReferenceNode } from 'src/modules/chains/types'

import { ChainContext } from '../chain-provider'
import { useChainPermissions } from '../useChainPermissions'

const ChainNode: ComponentType<NodeProps<ChainReferenceNode>> = ({ data, isConnectable }) => {
	const navigate = useNavigate()
	const [, setSearchParams] = useSearchParams()
	const { onAddStep } = useContext(ChainContext)
	const [isCreatingStep, setIsCreatingStep] = useState(false)
	const { canEditChain } = useChainPermissions()

	const handleNewStepClick = async (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation()
		setIsCreatingStep(true)
		await onAddStep(data._id, () => {
			data.fitView()
		})
		setIsCreatingStep(false)
	}

	const goToChain = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation()
		navigate(`/${RouteId.Chains}/${data.chain_reference}`)
	}

	return (
		<>
			<Box
				bgcolor="#e5f6fd"
				py={1}
				px={2}
				width={200}
				borderRadius={1}
				position="relative"
				onClick={() =>
					setSearchParams({
						stepId: data._id,
					})
				}
			>
				<Handle type="target" position={Position.Top} isConnectable={isConnectable} />
				<Text fontSize={14} textAlign="center">
					Chain: <b>{data.name}</b>
				</Text>

				{canEditChain && (
					<>
						<Box position="absolute" left={0} bottom={0}>
							<IconButton size="small" onClick={goToChain} isLoading={isCreatingStep}>
								<VisibilityOutlinedIcon
									sx={{
										fontSize: 12,
									}}
								/>
							</IconButton>
						</Box>
						<Box position="absolute" right={0} bottom={0}>
							<IconButton size="small" onClick={handleNewStepClick} isLoading={isCreatingStep}>
								<PlusIcon width={12} />
							</IconButton>
						</Box>
					</>
				)}
				<Handle type="source" position={Position.Bottom} isConnectable={isConnectable} />
			</Box>
		</>
	)
}

export default ChainNode
