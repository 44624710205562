import _cloneDeep from 'lodash/cloneDeep'
import React, { ComponentType, useCallback, useContext, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { TableTypes, StepTypes } from '@cango-app/sdk/types'
import { useSelector } from 'react-redux'

import { TableContext, TableProvider, TaskContext } from 'src/providers'
import { Box, TextField } from 'src/components'
import { CustomInputForm } from 'src/modules/my-tasks-v3/components/complete-task-cta/types'
import useDebouncedCallback from 'src/hooks/useDebouncedCallback'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'

const DatabaseModInput: ComponentType<{
	change: StepTypes.DatabaseChangeItem
	rowIdsUpdating: string[]
	disabled: boolean
	onModsUpdate: (modId: string, mod: string) => void
}> = ({ change, rowIdsUpdating, disabled, onModsUpdate }) => {
	const { control, setValue } = useFormContext<CustomInputForm>()
	const { mappedColumns, mappedRecords, cacheRowUpdates } = useContext(TableContext)

	const debouncedOnUpdateFields = useDebouncedCallback(
		useCallback(
			(
				updates: {
					oldRow: TableTypes.TableRow
					newRow: TableTypes.TableRow
				}[],
			) => {
				cacheRowUpdates(updates)
			},
			[cacheRowUpdates],
		),
		300,
	)

	const handleCustomFieldChange = useCallback(
		(changeId: string, columnId: string, value: string) => {
			const updatedFields = rowIdsUpdating.reduce(
				(
					acc: {
						newRow: TableTypes.TableRow
						oldRow: TableTypes.TableRow
					}[],
					_rowId,
				) => {
					const originalRecord = mappedRecords.get(_rowId)
					if (!originalRecord) {
						return acc
					}
					const updatedRecord = _cloneDeep(originalRecord)
					const column = mappedColumns.get(columnId)
					if (!column) {
						return acc
					}
					onModsUpdate(changeId, value)
					if (column.type === TableTypes.FieldType.CALCULATION) {
						updatedRecord.overrides = {
							...updatedRecord.overrides,
							[columnId]: value,
						}
					} else {
						updatedRecord.data[columnId] = value
					}
					return [
						...acc,
						{
							newRow: updatedRecord,
							oldRow: originalRecord,
						},
					]
				},
				[],
			)
			setValue(`inputs.${columnId}`, value)
			debouncedOnUpdateFields(updatedFields)
		},
		[rowIdsUpdating, debouncedOnUpdateFields, mappedRecords],
	)

	return (
		<Controller
			control={control}
			rules={{ required: change.is_required }}
			name={`inputs.${change.field}`}
			render={({ field: { value }, fieldState: { error } }) => (
				<TextField
					label={mappedColumns.get(change.field)?.name}
					value={value}
					onChange={(e) => {
						handleCustomFieldChange(String(change.id), change.field, e.target.value)
					}}
					containerProps={{ width: 300 }}
					fullWidth
					disabled={disabled}
					error={!!error}
					helperText={error?.message}
				/>
			)}
		/>
	)
}

export const DatabaseModInputs: ComponentType<{
	rowIdsUpdating: string[]
	disabled: boolean
	onModsUpdate: (modId: string, mod: string) => void
}> = ({ rowIdsUpdating, disabled, onModsUpdate }) => {
	const projectTableId = useSelector(projectSelectors.getSelectedProjectTableId)
	const { descendants, task } = useContext(TaskContext)
	const taskId = task?.step?.complete_options?.table_id ?? projectTableId

	const hasDatabaseMods = useMemo(
		() => descendants.some(({ database_changes }) => !!database_changes),
		[descendants],
	)

	if (!hasDatabaseMods || !taskId) {
		return null
	}

	return (
		<Box>
			{descendants.map((_desc) => {
				if (!_desc.database_changes) {
					return null
				}
				return (
					<TableProvider key={_desc._id} tableId={taskId}>
						<Box key={_desc._id}>
							{_desc.database_changes.map((_change) => {
								if (_change.operator !== 'custom_input') {
									return null
								}
								return (
									<DatabaseModInput
										key={_change.id}
										change={_change}
										rowIdsUpdating={rowIdsUpdating}
										disabled={disabled}
										onModsUpdate={onModsUpdate}
									/>
								)
							})}
						</Box>
					</TableProvider>
				)
			})}
		</Box>
	)
}
