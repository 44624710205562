import { PermissionsTypes } from '@cango-app/sdk/types'
import { Stack, Paper } from '@mui/material'
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined'
import capitalize from 'lodash/capitalize'
import { useCallback, useState } from 'react'

import { TrashIcon } from 'src/assets/icons'
import { Button, Select, Text, Grid, Box, IconButton, Modal } from 'src/components'

const ROUTE_OPTIONS = PermissionsTypes.PERMISSIONS_ROUTES.map((route) => ({
	_id: route,
	label: capitalize(route),
}))

type Props = {
	permissions: PermissionsTypes.Permissions[]
	updatePermission: (
		permissionChanges: Pick<PermissionsTypes.Permissions, '_id' | 'routes'>,
	) => Promise<void>
	deletePermission: (permissionId: string, substitutePermissionId: string) => Promise<void>
	isDeletingPermission: boolean
}

export const PermissionsManager = ({
	permissions,
	updatePermission,
	deletePermission,
	isDeletingPermission,
}: Props) => {
	const [selectedPermission, setSelectedPermission] = useState('')
	const [selectedSubtitutePermission, setSelectedSubtitutePermission] = useState('')

	const removeSelectedPermissions = useCallback(() => {
		setSelectedPermission('')
		setSelectedSubtitutePermission('')
	}, [])
	return (
		<>
			<Grid container spacing={2}>
				{permissions.map(({ _id, name, routes }) => (
					<Grid item xs={6} md={3} key={_id}>
						<Paper elevation={2}>
							<Stack direction="column" spacing={2} padding={2} borderRadius={2}>
								<Stack direction="row" justifyContent="space-between">
									<Text variant="h5">Level: {name}</Text>
									{permissions.length > 1 && (
										<IconButton onClick={() => setSelectedPermission(_id)}>
											<TrashIcon />
										</IconButton>
									)}
								</Stack>
								<Stack direction="column" spacing={2} alignItems="center">
									<Select
										containerProps={{
											width: '100%',
										}}
										multiple
										label="Routes visibles:"
										options={ROUTE_OPTIONS}
										value={routes}
										onChange={(e) => {
											updatePermission({
												_id,
												routes: e.target.value as string[],
											})
										}}
									/>
									<Box flex={1} display="flex" width="100%" justifyContent="space-between">
										<Button
											sx={{
												minWidth: '45%',
												maxWidth: '170px',
											}}
											startIcon={<ChecklistOutlinedIcon />}
											onClick={() => {
												updatePermission({
													_id,
													routes: PermissionsTypes.PERMISSIONS_ROUTES,
												})
											}}
										>
											Select All
										</Button>
										<Button
											variant="text"
											sx={{
												minWidth: '45%',
												maxWidth: '170px',
											}}
											onClick={() => {
												updatePermission({
													_id,
													routes: [],
												})
											}}
										>
											Clear all
										</Button>
									</Box>
								</Stack>
							</Stack>
						</Paper>
					</Grid>
				))}
			</Grid>
			<Modal open={!!selectedPermission} onClose={() => setSelectedPermission('')}>
				<Stack direction="column" spacing={2} width={450}>
					<Text variant="h5">Do you want to delete this permission level?</Text>
					<Text variant="caption">
						You need to choose a permission level in case any user is using the one you want to
						delete; they will be assigned this new permission level.
					</Text>
					<Select
						label="Available permissions levels:"
						options={permissions.reduce<{ _id: string; label: string }[]>(
							(_permissionsAcc, { _id, name }) => {
								if (selectedPermission === _id) {
									return _permissionsAcc
								}
								return [
									..._permissionsAcc,
									{
										_id,
										label: `Level: ${name}`,
									},
								]
							},
							[],
						)}
						value={selectedSubtitutePermission}
						onChange={(e) => setSelectedSubtitutePermission(e.target.value as string)}
					/>
					<Stack direction="row" justifyContent="space-between">
						<Button
							disabled={!selectedSubtitutePermission}
							color="error"
							isLoading={isDeletingPermission}
							onClick={() => {
								deletePermission(selectedPermission, selectedSubtitutePermission)
								removeSelectedPermissions()
							}}
						>
							Delete
						</Button>
						<Button variant="text" onClick={removeSelectedPermissions}>
							Close
						</Button>
					</Stack>
				</Stack>
			</Modal>
		</>
	)
}
