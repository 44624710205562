import { Stack } from '@mui/material'
import { GridColumnVisibilityModel, GridFilterModel } from '@mui/x-data-grid-premium'
import React, { ComponentType, useContext } from 'react'
import { useFormContext } from 'react-hook-form'

import { Select, Text } from 'src/components'
import { TableFilters } from 'src/components/table-filters'
import { ChainContext } from 'src/modules/chains/chain-provider'
import { TableContext, TableProvider } from 'src/providers'

import { StepFormType } from './step-form-container'

type QuestionnaireFieldsProps = {
	chainIndex: number
	actionIndex: number
}

const QuestionnaireComponent: ComponentType<QuestionnaireFieldsProps> = ({
	chainIndex,
	actionIndex,
}) => {
	const { columnList } = useContext(TableContext)
	const { watch, setValue } = useFormContext<StepFormType>()
	const action = watch(`chain_actions.${chainIndex}.actions.${actionIndex}`)

	const handleQuestionaireFiltersChange = (filters: GridFilterModel) => {
		setValue(`chain_actions.${chainIndex}.actions.${actionIndex}.questionaire.filters`, filters, {
			shouldDirty: true,
		})
	}

	const handleQuestionaireColumnsChange = (columns: string[]) => {
		setValue(`chain_actions.${chainIndex}.actions.${actionIndex}.questionaire.columns`, columns, {
			shouldDirty: true,
		})
	}

	return (
		<>
			<Text fontSize={14} fontWeight={500}>
				Questionnaire table filters
			</Text>
			<Stack direction="row">
				<TableFilters
					initialState={{
						filter: {
							filterModel: action.questionaire?.filters,
						},
						columns: {
							columnVisibilityModel: action.questionaire?.columns?.reduce(
								(acc: GridColumnVisibilityModel, _col) => {
									return {
										...acc,
										[_col]: true,
									}
								},
								columnList.reduce(
									(_fields: GridColumnVisibilityModel, _field) => ({
										..._fields,
										[_field._id]: false,
									}),
									{},
								) ?? {},
							),
						},
					}}
					onChange={handleQuestionaireFiltersChange}
					onColumnsChange={handleQuestionaireColumnsChange}
					withColumns
				/>
			</Stack>
			<Select
				label="Questionnaire scope"
				options={columnList}
				value={action.questionaire?.scope ?? []}
				onChange={(event) => {
					setValue(
						`chain_actions.${chainIndex}.actions.${actionIndex}.questionaire.scope`,
						event.target.value as string[],
						{ shouldDirty: true },
					)
				}}
				multiple
				containerProps={{ my: 2, maxWidth: 375 }}
			/>
		</>
	)
}

export const QuestionnaireFields: ComponentType<QuestionnaireFieldsProps> = (props) => {
	const { tableConfig } = useContext(ChainContext)

	if (!tableConfig) {
		return null
	}

	return (
		<TableProvider tableId={tableConfig._id}>
			<QuestionnaireComponent {...props} />
		</TableProvider>
	)
}
