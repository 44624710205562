import { StepTypes } from '@cango-app/sdk/types'

export const ACTIONS_REQUIRING_ROLES = [
	StepTypes.Action.ActionEnum.Call,
	StepTypes.Action.ActionEnum.Email,
	StepTypes.Action.ActionEnum.Invite,
	StepTypes.Action.ActionEnum.Meeting,
]
export const ACTIONS_REQUIRING_LINK = [
	StepTypes.Action.ActionEnum.Software,
	StepTypes.Action.ActionEnum.Video,
]
