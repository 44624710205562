import React, { ComponentType, useContext, useEffect, useMemo, useState } from 'react'
import { StepTypes, ChainTypes } from '@cango-app/sdk/types'
import { Badge, Stack } from '@mui/material'
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { v4 } from 'uuid'
import AttachmentIcon from '@mui/icons-material/AttachFile'
import _isArray from 'lodash/isArray'

import { SetResourcesFields } from 'src/modules/chains/components/step-modal/step-form/set-resources'
import { QuestionnaireFields } from 'src/modules/chains/components/step-modal/step-form/questionnaire-fields'
import { PlusIcon, TrashIcon } from 'src/assets/icons'
import {
	Box,
	Select,
	IconButton,
	Button,
	Divider,
	Text,
	GroupedSelectOption,
	GroupedSelect,
	Modal,
} from 'src/components'
import { getActionLabel } from 'src/helpers/labels'
import { usePrevious } from 'src/hooks/usePrevious'
import { colors } from 'src/theme/colors'
import { TableContext } from 'src/providers'
import { getListFromTable } from 'src/hooks/use-list-options'
import { useChainPermissions } from 'src/modules/chains/useChainPermissions'

import { StepContext } from '../step-provider'

import {
	ACTIONS_REQUIRING_LINK,
	ACTIONS_REQUIRING_ROLES,
	LinkInput,
	NoteInput,
	RoleSelect,
	TaskReferences,
} from './actions'
import { StepFormType } from './step-form-container'
import { TemplateUploadFields } from './template-upload-fields'
import { TemplateButton } from './template-button'
import { AttachmentsList } from './attachments-list'

const UndecoratedActionFields: ComponentType<{
	chainIndex: number
	actionIndex: number
	onRemove: () => void
	update: (action: StepTypes.Action.StepAction) => void
	action: StepTypes.Action.StepAction
}> = ({ actionIndex, chainIndex, onRemove, update, action }) => {
	const { control } = useFormContext<StepFormType>()
	const actions = useWatch({ control, name: `chain_actions.${chainIndex}.actions` })
	const previousActionType = usePrevious(action.type)

	const usedActions = actions.map((_action) => _action.type)

	const actionTypeOptions = useMemo(() => {
		const options = Object.values(StepTypes.Action.ActionEnum).map((type) => ({
			_id: type,
			label: getActionLabel(type),
		}))

		return options.filter((_option) => {
			if (_option._id === action.type) {
				return true
			}
			return !usedActions.includes(_option._id)
		})
	}, [usedActions, action.type])

	useEffect(() => {
		if (
			previousActionType &&
			!ACTIONS_REQUIRING_LINK.includes(action.type) &&
			previousActionType !== action.type &&
			action.links.length
		) {
			update({
				...action,
				links: [],
			})
		}
	}, [action.type])

	return (
		<>
			<Box
				sx={{
					mb: 2,
					display: 'flex',
				}}
			>
				<Box flex={1}>
					<Select
						disabled={
							action.type === StepTypes.Action.ActionEnum.FileTemplate && !!action.file_ids?.length
						}
						label="What needs to be done?"
						onChange={(event) =>
							update({ ...action, type: event.target.value as StepTypes.Action.ActionEnum })
						}
						value={action.type}
						options={actionTypeOptions}
						containerProps={{ my: 2, maxWidth: 375 }}
					/>

					{action.type && ACTIONS_REQUIRING_ROLES.includes(action.type) && (
						<RoleSelect action={action} update={update} />
					)}

					{action.type === StepTypes.Action.ActionEnum.FileTemplate && (
						<TemplateUploadFields chainIndex={chainIndex} actionIndex={actionIndex} />
					)}

					{action.type === StepTypes.Action.ActionEnum.TaskReference && (
						<TaskReferences
							actionIndex={actionIndex}
							chainIndex={chainIndex}
							typeOfAction="actions"
						/>
					)}

					{action.type === StepTypes.Action.ActionEnum.SetResources && (
						<SetResourcesFields action={action} update={update} />
					)}

					{action.type === StepTypes.Action.ActionEnum.Template && (
						<TemplateButton chainIndex={chainIndex} actionIndex={actionIndex} type="actions" />
					)}

					{action.type && action.type === StepTypes.Action.ActionEnum.Note && (
						<NoteInput action={action} update={update} />
					)}

					{action.type && action.type === StepTypes.Action.ActionEnum.Questionaire && (
						<QuestionnaireFields chainIndex={chainIndex} actionIndex={actionIndex} />
					)}

					{action.type && ACTIONS_REQUIRING_LINK.includes(action.type) && (
						<LinkInput chainIndex={chainIndex} actionIndex={actionIndex} typeOfAction="actions" />
					)}
				</Box>
				<Box>
					{actionIndex === 0 && <></>}
					<IconButton sx={{ mt: 5 }} onClick={onRemove}>
						<TrashIcon />
					</IconButton>
				</Box>
			</Box>
		</>
	)
}

const OptionActions: ComponentType<{
	onRemove: (index: number) => void
	optionList: GroupedSelectOption[]
	chainIndex: number
}> = ({ onRemove, optionList, chainIndex }) => {
	const { control, watch } = useFormContext<StepFormType>()
	const {
		fields: actionFields,
		append,
		remove,
		update: updateAction,
	} = useFieldArray({
		control,
		name: `chain_actions.${chainIndex}.actions`,
	})
	const [openSelectAttachmentModal, setOpenSelectAttachemntModal] = useState(false)
	const allChainActions = watch('chain_actions')
	const currentItem = watch(`chain_actions.${chainIndex}`)
	const attachments = useWatch({ control, name: `chain_actions.${chainIndex}.attachments` })

	const hasNoExtraParents = useMemo(() => {
		return !optionList.length || (optionList.length === 1 && optionList[0].groupName === 'Default')
	}, [optionList])
	const { canEditChain } = useChainPermissions()

	const filteredParentsList = useMemo(() => {
		return optionList.reduce((_acc: GroupedSelectOption[], _group) => {
			return [
				..._acc,
				{
					..._group,
					_options: _group.options.filter((_option) => {
						if (_option._id === currentItem.option_id) {
							return true
						}
						return !allChainActions.find((_action) => _action.option_id === _option._id)
					}),
				},
			]
		}, [])
	}, [optionList])

	return (
		<>
			<Box
				sx={{
					backgroundColor: 'rgba(164, 198, 188, 0.1)',
					p: 2,
					borderRadius: '8px',
					mb: 2,
				}}
			>
				{!hasNoExtraParents && (
					<Controller
						control={control}
						name={`chain_actions.${chainIndex}.option_id`}
						render={({ field: { value, onChange } }) => (
							<GroupedSelect
								label="Which chain is this for?"
								options={filteredParentsList}
								value={value}
								onChange={onChange}
								containerProps={{ mb: 1 }}
							/>
						)}
					/>
				)}

				{actionFields.map((_item, index) => (
					<>
						<UndecoratedActionFields
							key={_item._id}
							actionIndex={index}
							chainIndex={chainIndex}
							action={_item}
							onRemove={() => remove(index)}
							update={(action) => updateAction(index, action)}
						/>
						{index !== actionFields.length - 1 && <Divider />}
					</>
				))}
				<Box display="flex" justifyContent="space-between">
					<Stack direction="row" spacing={1}>
						<Button
							size="small"
							variant="outlined"
							disabled={!canEditChain}
							onClick={() =>
								append({
									_id: v4(),
									type: StepTypes.Action.ActionEnum.None,
									roles: [],
									note: '',
									links: [],
									file_ids: [],
									task_references: [],
								})
							}
							startIcon={<PlusIcon width={16} stroke={colors.feldgrau['60']} />}
						>
							{`Add an action${!hasNoExtraParents ? ' for this chain' : ''}`}
						</Button>
						<IconButton sx={{ mt: 5 }}>
							<Badge
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								badgeContent={attachments?.length ?? 0}
								overlap="circular"
								color="primary"
								onClick={() => setOpenSelectAttachemntModal(true)}
							>
								<AttachmentIcon />
							</Badge>
						</IconButton>
					</Stack>

					{canEditChain && (
						<Button
							size="small"
							variant="outlined"
							color="error"
							onClick={() => onRemove(chainIndex)}
							startIcon={<TrashIcon stroke={colors.error.main} width={12} />}
						>
							{`Remove all actions${!hasNoExtraParents ? ' for chain' : ''}`}
						</Button>
					)}
				</Box>
			</Box>
			<Modal
				open={openSelectAttachmentModal}
				onClose={() => setOpenSelectAttachemntModal(false)}
				containerStyle={{
					p: 0,
					overflow: 'hidden',
				}}
			>
				<Box position="relative" width={750} height={800}>
					<Box height="90%" overflow="auto" p={2}>
						<Text variant="h5">Attachments</Text>
						<AttachmentsList chainIndex={chainIndex} />
					</Box>
					<Box
						position="absolute"
						bgcolor="background.paper"
						zIndex={999}
						width="calc(100% - 32px)"
						bottom={0}
						paddingX={2}
						paddingY={3}
					>
						<Button fullWidth onClick={() => setOpenSelectAttachemntModal(false)}>
							Close
						</Button>
					</Box>
				</Box>
			</Modal>
		</>
	)
}

export const MultipleStepActions: ComponentType = () => {
	const { control } = useFormContext<StepFormType>()
	const { hierarchy } = useContext(StepContext)
	const { mappedColumns, tableConfig, resolvedRows, mappedValueOptions, columnFilterList } =
		useContext(TableContext)
	const { fields, append, remove } = useFieldArray({ control, name: 'chain_actions' })
	const { canEditChain } = useChainPermissions()

	const optionList = useMemo((): GroupedSelectOption[] => {
		const tasksWithOptions = [...hierarchy.values()].filter(
			(_stepWithDepth) =>
				(_stepWithDepth.complete_options?.column_options ||
					(_isArray(_stepWithDepth.complete_options?.options) &&
						_stepWithDepth.complete_options.options.length)) &&
				_stepWithDepth.depth !== 0,
		)

		if (!tasksWithOptions.length) return []

		return tasksWithOptions.reduce(
			(_acc: GroupedSelectOption[], _task) => {
				const parentName = _task.name
				let options = _task.complete_options?.options ?? []
				if (_task.complete_options?.column_options && tableConfig) {
					options = getListFromTable({
						optionsWithFilter: _task.complete_options,
						mappedColumns,
						resolvedRows,
						mappedValueOptions,
						columnFilterList,
					})
				}

				return [
					..._acc,
					{
						groupName: parentName,
						options: options,
					},
				]
			},
			[
				{
					groupName: 'Default',
					options: [
						{
							_id: ChainTypes.Task_Action_All,
							label: 'All',
						},
					],
				},
			],
		)
	}, [hierarchy, mappedColumns])

	const handleAddChain = () => {
		if (!optionList.length) {
			append({
				option_id: ChainTypes.Task_Action_All,
				actions: [],
				attachments: [],
			})
			return
		}

		if (!optionList[0]?.options.length) {
			return
		}

		append({
			option_id: optionList[0].options[0]._id,
			actions: [],
			attachments: [],
		})
	}

	return (
		<Box mt={2}>
			<Box mb={2}>
				<Button
					size="small"
					variant="outlined"
					onClick={handleAddChain}
					disabled={
						(optionList.length === 0 && fields.length > 0) ||
						(optionList.length > 1 &&
							optionList.flatMap(({ options }) => options).length === fields.length) ||
						!canEditChain
					}
				>
					Add
				</Button>
			</Box>
			{fields.map((_field, index) => (
				<OptionActions
					key={_field.option_id}
					onRemove={remove}
					optionList={optionList}
					chainIndex={index}
				/>
			))}
		</Box>
	)
}
