import React, { ComponentType, useContext } from 'react'
import { useSelector } from 'react-redux'
import { StepTypes } from '@cango-app/sdk/types'
import { Stack } from '@mui/material'

import { Box, FormCard } from 'src/components'
import { getActionLabel } from 'src/helpers/labels'
import { selectors as persistedConfigSelectors } from 'src/store/modules/persisted-config'
import { ActionComponent } from 'src/components/action-component'
import { TaskContext } from 'src/providers'

import { TaskAttachments } from './task-attachments'

export const ActionFields: ComponentType = () => {
	const { task } = useContext(TaskContext)

	const taskAttachmentDirection =
		useSelector(persistedConfigSelectors.getTaskAttachmentDirection) ?? 'vertical'

	if (!task) {
		return null
	}

	return (
		<>
			{/* Render only attachments in case there is no actions */}
			{task.actions.length === 0 && !!task.attachments.length && (
				<TaskAttachments attachments={task.attachments} />
			)}
			{task.actions.map((_action) => {
				if (_action.type === StepTypes.Action.ActionEnum.None) {
					return null
				}
				return (
					<Box key={_action._id} mt={2} pb={2}>
						<Stack direction={taskAttachmentDirection} spacing={2}>
							<FormCard title={`Action - ${getActionLabel(_action.type)}`}>
								<ActionComponent action={_action} />
							</FormCard>
							<TaskAttachments attachments={task.attachments ?? []} />
						</Stack>
					</Box>
				)
			})}
		</>
	)
}
