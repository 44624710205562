import { ComponentType, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CommsSdk } from '@cango-app/sdk/api'

import { Box, Divider } from 'src/components'
import { actions as notesActions } from 'src/store/modules/notes'

import { InboxNavigation } from './navigation'
import { InboxTaskList } from './inbox-task-list'
import { InboxTaskChat } from './inbox-task-chat'

const Inbox: ComponentType = () => {
	const dispatch = useDispatch()
	const { navState, selectedTaskId } = useParams<{
		navState: CommsSdk.InboxNavState
		selectedTaskId?: string
	}>()

	const handleSwitchNavState = useCallback((newState: CommsSdk.InboxNavState) => {
		dispatch(notesActions.getInbox(newState))
	}, [])

	useEffect(() => {
		if (navState) {
			handleSwitchNavState(navState)
		} else {
			handleSwitchNavState(CommsSdk.InboxNavState.MyMessages)
		}
	}, [navState])

	useEffect(() => {
		dispatch(notesActions.setSelectedInboxTaskId(selectedTaskId))
	}, [selectedTaskId])

	return (
		<Box display="flex" flex={1} overflow="hidden">
			<InboxNavigation />
			<Divider orientation="vertical" flexItem sx={{ borderRightWidth: 2 }} />
			<Box display="flex" width={500}>
				<InboxTaskList />
			</Box>
			<Divider orientation="vertical" flexItem sx={{ borderRightWidth: 2 }} />
			<Box flex={2} display="flex">
				<InboxTaskChat />
			</Box>
		</Box>
	)
}

export default Inbox
