import { ComponentType, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Skeleton from '@mui/material/Skeleton'
import LinearProgress from '@mui/material/LinearProgress'

import { Box, Button, Grid, Text } from 'src/components'
import { selectors as projectSelectors, ProjectsLoadingState } from 'src/store/modules/projects-v3'
import { UniversalDrawerType, actions as configActions } from 'src/store/modules/config'
import { useMyTasks } from 'src/hooks/useMyTasks'
import { RootState } from 'src/store/types'
import { TaskContext, TaskProvider } from 'src/providers'
import { ChainList } from 'src/components/section-tasks-v3/chains-list'

import { SingleTaskComponent } from './single-task-container'
import { TaskList } from './task-list'
import { AllMyTasksDone } from './all-my-task-done'

const Toolbar: ComponentType = () => {
	const dispatch = useDispatch()
	const { task: selectedTask } = useContext(TaskContext)

	const handleNewProjectClick = () => {
		dispatch(configActions.setUniversalDrawerType(UniversalDrawerType.Project))
	}

	return (
		<Grid container my={2} alignItems="center">
			<Grid item xs={10}>
				<ChainList chain={selectedTask?.chain} />
			</Grid>
			<Grid item xs={2}>
				<Box>
					<Button onClick={handleNewProjectClick}>New project</Button>
				</Box>
			</Grid>
		</Grid>
	)
}

const SectionContainerContent: ComponentType = () => {
	const { selectedSectionId, selectedTaskId } = useMyTasks()
	const { isLoadingTask } = useContext(TaskContext)
	const projectState = useSelector(projectSelectors.getProjectLoadingState)
	const tasksState = useSelector(projectSelectors.getTasksState)
	const activeTasks = useSelector(projectSelectors.getActiveTasksForUser)
	const section = useSelector((state: RootState) =>
		projectSelectors.getProjectSection(state, selectedSectionId),
	)
	const numberOfFilesPendingUpload = useSelector(projectSelectors.getNumberOfFilesPendingUpload)
	const taskFilesUploadedSize = useSelector(projectSelectors.getPercentageOfTasksUploaded)
	const totalFilesRequiringUpload = useSelector(
		projectSelectors.getTotalNumberOfFilesRequiringUpload,
	)
	const isProjectLoading = [ProjectsLoadingState.Loading, ProjectsLoadingState.Idle].includes(
		projectState,
	)
	const areTasksLoading = [ProjectsLoadingState.Loading, ProjectsLoadingState.Idle].includes(
		tasksState,
	)

	if (isProjectLoading || areTasksLoading || isLoadingTask) {
		return (
			<Skeleton variant="rectangular" width="100%" height={400} sx={{ borderRadius: '30px' }} />
		)
	}

	if (!section) {
		return null
	}

	if (!activeTasks.length) {
		return <AllMyTasksDone bgcolor="white" borderRadius="30px" px={3} py={2} height={400} />
	}

	return (
		<Box bgcolor="white" borderRadius="30px" px={3} py={2} minHeight={{ laptop: 200 }}>
			<Box>
				<Box height="4px">
					{isLoadingTask && <LinearProgress variant="indeterminate" />}
					{taskFilesUploadedSize !== 100 && (
						<>
							<LinearProgress variant="determinate" value={taskFilesUploadedSize} />
							<Text fontSize={12} textAlign="center">
								{'Uploading '}
								{totalFilesRequiringUpload - numberOfFilesPendingUpload + 1}
								{' of '}
								{totalFilesRequiringUpload}
							</Text>
						</>
					)}
				</Box>
				{selectedTaskId ? <SingleTaskComponent /> : <TaskList />}
			</Box>
		</Box>
	)
}

export const DesktopSectionContainer: ComponentType = () => {
	const { selectedTaskId } = useMyTasks()
	return (
		<TaskProvider taskId={selectedTaskId}>
			<Box flex={3} ml={{ laptop: 3 }} height="100%" sx={{ overflowY: 'auto' }}>
				<Toolbar />
				<SectionContainerContent />
			</Box>
		</TaskProvider>
	)
}
