import { ChainTypes, TaskTypes, StepTypes } from '@cango-app/sdk/types'
import { V3ProjectSdk } from '@cango-app/sdk/api'
import dayjs from 'dayjs'
import _uniq from 'lodash/uniq'

import { SelectedOptions } from 'src/providers/task-provider/types'
import { createTasksFromDatabaseLogic } from 'src/modules/my-tasks-v3/components/complete-task-cta/createTasks/database-logic-tasks'
import { createTasksFromSingleChainSelect } from 'src/modules/my-tasks-v3/components/complete-task-cta/createTasks/single-chain-select'
import { createRegularTasks } from 'src/modules/my-tasks-v3/components/complete-task-cta/createTasks/regular-tasks'
import {
	getTaskActionsAndAttachments,
	getTaskIteration,
	getTaskReferences,
	populateDescendants,
} from 'src/modules/my-tasks-v3/components/complete-task-cta/utils'
import { RootState } from 'src/store/types'

import { TaskDbChain } from '../types'

import { createTasksFromMultiSelect } from './multi-select-tasks'

export const createTasksFromDescendants = ({
	descendants,
	selectedOptions,
	projectTasks,
	completingTask,
	roleDbLogic,
	mappedProjectUsers,
	chain_starters,
	blueprintSteps,
	chainTasks,
	instanceName,
	state,
	mods,
}: {
	/** All descendants that have met completion conditions */
	descendants: TaskTypes.PopulatedStep.ProjectDescendant[]
	/** This has to include the selected options from the "from" field of the descendant */
	selectedOptions: SelectedOptions
	/** All the tasks in the project so far */
	projectTasks: TaskTypes.PopulatedTask[]
	/** The task that is completing */
	completingTask: TaskTypes.PopulatedTask
	/** Role database logic needs to be handled through the UI before getting to this point */
	roleDbLogic?: Record<string, TaskDbChain[]>
	mappedProjectUsers: Map<string, string | undefined>
	chain_starters: Pick<ChainTypes.BlueprintChain, '_id' | 'begins_with'>[]
	blueprintSteps: StepTypes.Step[]
	chainTasks: TaskTypes.PopulatedTask[]
	instanceName: string | undefined
	state: RootState
	mods: {
		_id: string
		mod: string
	}[]
}): {
	newTasks: TaskTypes.Task[]
	copyFilesPayload: V3ProjectSdk.FilesToBeCopiedPayload[]
	chainsToFetch: string[]
	newChains: TaskTypes.Chain.ProjectChain[]
} => {
	const mappedSteps = new Map(blueprintSteps.map((_step) => [_step._id, _step]))

	const externalReferences = blueprintSteps
		.flatMap(({ external_references, _id }) =>
			external_references?.map((ref) => ({
				...ref,
				group: _id,
			})),
		)
		.filter((ref) => !!ref)

	const blueprintSections = new Set(
		blueprintSteps.filter(({ isSection }) => isSection).map(({ _id }) => _id),
	)
	const chainReferenceSteps = new Set(
		blueprintSteps.filter(({ chain_reference }) => chain_reference).map(({ _id }) => _id),
	)
	const mappedProjectTasks = new Map(projectTasks.map((_task) => [_task._id, _task]))
	const mappedFiles = new Map<string, string>()
	let filesToBeCopied: V3ProjectSdk.FilesToBeCopiedPayload[] = []

	const tasks: TaskTypes.Task[] = []
	const chainsToFetch: string[] = []
	const newChains: TaskTypes.Chain.ProjectChain[] = []

	for (const _desc of descendants) {
		const blueprintStep = mappedSteps.get(_desc.step?._id ?? '')
		if (!blueprintStep || _desc.multi_use_config) {
			continue
		}

		const newTasks: TaskTypes.Task[] = []

		if (_desc.database_chain_logic) {
			const { tasks, chains } = createTasksFromDatabaseLogic({
				descendant: _desc,
				selectedOptions,
				projectTasks,
				completingTask,
				roleDbLogic: roleDbLogic?.[_desc._id],
				instanceName,
			})
			newTasks.push(...tasks)
			newChains.push(...chains)
		} else if (_desc.createForEveryOption) {
			if (!selectedOptions.length) {
				continue
			}
			const { tasks, chains } = createTasksFromMultiSelect({
				descendant: _desc,
				selectedOptions,
				completingTask,
				mods,
			})
			newTasks.push(...tasks)
			newChains.push(...chains)
		} else if (selectedOptions.length) {
			const { tasks, chains } = createTasksFromSingleChainSelect({
				descendant: _desc,
				selectedOptions,
				completingTask,
				mappedProjectTasks,
			})
			newTasks.push(...tasks)
			newChains.push(...chains)
		} else {
			const { tasks, chains } = createRegularTasks({
				descendant: _desc,
				completingTask,
				instanceName,
			})
			newTasks.push(...tasks)
			newChains.push(...chains)
		}

		const newSections = newTasks.filter(({ step }) => step && blueprintSections.has(step))
		const newReferences = newTasks.filter(({ step }) => step && chainReferenceSteps.has(step))

		if (newSections.length) {
			for (const task of newSections) {
				const newSectionStep = mappedSteps.get(task.step ?? '')

				if (!newSectionStep) {
					continue
				}

				const convertedDescendants = newSectionStep.descendants.reduce(
					(_descendants: TaskTypes.PopulatedStep.ProjectDescendant[], _descendantOfNewSection) => {
						if (!_descendantOfNewSection.step) {
							return _descendants
						}

						const descStep = mappedSteps.get(_descendantOfNewSection.step)

						if (!descStep) {
							return _descendants
						}

						const descendants = populateDescendants(descStep.descendants, mappedSteps)
						_descendants.push({
							..._descendantOfNewSection,
							step: { ...descStep, descendants },
						})
						return _descendants
					},
					[],
				)

				const newSectionSteps = createTasksFromDescendants({
					descendants: convertedDescendants,
					selectedOptions: [],
					projectTasks,
					completingTask: {
						...completingTask,
						chain: task.chain,
						step: { ...blueprintStep, descendants: convertedDescendants },
						section: task.name,
					},
					roleDbLogic: {},
					mappedProjectUsers,
					chain_starters,
					blueprintSteps,
					chainTasks,
					instanceName: undefined,
					state,
					mods,
				})

				tasks.push(...newSectionSteps.newTasks)
			}
		}

		if (newReferences.length) {
			for (const task of newReferences) {
				const newReferenceStep = mappedSteps.get(task.step ?? '')
				if (!newReferenceStep) {
					continue
				}

				const chainStarters = chain_starters.find(
					({ _id }) => _id === newReferenceStep.chain_reference,
				)

				if (!newReferenceStep.chain_reference) {
					continue
				}

				if (!chainStarters?.begins_with?.length) {
					chainsToFetch.push(newReferenceStep.chain_reference)
					continue
				}

				const startsWith = mappedSteps.get(chainStarters.begins_with[0])

				if (!startsWith) {
					continue
				}

				const convertedDescendants = startsWith.descendants.reduce(
					(_descendants: TaskTypes.PopulatedStep.ProjectDescendant[], _descendantOfStartsWith) => {
						if (!_descendantOfStartsWith.step) {
							return _descendants
						}

						const descStep = mappedSteps.get(_descendantOfStartsWith.step)

						if (!descStep) {
							return _descendants
						}

						const descendants = populateDescendants(descStep.descendants, mappedSteps)
						_descendants.push({
							..._descendantOfStartsWith,
							step: { ...descStep, descendants },
							chain_endings: _uniq([
								...(_descendantOfStartsWith.chain_endings ?? []),
								...(_desc.chain_endings ?? []),
							]),
						})
						return _descendants
					},
					[],
				)
				const newReferencesSteps = createTasksFromDescendants({
					descendants: convertedDescendants,
					selectedOptions: [],
					projectTasks,
					completingTask: {
						...completingTask,
						chain: task.chain ?? completingTask.chain,
						step: { ...startsWith, descendants: convertedDescendants },
						section: startsWith.isSection ? startsWith.name : completingTask.section,
						reference_group: newReferenceStep._id,
					},
					roleDbLogic: {},
					mappedProjectUsers,
					chain_starters,
					blueprintSteps,
					chainTasks,
					instanceName: undefined,
					state,
					mods,
				})

				tasks.push(...newReferencesSteps.newTasks)
			}
		}

		// TODO Multi use

		newTasks.forEach((_task, index) => {
			if (!_task.step || blueprintSections.has(_task.step) || chainReferenceSteps.has(_task.step)) {
				return
			}
			const taskStep = mappedSteps.get(_task.step)
			if (!taskStep) {
				return
			}

			taskStep.descendants.forEach((_desc) => {
				const descendantStep = mappedSteps.get(_desc.step)
				if (!descendantStep) {
					return
				}
				if (
					descendantStep.chain_reference &&
					!chain_starters.some(({ _id }) => _id === descendantStep.chain_reference)
				) {
					chainsToFetch.push(descendantStep.chain_reference)
				}
			})
			const projectTasksWithCompletingTask = projectTasks.map((projectTask) => {
				if (projectTask._id === completingTask._id) {
					return completingTask
				}
				return projectTask
			})
			const { actions, attachments, newFilesToBeCopied } = getTaskActionsAndAttachments({
				newStep: taskStep,
				taskId: _task._id,
				projectTasks: projectTasksWithCompletingTask,
				mappedFiles,
				filesToBeCopied,
				externalReferences,
				groupId: _task.reference_group ?? '',
			})

			filesToBeCopied = newFilesToBeCopied

			let updatedTask: TaskTypes.Task = {
				..._task,
				assignees: taskStep.roles.map((_role) => ({
					role: _role,
					user: mappedProjectUsers.get(_role),
					is_override: false,
				})),
				iteration: getTaskIteration({
					stepId: taskStep._id,
					projectTasks: [],
					taskParent: completingTask._id,
				}),
				actions,
				attachments,
				section: _task.section,
				created_at: dayjs().unix(),
				updated_at: dayjs().unix(),
			}

			updatedTask = getTaskReferences({
				task: updatedTask,
				mockedTasks: tasks,
				chainTasks: [...chainTasks, completingTask],
			})

			newTasks[index] = updatedTask
		})

		tasks.push(
			...newTasks.filter(
				({ step }) => !step || (!blueprintSections.has(step) && !chainReferenceSteps.has(step)),
			),
		)
	}

	return {
		newTasks: tasks,
		copyFilesPayload: filesToBeCopied,
		chainsToFetch,
		newChains,
	}
}
