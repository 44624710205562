import { ComponentType, useCallback, useContext, useMemo } from 'react'
import { SelectChangeEvent } from '@mui/material'
import { StepTypes, TableTypes, TaskTypes } from '@cango-app/sdk/types'
import _uniq from 'lodash/uniq'
import { useDispatch, useSelector } from 'react-redux'

import { useListOptions } from 'src/hooks/use-list-options'
import { TaskContext } from 'src/providers'
import { Select } from 'src/components'
import { getDatabaseRowUpdates } from 'src/modules/my-tasks-v3/components/complete-task-cta/utils'
import { filterDescendantsBySelection } from 'src/providers/task-provider/selectors'
import { RootState } from 'src/store/types'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'
import { selectors as tableSelectors, actions as tableActions } from 'src/store/modules/tables'

type OptionsSelectProps = {
	onChangeRowIdsUpdating: (rowIds: string[]) => void
}

export const OptionsSelect: ComponentType<OptionsSelectProps> = ({ onChangeRowIdsUpdating }) => {
	const dispatch = useDispatch()
	const { task, selectedOptions, setSelectedOptions } = useContext(TaskContext)
	const isMenu = task?.step?.isMenu ?? !!task?.isMenu
	const chainTasks = useSelector((state: RootState) =>
		projectSelectors.getProjectTasksInChain(state, task),
	)
	const selectedProjectId = useSelector(projectSelectors.getSelectedProjectId)
	const tableId = task?.step?.complete_options?.table_id ?? ''
	const mappedColumns = useSelector((state: RootState) =>
		tableSelectors.selectMappedColumns(state, task?.step?.complete_options?.table_id ?? ''),
	)
	const resolvedRows = useSelector((state: RootState) =>
		tableSelectors.selectResolvedRowData(state, tableId),
	)
	const mappedRecords = useSelector((state: RootState) =>
		tableSelectors.selectMappedRecords(state, tableId),
	)
	const columnFilterList = useSelector((state: RootState) =>
		tableSelectors.selectColumnFilterList(state, tableId),
	)
	const blueprintRows = useSelector((state: RootState) =>
		tableSelectors.selectBlueprintTableRows(state, tableId),
	)

	const listOptions = useListOptions({
		optionsWithFilter: task?.step?.complete_options ?? {
			options: [],
			column_options: undefined,
			table_id: undefined,
		},
		filterModel: task?.chain?.database_chain_logic?.filters,
	})

	const mappedOptions = useMemo(() => {
		return new Map(
			listOptions.map((_option) => [
				_option._id,
				{
					label: _option.label,
					dueTime: _option.dueTime,
					originalValue: _option.originalValue,
				},
			]),
		)
	}, [listOptions])

	const handleSelectOption = useCallback(
		async (e: SelectChangeEvent<unknown>) => {
			if (!selectedProjectId) {
				return
			}
			let preValues: string[]
			if (isMenu) {
				preValues = e.target.value as string[]
			} else {
				preValues = [e.target.value as string]
			}

			const missingOptions = new Map<
				string,
				TaskTypes.CompletedOption & {
					dueTime?: {
						time: string
						when: StepTypes.WhenDueTime
					}
				}
			>()
			task?.lifecycle.completed_options.forEach((_completedOption) => {
				if (
					task.isMultiUse &&
					task.isMenu &&
					!preValues.some((_id) => _id === _completedOption._id)
				) {
					preValues.push(_completedOption._id)
					missingOptions.set(_completedOption._id, _completedOption)
				}
			})
			const newSelectedOptions = preValues.reduce(
				(
					_acc: Array<
						TaskTypes.CompletedOption & {
							dueTime?: {
								time: string
								when: StepTypes.WhenDueTime
							}
						}
					>,
					val,
				) => {
					const existingMissingOption = missingOptions.get(val)
					if (existingMissingOption) {
						_acc.push(existingMissingOption)
						return _acc
					}
					const option = mappedOptions.get(val)
					const column = mappedColumns.get(task?.step?.complete_options?.column_options ?? '')
					if (!option) {
						return _acc
					}
					const columnType = column?.type
					if (
						columnType &&
						[
							TableTypes.FieldType.SINGLE_SELECT,
							TableTypes.FieldType.REFERENCE,
							TableTypes.FieldType.TABLE_SELECT,
						].includes(columnType)
					) {
						_acc.push({
							_id: val,
							label: option.label,
							dueTime: option.dueTime,
							column: column ? { type: column.type, _id: column._id } : undefined,
						})
					} else {
						_acc.push({
							_id: val,
							label: option?.originalValue ? String(option.originalValue) : option.label,
							dueTime: option.dueTime,
							column: column ? { type: column.type, _id: column._id } : undefined,
						})
					}
					return _acc
				},
				[],
			)

			if (task?.step?.descendants.some(({ database_changes }) => !!database_changes)) {
				const filteredDescendantsBySelection = filterDescendantsBySelection(
					task,
					task.step?.descendants ?? [],
					chainTasks,
					newSelectedOptions,
				)

				const { rowUpdates, newRows } = getDatabaseRowUpdates({
					resolvedRows,
					task,
					columnsWithFilterType: columnFilterList,
					descendants: filteredDescendantsBySelection,
					newSelectedOptions,
					previousSelectedOptions: selectedOptions,
					mappedRecords,
					mappedColumns,
					blueprintTableRows: blueprintRows,
					projectId: selectedProjectId,
				})

				if (rowUpdates.length || newRows.length) {
					dispatch(
						tableActions.softUpdateRows({
							tableId,
							updates: rowUpdates,
							newRows,
							discardPreviousChanges: true,
							projectId: selectedProjectId,
						}),
					)
				}

				onChangeRowIdsUpdating(
					_uniq([...rowUpdates.map(({ newRow }) => newRow._id), ...newRows.map(({ _id }) => _id)]),
				)
			}

			setSelectedOptions([...newSelectedOptions])
		},
		[
			task,
			mappedOptions,
			selectedOptions,
			mappedColumns,
			resolvedRows,
			columnFilterList,
			blueprintRows,
			mappedRecords,
			selectedProjectId,
		],
	)

	return (
		<Select
			label={`Choose ${isMenu ? 'options' : 'an option'}`}
			multiline={isMenu}
			multiple={isMenu}
			options={listOptions}
			value={
				selectedOptions.map(
					({ label }) => listOptions.find((_option) => _option.label === label)?._id,
				) as string[]
			}
			onChange={handleSelectOption}
			sx={{ width: 300 }}
			containerProps={{
				width: { mobile: '100%', laptop: undefined },
				minWidth: 300,
				mr: { laptop: 1 },
				mb: { mobile: 1, laptop: 0 },
			}}
		/>
	)
}
