import { ComponentType } from 'react'
import { StepTypes } from '@cango-app/sdk/types'

import { TextField } from 'src/components'

type Props = {
	action: StepTypes.Action.StepAction
	update: (action: StepTypes.Action.StepAction) => void
}

export const NoteInput: ComponentType<Props> = ({ action, update }) => {
	return (
		<TextField
			label="Describe the action"
			value={action.note}
			fullWidth
			multiline
			onChange={(event) =>
				update({
					...action,
					note: event.target.value,
				})
			}
			sx={{ mb: 2 }}
		/>
	)
}
