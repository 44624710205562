import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Alert from '@mui/material/Alert'
import { Controller, useFormContext } from 'react-hook-form'
import React, { ComponentType, useContext, useState } from 'react'
import { GridFilterOperator } from '@mui/x-data-grid-premium'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Accordion from '@mui/material/Accordion'

import { Box, Checkbox, IconButton, Text } from 'src/components'
import { TableFilters } from 'src/components/table-filters'
import { ChainContext } from 'src/modules/chains/chain-provider'
import { RequiredModFields } from 'src/modules/chains/components/chain-dependency-edge/required-mod-fields'
import { DependencyForm } from 'src/modules/chains/components/chain-dependency-edge/types'
import { TableProvider } from 'src/providers'

const customInputFilterItem: GridFilterOperator = {
	label: 'custom input',
	value: 'custom_input',
	getApplyFilterFn: () => {
		return () => true
	},
	requiresFilterValue: false,
}

const DatabaseModsComponent: ComponentType = () => {
	const { control, setValue, watch } = useFormContext<DependencyForm>()
	const [isExpanded, setIsExpanded] = useState(false)
	const databaseChanges = watch('database_changes')

	const handleModifyDatabaseChange = (shouldMod: boolean) => {
		if (shouldMod) {
			setValue('database_changes', [], { shouldDirty: true })
			setIsExpanded(true)
			return
		}
		setValue('database_changes', null, { shouldDirty: true })
	}

	return (
		<Accordion expanded={isExpanded && !!databaseChanges}>
			<AccordionSummary
				expandIcon={
					<IconButton onClick={() => setIsExpanded(!isExpanded)} disabled={!databaseChanges}>
						{<ExpandMoreIcon />}
					</IconButton>
				}
			>
				<Box display="flex" alignItems="center">
					<Checkbox
						onChange={(e, isChecked) => {
							e.stopPropagation()
							handleModifyDatabaseChange(isChecked)
						}}
						checked={!!databaseChanges}
					/>
					<Text>Modify database when task completes</Text>
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				<Alert severity="info" sx={{ mb: 2 }}>
					These changes will only be applied to the rows within the chain
				</Alert>
				<Controller
					control={control}
					name="database_changes"
					render={({ field: { value, onChange } }) => (
						<TableFilters
							onChange={(model) => onChange(model.items)}
							initialState={{ filter: { filterModel: { items: value ?? [] } } }}
							localeText={{
								filterPanelAddFilter: 'Add mod',
								toolbarFilters: 'Mods',
							}}
							disableLogicOperators
							custom_filters={[customInputFilterItem]}
						/>
					)}
				/>
				<RequiredModFields />
			</AccordionDetails>
		</Accordion>
	)
}

export const DatabaseMods: ComponentType = () => {
	const { tableConfig } = useContext(ChainContext)

	if (!tableConfig?._id) {
		return <Text>No database table selected</Text>
	}

	return (
		<TableProvider tableId={tableConfig._id}>
			<DatabaseModsComponent />
		</TableProvider>
	)
}
