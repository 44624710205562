import React, { ComponentType, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RouteId } from 'src/constants/routes'
import { LoadingDocument } from 'src/components'
import { actions as tableActions, selectors as tableSelectors } from 'src/store/modules/tables'
import type { AsyncDispatchType, RootState } from 'src/store/types'
import {
	selectors as projectSelectors,
	actions as projectActions,
} from 'src/store/modules/projects-v3'
import { DriveFilesProvider } from 'src/providers'
import { showSnackbar } from 'src/helpers/snackbarManager'

import { useProject } from '../useProject'

import { ProjectComponent } from './project-component'

import 'src/assets/css/Task.css'

export const ProjectContainer: ComponentType = () => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const { projectId: currentProjectId, selectedTaskId } = useProject()
	const project = useSelector(projectSelectors.getSelectedProject)
	const tableId = useSelector(projectSelectors.getSelectedProjectTableId)
	const allTables = useSelector(tableSelectors.selectAllTableIds)
	const isFetchingTable = useSelector((state: RootState) =>
		tableSelectors.isFetchingTable(state, tableId ?? ''),
	)
	const [isLoadingProject, setIsLoadingProject] = useState(false)
	const [errorFetchingProject, setErrorFetchingProject] = useState('')

	const fetchProject = async () => {
		if (!currentProjectId) return
		window.scrollTo(0, 0)
		setIsLoadingProject(true)
		const response = await dispatch(
			projectActions.getProject({
				projectId: currentProjectId,
			}),
		)
		setIsLoadingProject(false)
		if (response.meta.requestStatus === 'rejected') {
			setErrorFetchingProject(`Project with ID ${currentProjectId} not found`)
		}
	}

	const handleRevertInstance = async (_instanceId: string, _sectionId: string) => {
		if (!currentProjectId) return
		try {
			showSnackbar('Unable to revert instance', { variant: 'error' })
			// await V3ProjectSdk.deleteAsRequiredInstance(import.meta.env.VITE_API as string, authHeaders, {
			// 	instanceId,
			// 	sectionId,
			// })
			// await dispatch(
			// 	projectActions.getById({
			// 		projectId: currentProjectId,
			// 	}),
			// )
		} catch (error) {
			showSnackbar('Error deleting instance', { variant: 'error' })
		}
	}

	useEffect(() => {
		if (!isFetchingTable && tableId && !allTables.includes(tableId)) {
			dispatch(tableActions.fetchTable({ newTableId: tableId }))
		}
	}, [tableId, allTables, isFetchingTable])

	useEffect(() => {
		fetchProject()
		return () => {
			dispatch(projectActions.endSession())
		}
	}, [])

	if (isLoadingProject || !project || !tableId) {
		return (
			<LoadingDocument
				errorText={errorFetchingProject}
				docType="Project"
				returnToRoute={RouteId.Projects}
				customText="Fetching project"
			/>
		)
	}

	return (
		<DriveFilesProvider
			parentFolderName={project.name}
			parentFolderId={project?.google_drive_folder_id}
		>
			<ProjectComponent onRevertInstance={handleRevertInstance} selectedTaskId={selectedTaskId} />
		</DriveFilesProvider>
	)
}
