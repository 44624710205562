import { ComponentType, ReactNode, useMemo } from 'react'
import { BoxProps } from '@mui/material/Box'
import { Stack } from '@mui/material'
import { StepTypes, TaskTypes } from '@cango-app/sdk/types'
import numeral from 'numeral'
import FlagIcon from '@mui/icons-material/Flag'
import { useSelector } from 'react-redux'

import { ActionIcon } from 'src/assets/icons'
import { Box, Text } from 'src/components'
import { colors } from 'src/theme/colors'
import { AsRequired } from 'src/assets/icons/actions'
import {
	ProjectGroupingType,
	selectors as persistedConfigSelectors,
} from 'src/store/modules/persisted-config'
import { RootState } from 'src/store/types'
import { ChainList } from 'src/components/section-tasks-v3/chains-list'

type TaskTitleContentProps = {
	instanceName?: string
	taskName: string | ReactNode
	chain: TaskTypes.PopulatedTask['chain']
	iteration: number
	numberOfUses?: number
	isFlagged?: boolean
}

type TaskTitleProps = {
	containerProps?: BoxProps
	isSubtask?: boolean
	fontSize?: number
	iconWidth?: number
	hideIcon?: boolean
	task: Pick<
		TaskTypes.PopulatedTask,
		'isMultiUse' | 'name' | 'chain' | 'iteration' | 'lifecycle' | 'actions' | 'project_id'
	>
}

export const TaskTitleContent: ComponentType<TaskTitleContentProps> = ({
	instanceName,
	taskName,
	iteration,
	numberOfUses,
	isFlagged,
}) => {
	// const parentOptionsToShow = useMemo(() => {
	// 	if (!selectedParentOptions) return []
	// 	return selectedParentOptions.filter((_option) => chain?.option_id !== _option._id)
	// }, [chain, selectedParentOptions])
	const parentOptionsToShow = [] as any[]
	return (
		<Box display="flex" alignItems="center" position="relative" justifyContent="space-between">
			<Stack direction="row" spacing={1}>
				{isFlagged ? <FlagIcon color="error" /> : null}
				<Stack direction="column">
					<Text fontSize={14}>
						{instanceName && <b>{`[${instanceName}]`}&nbsp;</b>} {taskName}{' '}
					</Text>

					{/* {!!chain && <ChainList chain={chain} />} */}
					{!!parentOptionsToShow.length &&
						parentOptionsToShow.map((_option) => (
							<Text key={_option._id} fontSize={12}>
								{_option.task_name || 'Selection'}: <b>{_option.label}</b>
							</Text>
						))}
					{!!iteration && iteration > 1 && (
						<Text fontSize={12}>
							<b>{numeral(iteration).format('Oo')}</b> iteration
						</Text>
					)}
				</Stack>
			</Stack>
			{!!numberOfUses && (
				<Text fontSize={14}>
					<b>
						&nbsp;(used {numberOfUses === 1 ? 'once' : numberOfUses}
						{numberOfUses > 1 && ' times'})
					</b>
				</Text>
			)}
		</Box>
	)
}

export const TaskTitle: ComponentType<TaskTitleProps> = ({
	containerProps,
	isSubtask = false,
	iconWidth = 20,
	hideIcon = false,
	task,
}) => {
	const projectGrouping = useSelector((state: RootState) =>
		persistedConfigSelectors.getProjectGroupingConfig(state, task.project_id),
	)
	const iconBgColor = useMemo(() => {
		if (isSubtask) return
		if (task.isMultiUse) return colors.neutral['40']
		return colors.sunglow['10']
	}, [isSubtask])

	const taskAction = useMemo(() => {
		if (!task.actions.length) {
			return StepTypes.Action.ActionEnum.None
		}
		return task.actions[task.actions.length - 1].type
	}, [task])

	return (
		<Box display="flex" alignItems="center" {...containerProps}>
			{!hideIcon && (
				<Box bgcolor={iconBgColor} borderRadius="10px" p="8px" mr={2}>
					{task.isMultiUse ? (
						<AsRequired width={iconWidth} />
					) : taskAction !== StepTypes.Action.ActionEnum.None ? (
						<ActionIcon actionType={taskAction} width={iconWidth} stroke={colors.sunglow['100']} />
					) : null}
				</Box>
			)}
			<Box>
				<TaskTitleContent
					taskName={task.name}
					chain={task.chain}
					iteration={task.iteration}
					numberOfUses={task.isMultiUse ? task.lifecycle.completed_meta?.length : 0}
				/>
				{projectGrouping === ProjectGroupingType.Sections && (
					<Box pt={0.5}>
						<ChainList chain={task.chain} />
					</Box>
				)}
			</Box>
		</Box>
	)
}
