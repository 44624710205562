import { useFieldArray, useFormContext } from 'react-hook-form'
import { v4 } from 'uuid'

import { Box, TextField, Button, IconButton } from 'src/components'
import { TrashIcon } from 'src/assets/icons'

import { StepFormType } from '../step-form-container'

type Props = { actionIndex: number; chainIndex: number; typeOfAction: 'actions' | 'attachments' }

export const LinkInput: React.ComponentType<Props> = ({
	actionIndex,
	chainIndex,
	typeOfAction,
}) => {
	const { control } = useFormContext<StepFormType>()
	const { fields, append, remove, update } = useFieldArray({
		control,
		name: `chain_actions.${chainIndex}.${typeOfAction}.${actionIndex}.links`,
	})

	return (
		<Box>
			{fields.map((field, fieldIndex) => (
				<Box display="flex" key={field._id}>
					<TextField
						label="Link"
						value={field.link}
						fullWidth
						onChange={(e) => update(fieldIndex, { ...field, link: e.target.value as string })}
						containerProps={{ flex: 1, mb: 2 }}
					/>
					<Box>
						<IconButton sx={{ mt: 3, ml: 1 }} onClick={() => remove(fieldIndex)}>
							<TrashIcon width={14} />
						</IconButton>
					</Box>
				</Box>
			))}
			<Button
				variant="outlined"
				size="small"
				onClick={() => {
					append({
						_id: v4(),
						link: '',
					})
				}}
			>
				Add new link
			</Button>
		</Box>
	)
}
