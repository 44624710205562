import { useContext, ComponentType } from 'react'
import { RoleTypes, StepTypes } from '@cango-app/sdk/types'

import { Select, Box, Chip } from 'src/components'
import { ChainContext } from 'src/modules/chains/chain-provider'

type Props = {
	action: StepTypes.Action.StepAction
	update: (action: StepTypes.Action.StepAction) => void
}

export const RoleSelect: ComponentType<Props> = ({ action, update }) => {
	const { rolesForChain } = useContext(ChainContext)
	return (
		<Select
			label={`${action.type} who?`}
			onChange={(e) => {
				const value = e.target.value as string[] | string
				update({ ...action, roles: typeof value === 'string' ? value.split(',') : value })
			}}
			value={action.roles || []}
			multiple
			multiline
			options={rolesForChain}
			containerProps={{ mb: 2, maxWidth: 375 }}
			renderValue={(selected: any) => {
				const roleNames = selected.map((id: string) =>
					rolesForChain.find((role) => role._id === id),
				)
				return (
					<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
						{roleNames.map((role: RoleTypes.ClientRole) => {
							return role ? <Chip key={role._id} label={role.label} /> : ''
						})}
					</Box>
				)
			}}
		/>
	)
}
