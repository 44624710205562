import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AccordionDetails } from '@mui/material'
import Alert from '@mui/material/Alert'
import { useFormContext } from 'react-hook-form'
import React, { ComponentType, useContext, useState } from 'react'
import AccordionSummary from '@mui/material/AccordionSummary/AccordionSummary'
import Accordion from '@mui/material/Accordion/Accordion'

import { Box, Checkbox, IconButton, Select, Text } from 'src/components'
import { useListOptions } from 'src/hooks/use-list-options'
import { ChainContext } from 'src/modules/chains/chain-provider'

import { DependencyForm } from './types'

export const DuplicateRowSelection: ComponentType<{ source?: string }> = ({ source }) => {
	const { setValue, watch } = useFormContext<DependencyForm>()
	const { nodeMap } = useContext(ChainContext)
	const [isExpanded, setIsExpanded] = useState(false)
	const duplication = watch('duplication')
	const fromNodeId = watch('option_condition.from') ?? source ?? ''
	const fromNode = nodeMap.get(fromNodeId)
	const listOptions = useListOptions({
		optionsWithFilter: fromNode?.data.complete_options ?? {
			options: [],
			column_options: undefined,
			table_id: undefined,
		},
		isBlueprint: true,
	})

	const handleCheckboxChange = (isChecked: boolean) => {
		if (!isChecked) {
			setValue('duplication', undefined, { shouldDirty: true })
			return
		}
		setIsExpanded(true)
		setValue('duplication', {
			activated: true,
			selected_options: [],
		})
	}

	return (
		<Accordion expanded={isExpanded && !!duplication}>
			<AccordionSummary
				expandIcon={
					<IconButton onClick={() => setIsExpanded(!isExpanded)} disabled={!duplication}>
						{<ExpandMoreIcon />}
					</IconButton>
				}
			>
				<Box display="flex" alignItems="center">
					<Checkbox
						checked={!!duplication?.activated}
						onChange={(e, isChecked) => {
							e.stopPropagation()
							handleCheckboxChange(isChecked)
						}}
					/>
					<Text>Duplicate row selection</Text>
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				<Alert severity="info" sx={{ mb: 2 }}>
					Leaving the selection blank results in duplicating any selection
				</Alert>
				<Select
					label="Only duplicate selected options"
					value={duplication?.selected_options?.map(({ _id }) => _id) ?? []}
					options={listOptions}
					multiple={true}
					disabled={!fromNode?.data.complete_options?.column_options}
					onChange={(e) => {
						const selectedOptions = (e.target.value as string[]).reduce(
							(
								acc: {
									_id: string
									label: string
								}[],
								option,
							) => {
								const listOption = listOptions.find((listOption) => listOption._id === option)
								if (listOption) {
									acc.push(listOption)
								}
								return acc
							},
							[],
						)
						setValue('duplication.selected_options', selectedOptions)
					}}
				/>
			</AccordionDetails>
		</Accordion>
	)
}
