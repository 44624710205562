import { ComponentType, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'

import { actions as authActions, selectors as authSelectors } from 'src/store/modules/auth'
import { actions as tableActions } from 'src/store/modules/tables'
import { selectors as configSelectors } from 'src/store/modules/config'
import { Box, ForceUpdateOverlay, ErrorBoundary } from 'src/components'
import { RouteId } from 'src/constants/routes'
import { useIsMobile } from 'src/hooks/useMobile'
import { DesktopNavigation, MobileNavigation } from 'src/routing/navigation'
import { APP_BAR_HEIGHT } from 'src/routing/navigation/desktop-navigation'
import { useRoles } from 'src/hooks/useRoles'
import { useContacts } from 'src/hooks/useContacts'
import { useUsers } from 'src/hooks/useUsers'
import { useInbox } from 'src/hooks/useInbox'
import { actions as imageActions } from 'src/store/modules/images'

import { getRouteName } from './utils'
import { STAGING_ALERT_BANNER_HEIGHT } from './navigation/staging-alert'

export const Dashboard: ComponentType = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const navigate = useNavigate()
	const isMobile = useIsMobile()
	useRoles()
	useContacts()
	useUsers()
	useInbox()
	const organisationCode = useSelector(configSelectors.getOrganisationCode)
	const firebaseToken = useSelector(authSelectors.getFirebaseToken)
	const currentPath = location.pathname

	useEffect(() => {
		if (!organisationCode) {
			dispatch(authActions.logoutUser())
		}
	}, [organisationCode, dispatch])
	useEffect(() => {
		const routeName = getRouteName(currentPath)
		document.title = `${organisationCode} - ${routeName} - Cango`

		if (organisationCode && isMobile && !currentPath.includes(`/${RouteId.MyTasks}`)) {
			navigate(`/${RouteId.MyTasks}`)
		}
	}, [currentPath, isMobile, organisationCode])

	useEffect(() => {
		if (organisationCode && !firebaseToken) {
			dispatch(authActions.fetchFirebaseToken())
		}
		dispatch(tableActions.getTableList())
		dispatch(imageActions.getImages())
	}, [])

	if (!organisationCode) {
		return <Navigate to="/login" />
	}

	return (
		<>
			{!isMobile && <DesktopNavigation />}
			<Box
				display="flex"
				flexDirection="column"
				height={{
					laptop: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
					mobile: `calc(100dvh - ${STAGING_ALERT_BANNER_HEIGHT}px)`,
				}}
			>
				<Box flexGrow={1} overflow="auto">
					<Box
						width={{ laptop: '100%' }}
						height={{ laptop: '100%' }}
						style={{ display: 'flex', flexDirection: 'column' }}
					>
						<ErrorBoundary>
							<Outlet />
						</ErrorBoundary>
					</Box>
				</Box>
				{isMobile && <MobileNavigation />}
			</Box>
			<ForceUpdateOverlay />
		</>
	)
}
