import { ComponentType, useContext, useState } from 'react'
import { Handle, NodeProps, Position } from '@xyflow/react'
import Stack from '@mui/system/Stack/Stack'
import ReplayIcon from '@mui/icons-material/Replay'

import { Box, Button, Modal, Text } from 'src/components'
import { LoopNode as LoopNodeType, StandardNode } from 'src/modules/chains/types'
import { ChainContext } from 'src/modules/chains/chain-provider'
import { colors } from 'src/theme/colors'

export const LoopNode: ComponentType<NodeProps<LoopNodeType>> = ({ data }) => {
	const { nodeMap, onUpdateConnection } = useContext(ChainContext)
	const step = nodeMap.get(data.step) as StandardNode
	const [settingsOpen, setSettingsOpen] = useState(false)

	const handleDeleteLoop = async () => {
		await onUpdateConnection({
			_id: data.descendant._id,
			connection: { source: data.source, target: data.step },
			method: 'remove',
		})
	}

	if (!step || step.type !== 'standard') {
		return null
	}

	return (
		<>
			{settingsOpen && (
				<Modal open={true} onClose={() => setSettingsOpen(false)}>
					<Box>
						<Button color="error" onClick={handleDeleteLoop}>
							Delete loop
						</Button>
					</Box>
				</Modal>
			)}
			<Box
				sx={{
					bgcolor: colors.terracotta['60'],
					px: 2,
					py: 1,
					borderRadius: 1,
				}}
				onClick={() => setSettingsOpen(true)}
			>
				<Handle type="target" position={Position.Top} isConnectable={false} />
				<Stack direction="row">
					<ReplayIcon fontSize="small" sx={{ mr: 1 }} />
					<Text fontSize="small">{step.data.name}</Text>
				</Stack>
			</Box>
		</>
	)
}
