import { StepTypes, TaskTypes, TableTypes } from '@cango-app/sdk/types'
import { v4 } from 'uuid'

import {
	buildFilterModel,
	buildParentChains,
	getNewChainAfterEnding,
} from 'src/modules/my-tasks-v3/components/complete-task-cta/utils'

import { CompletingTask } from '../types'

type StepWithOptions = {
	stepId: string
	options: TaskTypes.CompletedOption[]
	column?: Pick<TableTypes.Field, '_id' | 'type'>
}

const getStepsWithOptions = ({
	descendant,
	selectedOptions,
}: {
	descendant: TaskTypes.PopulatedStep.ProjectDescendant
	selectedOptions: TaskTypes.CompletedOption[]
}): StepWithOptions | undefined => {
	const stepId = descendant.step?._id
	if (!stepId) {
		return
	}

	const optionCondition = descendant.option_condition

	const isAnyCondition =
		!optionCondition || optionCondition?.operator === StepTypes.ChildConditionOperator.Any
	const isMatchingCondition =
		optionCondition?.operator === StepTypes.ChildConditionOperator.Is &&
		optionCondition.values.some((_val) => selectedOptions.some((_option) => _option._id === _val))
	const isNotMatchingCondition =
		optionCondition?.operator === StepTypes.ChildConditionOperator.IsNot &&
		descendant.option_condition?.values.every(
			(_val) => !selectedOptions.some((_option) => _option._id === _val),
		)

	if (isAnyCondition || isMatchingCondition || isNotMatchingCondition) {
		return {
			stepId,
			options: !isNotMatchingCondition ? selectedOptions : [],
		}
	}
}

const createChain = ({
	mappedProjectTasks,
	completingTask,
	descendant,
	stepWithOptions,
}: {
	mappedProjectTasks: Map<string, TaskTypes.PopulatedTask>
	completingTask: CompletingTask
	descendant: TaskTypes.PopulatedStep.ProjectDescendant
	stepWithOptions: StepWithOptions
}): TaskTypes.Chain.ProjectChain | undefined => {
	const parentTask = mappedProjectTasks.get(completingTask.parent?._id)
	const parentChains = parentTask?.chain?.parent_chains ?? []

	if (descendant.thread) {
		return {
			...descendant.thread,
			_id: v4(),
			parent_chains: buildParentChains({ parentChains, parentTask, descendant }),
			option_id: '',
			label: {
				prefix: descendant.thread.prefix,
				selected_option:
					stepWithOptions.options.length === 1 ? stepWithOptions.options[0].label : '',
				columnType: stepWithOptions.column?.type,
				color: descendant.thread.color,
				table: descendant.thread?.table,
			},
			database_chain_logic: {
				filters: buildFilterModel({
					descendant,
					options: {
						columnId: stepWithOptions.column?._id,
						labels: stepWithOptions.options.map((_option) => _option.label),
					},
				}),
			},
			original_descendant_id: descendant._id,
		}
	}

	const parentTaskChain = completingTask?.chain
	if (!parentTaskChain?._id) {
		return undefined
	}

	const newChainAfterEnding = getNewChainAfterEnding({
		projectChain: parentTaskChain,
		parentChains,
		descendant,
	})

	if (!newChainAfterEnding) {
		return undefined
	}

	return newChainAfterEnding
}

const createUnlinkedTaskWithChain = ({
	mappedProjectTasks,
	completingTask,
	descendant,
	stepWithOptions,
}: {
	mappedProjectTasks: Map<string, TaskTypes.PopulatedTask>
	completingTask: CompletingTask
	descendant: TaskTypes.PopulatedStep.ProjectDescendant
	stepWithOptions?: StepWithOptions
}): { tasks: TaskTypes.Task[]; chains: TaskTypes.Chain.ProjectChain[] } => {
	const blueprintStep = descendant.step
	if (!blueprintStep || !stepWithOptions) {
		return {
			tasks: [],
			chains: [],
		}
	}
	const chain = createChain({
		mappedProjectTasks,
		completingTask,
		descendant,
		stepWithOptions,
	})

	const newChains: TaskTypes.Chain.ProjectChain[] = []
	if (chain && chain?._id !== completingTask.chain?._id) {
		newChains.push(chain)
	}

	return {
		chains: newChains,
		tasks: [
			{
				...blueprintStep,
				_id: v4(),
				chain,
				step: blueprintStep._id,
				project_id: completingTask.project_id,
				notes: [],
				annotations: [],
				parent: completingTask._id,
				assignees: [],
				lifecycle: {
					complete: false,
					completed_meta: [],
					completed_options: [],
				},
				unblock_task: undefined,
				section: completingTask.section,
				iteration: 1,
				actions: [],
				attachments: [],
			},
		],
	}
}

export const createTasksFromSingleChainSelect = ({
	selectedOptions,
	descendant,
	mappedProjectTasks,
	completingTask,
}: {
	descendant: TaskTypes.PopulatedStep.ProjectDescendant
	selectedOptions: TaskTypes.CompletedOption[]
	mappedProjectTasks: Map<string, TaskTypes.PopulatedTask>
	completingTask: CompletingTask
}): { tasks: TaskTypes.Task[]; chains: TaskTypes.Chain.ProjectChain[] } => {
	const stepWithOptions = getStepsWithOptions({
		descendant,
		selectedOptions,
	})

	return createUnlinkedTaskWithChain({
		mappedProjectTasks,
		completingTask,
		descendant,
		stepWithOptions,
	})
}
