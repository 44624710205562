import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { GridFilterModel } from '@mui/x-data-grid-premium'
import React, { ComponentType, useContext, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Box, Checkbox, IconButton, Text, Toggle } from 'src/components'
import { TableFilters } from 'src/components/table-filters'
import { ChainContext } from 'src/modules/chains/chain-provider'
import { DependencyForm } from 'src/modules/chains/components/chain-dependency-edge/types'
import { TableProvider } from 'src/providers'

const CreateConditionsComponent: ComponentType = () => {
	const { watch, setValue } = useFormContext<DependencyForm>()
	const [isExpanded, setIsExpanded] = useState(false)
	const createConditions = watch('create_conditions')

	const handleFiltersChange = (newFilters: GridFilterModel) => {
		setValue('create_conditions.filter', newFilters)
	}

	const handleCreateConditionsChange = (isChecked: boolean) => {
		if (isChecked) {
			setValue('create_conditions.filter', {
				items: [],
			})
			setIsExpanded(true)
			return
		}
		setValue('create_conditions', undefined, { shouldDirty: true })
	}

	const handleToggleChange = (newValue: boolean) => {
		setValue('create_conditions.returnTrueForNoRows', newValue)
	}

	return (
		<Accordion expanded={isExpanded && !!createConditions}>
			<AccordionSummary
				expandIcon={
					<IconButton onClick={() => setIsExpanded(!isExpanded)} disabled={!createConditions}>
						{<ExpandMoreIcon />}
					</IconButton>
				}
			>
				<Box display="flex" alignItems="center">
					<Checkbox
						checked={!!createConditions}
						onChange={(e, isChecked) => {
							e.stopPropagation()
							handleCreateConditionsChange(isChecked)
						}}
					/>
					<Text>Only create the next task if database conditions are met</Text>
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				<Toggle
					options={[
						{ label: 'When there are rows', value: false },
						{ label: 'When there are NO rows', value: true },
					]}
					onChange={(newValue) => {
						handleToggleChange(newValue)
					}}
					value={!!createConditions?.returnTrueForNoRows}
					sx={{ mb: 2 }}
				/>
				<TableFilters
					initialState={{
						filter: {
							filterModel: createConditions?.filter,
						},
					}}
					onChange={handleFiltersChange}
				/>
			</AccordionDetails>
		</Accordion>
	)
}

export const CreateConditions: ComponentType = () => {
	const { tableConfig } = useContext(ChainContext)

	if (!tableConfig?._id) {
		return <Text>No database table selected</Text>
	}

	return (
		<TableProvider tableId={tableConfig._id}>
			<CreateConditionsComponent />
		</TableProvider>
	)
}
