import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import React, { ComponentType } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Box, Checkbox, Text } from 'src/components'
import { DependencyForm } from 'src/modules/chains/components/chain-dependency-edge/types'

export const ApplyOptionSelectionToDatabase: ComponentType = () => {
	const { control } = useFormContext<DependencyForm>()

	return (
		<Accordion expanded={false}>
			<AccordionSummary>
				<Box display="flex" alignItems="center">
					<Controller
						control={control}
						name="option_condition.add_selection_to_database_chain_filters"
						render={({ field: { value, onChange } }) => (
							<Checkbox
								checked={value}
								onChange={(e, isChecked) => {
									e.stopPropagation()
									onChange(isChecked)
								}}
							/>
						)}
					/>
					<Text>Apply selections to database filters</Text>
				</Box>
			</AccordionSummary>
		</Accordion>
	)
}
