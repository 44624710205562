import { StepTypes } from '@cango-app/sdk/types'

import { EdgeData } from 'src/modules/chains/types'

export enum DependencyType {
	WHEN_THIS_TASK_COMPLETES = 'WHEN_THIS_TASK_COMPLETES',
	WHEN_AN_OPTION_SELECTED = 'WHEN_AN_OPTION_SELECTED',
}

export type DependencyForm = Pick<
	StepTypes.Descendant,
	| 'option_condition'
	| 'database_changes'
	| 'chain_endings'
	| 'create_conditions'
	| 'database_chain_logic'
	| 'duplication'
> & {
	createForEveryOption: boolean
	chainPrefix: string
	multiUseConfig?: StepTypes.ReturnToMultiUseConfig | null
}

export type DependencyModalProps = EdgeData & {
	source: string
	target: string
	onDeleteConnection?: () => void
	onClose: () => void
}
