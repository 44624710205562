import React, { ComponentType } from 'react'
import { TaskTypes } from '@cango-app/sdk/types'
import { useSelector } from 'react-redux'

import { ChainList } from 'src/components/section-tasks-v3/chains-list'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'
import { Box, Grid, Text } from 'src/components'
import { OnDemandChainSelection } from 'src/modules/my-tasks-v3/components/on-demand-chain-selection'

type SectionTitleBarProps = {
	sectionName: string
	rowCount?: number
	chain: TaskTypes.PopulatedTask['chain']
}

export const SectionTitleBar: ComponentType<SectionTitleBarProps> = ({
	sectionName,
	rowCount,
	chain,
}) => {
	const projectWideChains = useSelector(projectSelectors.getProjectWideChains)

	return (
		<>
			<Grid container mb={1}>
				<Grid xs={6}>
					<Box display="flex" alignItems="flex-start" minHeight="50px" flexDirection="column">
						<Text variant="h5" fontWeight={500}>
							{sectionName}
						</Text>
						{!!chain && <ChainList chain={chain} />}
					</Box>

					{!!rowCount && (
						<Text fontSize={14} variant="overline" lineHeight={1}>{`${rowCount} task${
							rowCount !== 0 ? 's' : ''
						}`}</Text>
					)}
				</Grid>
				<Grid xs={6} display="flex" justifyContent="flex-end" alignItems="center">
					{!!projectWideChains.length && <OnDemandChainSelection chainId={chain?._id} />}
				</Grid>
			</Grid>
		</>
	)
}
