import React, { ComponentType, useCallback, useContext, useMemo, useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { StepTypes, TableTypes } from '@cango-app/sdk/types'
import { v4 } from 'uuid'
import _uniq from 'lodash/uniq'
import { Alert, Badge, Stack } from '@mui/material'
import _isString from 'lodash/isString'
import AlarmOutlinedIcon from '@mui/icons-material/AlarmOutlined'
import AlarmOnOutlinedIcon from '@mui/icons-material/AlarmOnOutlined'
import { TIME_OPTIONS } from '@cango-app/sdk/utils'

import { ChainContext } from 'src/modules/chains/chain-provider'
import { Box, Button, IconButton, Select, TextField, Toggle, Text, Modal } from 'src/components'
import { TrashIcon } from 'src/assets/icons'
import { TableContext, TableProvider } from 'src/providers/table-provider'
import { TableFilters } from 'src/components/table-filters'
import { handlePasteOptions } from 'src/components/utils'

import { StepFormType } from './step-form-container'

type ChildItemProps = {
	index: number
	onRemove: (itemId: string) => void
	item: {
		_id: string
		label: string
		dueTime?: {
			time: string
			when: StepTypes.WhenDueTime
		}
	}
	onPaste: (data: React.ClipboardEvent<HTMLDivElement>, index: number) => void
	update: (option: StepTypes.StepOption) => void
}

enum ListOriginType {
	Custom = 'Custom',
	DatabaseColumn = 'DatabaseColumn',
}

const ChildItem: ComponentType<ChildItemProps> = ({ item, onRemove, index, onPaste, update }) => {
	const { setValue } = useFormContext<StepFormType>()
	const [openTimeModal, setOpenTimeModal] = useState(false)

	const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
		e.preventDefault()
		e.stopPropagation()

		const pastedText = e.clipboardData.getData('text')
		if (!pastedText.trim()) return

		const firstLine = pastedText.split('\n')[0].trim()
		setValue(`complete_options.options.${index}.label`, firstLine, {
			shouldDirty: true,
			shouldTouch: true,
		})
		if (onPaste) {
			onPaste(e, index)
		}
	}

	return (
		<>
			<Box display="flex" alignItems="center">
				<TextField
					label={`Option ${index + 1}`}
					onChange={(e) => update({ ...item, label: e.target.value })}
					value={item.label}
					fullWidth
					containerProps={{ mr: 1.5, my: 1.5, flex: 1 }}
					onPaste={handlePaste}
				/>
				<IconButton
					onClick={() => setOpenTimeModal(true)}
					aria-label="Set recommended time for task"
				>
					{item?.dueTime ? (
						<Badge color="primary" badgeContent={item?.dueTime.time}>
							<AlarmOnOutlinedIcon />
						</Badge>
					) : (
						<AlarmOutlinedIcon />
					)}
				</IconButton>
				<IconButton onClick={() => onRemove(item._id)}>
					<TrashIcon />
				</IconButton>
			</Box>
			<Modal open={openTimeModal} onClose={() => setOpenTimeModal(false)}>
				<Stack direction="column" spacing={2}>
					<Text variant="h5">Set recommended time for this task:</Text>
					<Select
						withNoneOption
						label="Time:"
						options={TIME_OPTIONS}
						value={item.dueTime?.time ?? ''}
						onChange={(e) =>
							update({
								...item,
								dueTime: {
									...(item.dueTime ?? { when: StepTypes.WhenDueTime.before }),
									time: e.target.value as string,
								},
							})
						}
						disableOrdering
						helperText={
							<Box>
								<Text fontSize={12}>Task can be completed within the time and afterward</Text>
							</Box>
						}
					/>
					<Select
						withNoneOption
						label="When:"
						options={Object.keys(StepTypes.WhenDueTime).map((when) => ({
							_id: when,
							label: when,
						}))}
						value={item.dueTime?.when ?? ''}
						onChange={(e) =>
							update({
								...item,
								dueTime: {
									...(item.dueTime ?? { time: '1' }),
									when: e.target.value as StepTypes.WhenDueTime,
								},
							})
						}
						disableOrdering
					/>
					<Stack flex={1} direction="row" justifyContent="space-between">
						<Button onClick={() => setOpenTimeModal(false)}>Close</Button>
						<Button
							variant="text"
							onClick={() => {
								setValue(`complete_options.options.${index}.dueTime`, undefined, {
									shouldDirty: true,
								})
								setOpenTimeModal(false)
							}}
						>
							Clear
						</Button>
					</Stack>
				</Stack>
			</Modal>
		</>
	)
}

const CustomOptions: ComponentType = () => {
	const { control, setValue } = useFormContext<StepFormType>()
	const { fields, append, remove, update } = useFieldArray({
		control,
		name: 'complete_options.options',
	})

	const handlePaste = useCallback(
		(e: React.ClipboardEvent<HTMLDivElement>, index: number) => {
			e.preventDefault()
			e.stopPropagation()
			const newOptions = handlePasteOptions(e, fields ?? [], index)
			setValue('complete_options.options', newOptions, { shouldDirty: true, shouldTouch: true })
		},
		[fields, setValue],
	)

	const handleNewItem = () => {
		append({
			_id: v4(),
			label: '',
		})
	}

	return (
		<>
			{fields.map((item, index) => {
				if (!item) {
					return null
				}
				return (
					<Box key={item._id}>
						<ChildItem
							index={index}
							onRemove={() => remove(index)}
							item={item}
							onPaste={handlePaste}
							update={(option: StepTypes.StepOption) => update(index, option)}
						/>
					</Box>
				)
			})}
			<Button sx={{ my: 2, mt: 1 }} variant="outlined" size="small" onClick={handleNewItem}>
				Add an option
			</Button>
		</>
	)
}

const ColumnOptions: ComponentType = () => {
	const { watch, setValue } = useFormContext<StepFormType>()
	const { resolvedRows, mappedColumns } = useContext(TableContext)
	const completeOptions = watch('complete_options')
	const { connectedTables, tableConfig } = useContext(ChainContext)

	const columnHasDuplicatedValues = useMemo(() => {
		const columnOptions = completeOptions.column_options
		if (!columnOptions) {
			return false
		}
		if (!resolvedRows.length) return false
		const columnValues = resolvedRows.reduce((_colValues: string[], _record) => {
			if (!_record[columnOptions]) {
				return _colValues
			}
			return [..._colValues, String(_record[columnOptions])]
		}, [])
		return _uniq(columnValues).length !== columnValues.length
	}, [completeOptions, resolvedRows])

	const handleTableOptionsChange = (newOption: string) => {
		setValue(
			'complete_options',
			{
				table_id: newOption,
				column_options: '',
				filter: {
					items: [],
				},
				options: [],
			},
			{ shouldDirty: true },
		)
	}

	const handleColumnOptionsChange = (newOption: string) => {
		setValue('complete_options.column_options', newOption, { shouldDirty: true })
	}

	const handleNewFilters = (filters: TableTypes.FilterModel) => {
		setValue('complete_options.filter', filters, { shouldDirty: true })
	}

	return (
		<Box my={2}>
			<Select
				options={[
					...connectedTables,
					...(tableConfig ? [{ _id: tableConfig._id, label: tableConfig.name }] : []),
				]}
				value={completeOptions.table_id}
				onChange={(e) => handleTableOptionsChange(e.target.value as string)}
				containerProps={{ mb: 2 }}
				label="Table"
			/>
			<Select
				options={[...mappedColumns.keys()].map((_columnKey) => ({
					_id: _columnKey,
					label: mappedColumns.get(_columnKey)?.name ?? '',
				}))}
				value={completeOptions.column_options}
				onChange={(e) => handleColumnOptionsChange(e.target.value as string)}
				containerProps={{ mb: 2 }}
				label="Column"
			/>
			{columnHasDuplicatedValues && (
				<Alert severity="warning">This column has duplicated values</Alert>
			)}
			<Box mt={1}>
				<TableFilters
					initialState={{
						filter: { filterModel: completeOptions.filter },
					}}
					onChange={handleNewFilters}
				/>
			</Box>
		</Box>
	)
}

const OptionsHandler: ComponentType = () => {
	const { watch } = useFormContext<StepFormType>()
	const optionsField = watch('complete_options')

	if (!optionsField) {
		return null
	}

	if (optionsField.table_id && _isString(optionsField.table_id ?? optionsField.column_options)) {
		return (
			<TableProvider tableId={optionsField.table_id}>
				<ColumnOptions />
			</TableProvider>
		)
	}

	if (typeof optionsField.options === 'string') {
		return null
	}

	return <CustomOptions />
}

export const UndecoratedOptionsFields: ComponentType = () => {
	const { control, watch, setValue } = useFormContext<StepFormType>()
	const [chainActions, completeOptions] = watch(['chain_actions', 'complete_options'])
	const { tableConfig } = useContext(ChainContext)

	const hasArchiveTypeAction = useMemo(() => {
		return chainActions.some((action) =>
			action.actions.some((a) => a.type === StepTypes.Action.ActionEnum.Archive),
		)
	}, [chainActions])

	const currentOptionType = useMemo(() => {
		return _isString(completeOptions.table_id ?? completeOptions.column_options)
			? ListOriginType.DatabaseColumn
			: ListOriginType.Custom
	}, [completeOptions])

	const handleOptionTypeChange = (oldValue: ListOriginType, newValue: ListOriginType) => {
		if (oldValue === newValue) {
			return
		}
		if (oldValue === ListOriginType.Custom) {
			setValue(
				'complete_options',
				{
					table_id: tableConfig?._id ?? '',
					column_options: '',
					options: [],
					filter: {
						items: [],
					},
				},
				{ shouldDirty: true },
			)
			return
		}

		setValue(
			'complete_options',
			{
				table_id: undefined,
				options: [],
				column_options: undefined,
			},
			{ shouldDirty: true },
		)
	}

	if (hasArchiveTypeAction) {
		return null
	}

	return (
		<Box mt={2}>
			<Toggle
				value={currentOptionType}
				onChange={(newValue) =>
					handleOptionTypeChange(currentOptionType, newValue as ListOriginType)
				}
				options={[
					{ value: ListOriginType.Custom, label: 'Custom' },
					{ value: ListOriginType.DatabaseColumn, label: 'Database Column' },
				]}
			/>
			<Box>
				<OptionsHandler />

				<Controller
					name={'isMenu'}
					control={control}
					render={({ field: { onChange, value } }) => (
						<Toggle
							label="How many options can be chosen?"
							value={value}
							options={[
								{
									label: 'Only one',
									value: false,
								},
								{
									label: 'Multiple',
									value: true,
								},
							]}
							onChange={onChange}
							containerProps={{ mb: 1.5 }}
						/>
					)}
				/>
			</Box>
		</Box>
	)
}

export const OptionsFields = UndecoratedOptionsFields
