import { StepTypes } from '@cango-app/sdk/types'
import React, { ComponentType, useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { EditPenIcon, TrashIcon } from 'src/assets/icons'
import { Box, IconButton, Select, Text } from 'src/components'
import { ChainContext } from 'src/modules/chains/chain-provider'
import { useChainPermissions } from 'src/modules/chains/useChainPermissions'
import { ViewsButton } from 'src/modules/tables/views-button'
import { TableProvider } from 'src/providers'
import { RootState } from 'src/store/types'
import { colors } from 'src/theme/colors'
import { selectors as tableSelectors } from 'src/store/modules/tables'

type StepResourcesProps = {
	action: StepTypes.Action.StepAction
	update: (action: StepTypes.Action.StepAction) => void
}

const SetResourcesComponent: ComponentType<StepResourcesProps> = ({ action, update }) => {
	const { connectedTables, tableConfig: chainTable } = useContext(ChainContext)
	const { canEditChain } = useChainPermissions()
	const [shouldOpenViewForm, setShouldOpenViewForm] = useState(false)
	const tableConfig = useSelector((state: RootState) =>
		tableSelectors.selectTableConfig(state, action.table_view?.table ?? chainTable?._id ?? ''),
	)

	const viewSelected = useMemo(() => {
		if (!tableConfig?.views || !action.table_view?.view) {
			return null
		}

		return tableConfig.views.find((_view) => _view._id === action.table_view?.view) ?? null
	}, [action.table_view?.view, tableConfig?.views])

	const handleTableChange = (table: string) => {
		update({
			...action,
			table_view: {
				table,
			},
		})
	}

	return (
		<>
			{!!viewSelected && (
				<Box display="flex" justifyContent="space-between" mb={2}>
					<Box
						display="flex"
						justifyContent="space-between"
						border="1px solid"
						borderColor={colors.neutral['40']}
						borderRadius={2}
						px={2}
						py={1}
						minWidth={175}
						bgcolor="#fff"
					>
						<Box>
							<Text variant="overline">View</Text>
							<Text>{viewSelected.name}</Text>
						</Box>
						{canEditChain && (
							<Box sx={{ ml: 2 }} display="flex" alignItems="center">
								<IconButton onClick={() => setShouldOpenViewForm(true)}>
									<EditPenIcon stroke={colors.neutral['60']} />
								</IconButton>
								<IconButton
									onClick={() =>
										update({
											...action,
											table_view: {
												table: action.table_view?.table ?? tableConfig?._id ?? '',
											},
										})
									}
								>
									<TrashIcon stroke={colors.neutral['60']} />
								</IconButton>
							</Box>
						)}
					</Box>
				</Box>
			)}
			<Box mb={2}>
				<Select
					label="Table"
					value={action.table_view?.table}
					onChange={(e) => handleTableChange(e.target.value as string)}
					options={[
						...connectedTables,
						...(chainTable ? [{ _id: chainTable._id, label: chainTable.name }] : []),
					]}
					containerProps={{ mb: 1 }}
				/>
				<ViewsButton
					openViewParams={{
						open: shouldOpenViewForm,
						viewId: action.table_view?.view,
					}}
					onViewSelect={(viewId) => {
						update({
							...action,
							table_view: {
								table: action.table_view?.table ?? tableConfig?._id ?? '',
								view: viewId,
							},
						})
						if (!viewId) {
							setShouldOpenViewForm(true)
						}
					}}
					onClose={() => setShouldOpenViewForm(false)}
					label={viewSelected ? 'Change view' : 'Select view'}
					buttonVariant="outlined"
					size="small"
					onViewSaved={(viewId) =>
						update({
							...action,
							table_view: {
								table: action.table_view?.table ?? tableConfig?._id ?? '',
								view: viewId,
							},
						})
					}
				/>
			</Box>
		</>
	)
}

export const SetResourcesFields: ComponentType<StepResourcesProps> = (props) => {
	const { tableConfig } = useContext(ChainContext)

	if (!tableConfig?._id) {
		return <Text>No database table linked to Blueprint</Text>
	}

	return (
		<TableProvider tableId={props.action.table_view?.table ?? tableConfig._id}>
			<SetResourcesComponent {...props} />
		</TableProvider>
	)
}
