import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Accordion from '@mui/material/Accordion'
import React, { ComponentType, useCallback, useContext, useMemo, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { TableTypes } from '@cango-app/sdk/types'
import { useSelector } from 'react-redux'
import { GridFilterModel } from '@mui/x-data-grid-premium'
import PulseLoader from 'react-spinners/PulseLoader'
import _isEqual from 'lodash/isEqual'

import { ChainContext } from 'src/modules/chains/chain-provider'
import { selectors as contactSelectors } from 'src/store/modules/contacts'
import { TableContext, TableProvider } from 'src/providers'
import { Box, Checkbox, IconButton, Select, Text } from 'src/components'
import { ChainChip } from 'src/components/section-tasks-v3/chains-list'
import { TableFilters } from 'src/components/table-filters'

import { getFilters } from '../../../tables/utils'

import { DependencyForm } from './types'

const DependencyFromDatabaseComponent: ComponentType = () => {
	const { columnList, resolvedRows, isLoadingTable, mappedColumns } = useContext(TableContext)
	const { connectedTables, tableConfig } = useContext(ChainContext)
	const { control, setValue, watch } = useFormContext<DependencyForm>()
	const [firstFilterRender, setFirstFilterRender] = useState<GridFilterModel>()
	const [isExpanded, setIsExpanded] = useState(false)
	const [databaseLogic, createForEveryOption] = watch([
		'database_chain_logic',
		'createForEveryOption',
	])
	const databaseLogicColumn = databaseLogic?.column ?? ''
	const databaseFilters: GridFilterModel = databaseLogic?.filters ?? { items: [] }

	const mappedContacts = useSelector(contactSelectors.mappedContacts)

	const chainPrefix = useMemo(() => {
		return mappedColumns.get(databaseLogicColumn)?.name ?? ''
	}, [databaseLogicColumn, mappedColumns])

	const exampleValue = useMemo(() => {
		if (!databaseLogicColumn || !resolvedRows.length) {
			return ''
		}

		const record = resolvedRows.find((_record) => _record[databaseLogicColumn])

		if (!record) {
			return ''
		}

		const column = mappedColumns.get(databaseLogicColumn)

		if (!column) {
			return ''
		}

		const recordValue = record[databaseLogicColumn]

		if (column.type === TableTypes.FieldType.CONTACT && typeof recordValue === 'string') {
			const contact = mappedContacts.get(recordValue)
			if (contact) {
				return `${contact.name} ${contact.surname}`
			}
		}

		const valueOption = column.valueOptions?.find((_option) => _option._id === recordValue)

		if (valueOption) {
			return valueOption.label
		}

		return String(recordValue)
	}, [resolvedRows, databaseLogicColumn])

	const handleSetFilters = useCallback(
		(filters: GridFilterModel) => {
			if (!databaseLogic) {
				return
			}
			if (!firstFilterRender && !filters.items.length && databaseFilters.items.length) {
				setFirstFilterRender(filters)
				return
			}

			if (!firstFilterRender) {
				setFirstFilterRender(filters)
			}

			if (_isEqual(databaseFilters, filters)) {
				return
			}

			setValue('database_chain_logic.filters', filters)
		},
		[databaseFilters, firstFilterRender, databaseLogic],
	)

	const handleCheckboxClicked = (isChecked: boolean) => {
		setValue('chainPrefix', '')
		if (isChecked) {
			setValue('database_chain_logic', {
				table: '',
				column: '',
				filters: { items: [] },
			})
			if (createForEveryOption) {
				setValue('createForEveryOption', false)
			}
			setIsExpanded(true)
			return
		}
		setValue('database_chain_logic', null)
	}

	return (
		<Accordion expanded={isExpanded && !!databaseLogic}>
			<AccordionSummary
				expandIcon={
					<IconButton onClick={() => setIsExpanded(!isExpanded)} disabled={!databaseLogic}>
						{<ExpandMoreIcon />}
					</IconButton>
				}
			>
				<Box display="flex" alignItems="center">
					{isLoadingTable ? (
						<Box mr={2}>
							<PulseLoader size={6} />
						</Box>
					) : (
						<Checkbox
							onChange={(e, isChecked) => {
								e.stopPropagation()
								handleCheckboxClicked(isChecked)
							}}
							checked={!!databaseLogic}
						/>
					)}
					<Text>Create chains from the database</Text>
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				<Box>
					{!!databaseLogic && (
						<>
							<Controller
								control={control}
								name="database_chain_logic.table"
								render={({ field: { value, onChange } }) => {
									const handleColumnSelectionChange = (newTableId: string) => {
										if (!databaseLogic) {
											return
										}
										onChange(newTableId)
									}

									return (
										<Select
											options={[
												...connectedTables,
												...(tableConfig?._id
													? [{ _id: tableConfig._id, label: tableConfig.name }]
													: []),
											]}
											disableOrdering
											containerProps={{ my: 1 }}
											label="Table"
											onChange={(e) => handleColumnSelectionChange(e.target.value as string)}
											value={value}
										/>
									)
								}}
							/>
							<Controller
								control={control}
								name="database_chain_logic.column"
								render={({ field: { value, onChange } }) => {
									const handleColumnSelectionChange = (newColumnId: string) => {
										if (!databaseLogic) {
											return
										}
										setValue('chainPrefix', mappedColumns.get(newColumnId)?.name ?? '')
										onChange(newColumnId)
									}

									return (
										<Select
											options={columnList}
											disableOrdering
											containerProps={{ my: 1 }}
											label="Column"
											onChange={(e) => handleColumnSelectionChange(e.target.value as string)}
											value={value}
											helperText={
												<Box>
													<Text
														fontSize={12}
													>{`Tags will be constructed according to the column name`}</Text>
													<Box display="flex" alignItems="center">
														<Text fontSize={12} mr={1}>
															{'e.g.'}
														</Text>
														<ChainChip
															prefix={chainPrefix || '[example]'}
															selected_option={exampleValue || 'Example option 1'}
															isLoadingTable={false}
															color={'#c4def6'}
															columnType={mappedColumns.get(databaseLogicColumn)?.type}
															table={''}
														/>
													</Box>
												</Box>
											}
										/>
									)
								}}
							/>
						</>
					)}

					<TableFilters
						initialState={{
							filter: {
								filterModel: {
									...databaseFilters,
									items: getFilters({
										items: databaseFilters.items,
										mappedColumns,
										chains: [],
									}),
								},
							},
						}}
						onChange={handleSetFilters}
					/>
				</Box>
			</AccordionDetails>
		</Accordion>
	)
}

export const DependencyFromDatabase: ComponentType = () => {
	const { tableConfig } = useContext(ChainContext)
	const tableId: string | undefined = useWatch<DependencyForm>({
		name: 'database_chain_logic.table',
	})

	if (!tableConfig?._id) {
		return <Text>No database table selected</Text>
	}

	return (
		<TableProvider tableId={tableId ?? tableConfig._id}>
			<DependencyFromDatabaseComponent />
		</TableProvider>
	)
}
