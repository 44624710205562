import React, { ComponentType } from 'react'
import { StepTypes } from '@cango-app/sdk/types'
import { Controller, useFieldArray } from 'react-hook-form'

import { Box, Button, Select, TextField } from 'src/components'
import { getTrainingTypeLabel } from 'src/helpers/labels'
import { TrashIcon } from 'src/assets/icons'
import { colors } from 'src/theme/colors'
import { useChainPermissions } from 'src/modules/chains/useChainPermissions'

import { StepFormControl } from './step-form-container'

type TrainingFieldsProps = {
	control: StepFormControl
}

const UndecoratedTrainingFields: ComponentType<TrainingFieldsProps> = ({ control }) => {
	const { fields, append, remove } = useFieldArray({ control, name: 'training' })
	const { canEditChain } = useChainPermissions()

	return (
		<Box mt={2} display="flex" flexDirection="column">
			{fields.map((field, index) => (
				<Box
					key={field.id}
					sx={{
						backgroundColor: 'rgba(164, 198, 188, 0.1)',
						p: 2,
						borderRadius: '8px',
						mb: 2,
					}}
				>
					<Controller
						control={control}
						name={`training.${index}.type`}
						rules={{ required: 'Please select a type' }}
						render={({ field: { value, onChange } }) => (
							<Select
								label="Type"
								onChange={(event) =>
									onChange(event.target.value as StepTypes.Training.TrainingEnum)
								}
								value={value}
								options={Object.values(StepTypes.Training.TrainingEnum).map((item) => ({
									_id: item,
									label: getTrainingTypeLabel(item),
								}))}
								containerProps={{ mb: 1, mt: 1 }}
							/>
						)}
					/>

					<Controller
						control={control}
						name={`training.${index}.link`}
						rules={{
							required:
								fields[index].type === StepTypes.Training.TrainingEnum.Online
									? 'Please enter a link'
									: undefined,
							validate: (value) => {
								if (!value) return
								try {
									new URL(value)
									return true
								} catch (error) {
									return 'Invalid URL'
								}
							},
						}}
						render={({ field: { value, onChange }, fieldState: { error } }) => (
							<TextField
								error={!!error}
								label="Link to resource"
								value={value}
								fullWidth
								onChange={(event) => onChange(event.target.value)}
								helperText={
									error?.message ??
									'If this is a Loom or Youtube link, Cango will embed your video into the platform.'
								}
							/>
						)}
					/>
					<Box display="flex" justifyContent="flex-end">
						<Button
							variant="outlined"
							size="small"
							startIcon={<TrashIcon stroke={colors.error.main} width={12} />}
							color="error"
							onClick={() => remove(index)}
						>
							Delete
						</Button>
					</Box>
				</Box>
			))}
			{canEditChain && (
				<Box>
					<Button
						variant="outlined"
						size="small"
						onClick={() => append({ type: StepTypes.Training.TrainingEnum.Online, link: '' })}
					>
						Add training
					</Button>
				</Box>
			)}
		</Box>
	)
}

export const TrainingFields = React.memo(UndecoratedTrainingFields)
