import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'

import { ChevronDown } from 'src/assets/icons'
import { ROUTES, RouteId } from 'src/constants/routes'
import { selectors as persistedConfigSelectors } from 'src/store/modules/persisted-config'
import { Box } from 'src/components'

import { MenuItem } from '../components/menu-item'
import { ProjectsMenuItem } from '../components/projects-menu-item'

type MainNavProps = {
	projectsPopover: HTMLButtonElement | null
	onProjectsClick: (event: React.MouseEvent<HTMLButtonElement>) => void
	onProjectsClose: () => void
	onRouteClick: (routeId: RouteId) => void
}

export const MainNav: ComponentType<MainNavProps> = ({
	onProjectsClick,
	onProjectsClose,
	projectsPopover,
	onRouteClick,
}) => {
	const recentlyViewedProjects = useSelector(persistedConfigSelectors.getRecentlyViewedProjects)

	// const routes = useMemo(
	// 	() =>
	// 		ROUTES.filter((route) => {
	// 			if (!userPermissions) return false
	// 			if ([RouteId.MyTasks, RouteId.Help, RouteId.Project].includes(route.id)) {
	// 				return true
	// 			}
	// 			return userPermissions.routes.includes(route.id)
	// 		}),
	// 	[userPermissions],
	// )
	const routes = ROUTES

	const routesInAppBar = routes.filter(({ id }) => {
		const routesNotToDisplay = [
			RouteId.Blueprint,
			RouteId.Project,
			RouteId.Users,
			RouteId.Roles,
			RouteId.Tables,
			RouteId.Inbox,
			RouteId.Contacts,
			RouteId.Templates,
			RouteId.Permissions,
		]

		routesNotToDisplay.push(RouteId.Blueprint)
		routesNotToDisplay.push(RouteId.Project)

		return !routesNotToDisplay.includes(id)
	})

	const handleMenuItemClick = (event: React.MouseEvent<HTMLButtonElement>, routeId: RouteId) => {
		if (routeId === RouteId.Projects && !!recentlyViewedProjects.length) {
			onProjectsClick(event)
			return
		}
		onRouteClick(routeId)
	}

	return (
		<Box flexGrow={1} display="flex">
			{routesInAppBar.map((route) => {
				const RouteIcon = route.icon
				const routeUrl = `/${route.id}`

				return (
					<Box key={route.id}>
						<MenuItem
							endIcon={
								route.id === RouteId.Projects && !!recentlyViewedProjects.length ? (
									<ChevronDown fill="#fff" />
								) : undefined
							}
							startIcon={RouteIcon ? <RouteIcon fill="#fff" width={18} /> : undefined}
							onMenuItemClick={handleMenuItemClick}
							routeId={route.id}
							routeUrl={routeUrl}
							routeName={route.label}
						/>
						{route.id === RouteId.Projects && (
							<ProjectsMenuItem
								routeId={route.id}
								projectsPopover={projectsPopover}
								onProjectsClick={onProjectsClick}
								onProjectsClose={onProjectsClose}
								onRouteClick={onRouteClick}
							/>
						)}
					</Box>
				)
			})}
		</Box>
	)
}
