import { TemplatesSdk } from '@cango-app/sdk/api'
import { TemplateTypes } from '@cango-app/sdk/types'
import { AxiosError } from 'axios'
import {
	ComponentType,
	createContext,
	PropsWithChildren,
	useCallback,
	useEffect,
	useState,
} from 'react'
import { useSelector } from 'react-redux'

import { showSnackbar } from 'src/helpers/snackbarManager'
import { selectors as authSelectors } from 'src/store/modules/auth'

export type TemplatesProviderChildProps = {
	templates: TemplateTypes.Template[]
	isLoadingTemplates: boolean
	selectedTemplate: TemplateTypes.Template | undefined
	setSelectedTemplate: React.Dispatch<React.SetStateAction<TemplateTypes.Template | undefined>>
	onCreateTemplate: (name: string) => Promise<{ isSuccess: boolean }>
	onDeleteTemplate: (templateId: string) => Promise<void>
	onUpdateTemplate: (data: Partial<TemplateTypes.Template>) => Promise<{ isSuccess: boolean }>
}

export const TemplatesContext = createContext<TemplatesProviderChildProps>({
	templates: [],
	selectedTemplate: undefined,
	setSelectedTemplate: () => undefined,
	isLoadingTemplates: false,
	onCreateTemplate: async () => {
		return {
			isSuccess: false,
		}
	},
	onDeleteTemplate: async () => undefined,
	onUpdateTemplate: async () => {
		return {
			isSuccess: false,
		}
	},
})

export const TemplatesProvider: ComponentType<
	PropsWithChildren & {
		organisationId?: string
	}
> = (props) => {
	const [templates, setTemplates] = useState<TemplateTypes.Template[]>([])
	const [selectedTemplate, setSelectedTemplate] = useState<TemplateTypes.Template | undefined>(
		undefined,
	)
	const [isLoadingTemplates, setIsLoadingTemplates] = useState(false)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)

	const getTemplates = useCallback(async () => {
		setIsLoadingTemplates(true)
		try {
			const response = await TemplatesSdk.getTemplates(
				import.meta.env.VITE_API as string,
				authHeaders,
				props?.organisationId,
			)
			setTemplates(response)
		} catch (err) {
		} finally {
			setIsLoadingTemplates(false)
		}
	}, [])

	const onCreateTemplate = useCallback(async (name: string) => {
		let isSuccess = false
		if (!name.length) {
			showSnackbar("Template's name cannot be empty", { variant: 'error' })
			return {
				isSuccess,
			}
		}
		setIsLoadingTemplates(true)
		try {
			const createdTemplate = await TemplatesSdk.createTemplate(
				import.meta.env.VITE_API as string,
				authHeaders,
				{
					name,
					content: '',
					variables: [],
				},
			)
			setTemplates((currentTemplates) => [...currentTemplates, createdTemplate])
			setSelectedTemplate(createdTemplate)
			isSuccess = true
		} catch (err) {
			showSnackbar('Error saving template', { variant: 'error' })
		} finally {
			setIsLoadingTemplates(false)
		}
		return {
			isSuccess,
		}
	}, [])

	const onUpdateTemplate = useCallback(async (data: Partial<TemplateTypes.Template>) => {
		let isSuccess = false
		if (!data.name?.length) {
			showSnackbar("Template's name cannot be empty", { variant: 'error' })
			return {
				isSuccess,
			}
		}
		setIsLoadingTemplates(true)
		try {
			const updatedTemplate = await TemplatesSdk.updateTemplate(
				import.meta.env.VITE_API as string,
				authHeaders,
				data,
			)
			setTemplates((currentTemplates) =>
				currentTemplates.map((currentTemplate) => {
					if (currentTemplate._id === updatedTemplate._id) {
						return updatedTemplate
					}
					return currentTemplate
				}),
			)
			isSuccess = true
			showSnackbar('Template updated successfully!', { variant: 'success' })
		} catch (err) {
			showSnackbar('Error updating template', { variant: 'error' })
		} finally {
			setIsLoadingTemplates(false)
		}
		return {
			isSuccess,
		}
	}, [])

	const onDeleteTemplate = useCallback(async (templateId: string) => {
		setIsLoadingTemplates(true)
		try {
			await TemplatesSdk.deleteTemplate(import.meta.env.VITE_API as string, authHeaders, templateId)
			setTemplates((currentTemplates) =>
				currentTemplates.filter((currentTemplate) => currentTemplate._id !== templateId),
			)
			setSelectedTemplate((currentSelectedTemplate) => {
				return currentSelectedTemplate?._id !== templateId ? currentSelectedTemplate : undefined
			})
			showSnackbar('Template deleted', { variant: 'success' })
		} catch (error) {
			const axiosMessage =
				(error as AxiosError<{ message: string }>).response?.data?.message ??
				'Cannot delete Template'
			showSnackbar(axiosMessage, { variant: 'error' })
		} finally {
			setIsLoadingTemplates(false)
		}
	}, [])

	useEffect(() => {
		getTemplates()
	}, [])
	return (
		<TemplatesContext.Provider
			value={{
				templates,
				isLoadingTemplates,
				selectedTemplate,
				setSelectedTemplate,
				onCreateTemplate,
				onDeleteTemplate,
				onUpdateTemplate,
			}}
		>
			{props.children}
		</TemplatesContext.Provider>
	)
}
