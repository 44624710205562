import { ComponentType, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
	ReactFlow as ReactFlowLibrary,
	Background,
	ReactFlowProvider,
	Controls,
	MiniMap,
	useNodesState,
	useEdgesState,
	Edge,
	Connection,
	useReactFlow,
	getOutgoers,
	Node,
} from '@xyflow/react'
import { TableTypes } from '@cango-app/sdk/types'
import { useSelector } from 'react-redux'
import { v4 } from 'uuid'

import { Box } from 'src/components'
import { TableContext } from 'src/providers'
import { colors } from 'src/theme/colors'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'

import { CangoQuestionaireNode } from './types'
import { getLayout } from './get-layout'
import { checkLogicForCycles, NodeModal } from './node-modal'
import { QuestionNode } from './question-node'
import '@xyflow/react/dist/style.css'

const nodeTypes = { question: QuestionNode }

const LayoutFlow: ComponentType = () => {
	const { updateRecords, onDeleteRecords, tableConfig, mappedRecords, columns, resolvedRows } =
		useContext(TableContext)
	const selectedProjectId = useSelector(projectSelectors.getSelectedProjectId)
	const [nodes, setNodes, onNodesChange] = useNodesState<CangoQuestionaireNode>([])
	const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([])
	const { getNodes, getEdges } = useReactFlow()
	const [selectedNode, setSelectedNode] = useState<TableTypes.TableRow | undefined>(undefined)

	const questionColumnId = tableConfig?.principal_field ?? ''
	const optionsColumnId = useMemo(() => {
		return columns.find((_field) => _field.type === TableTypes.FieldType.OPTIONS)?._id ?? ''
	}, [columns])

	const onLayout = () => {
		const layout = getLayout({
			rows: [...mappedRecords.values()],
			questionColumnId,
			optionsColumnId,
		})

		setNodes([...layout.nodes])
		setEdges([...layout.edges])
	}

	const handleDeleteConnection = useCallback(
		async (edges: Edge[]) => {
			const edge = edges[0]
			const originalNode = mappedRecords.get(edge.source)
			if (!originalNode) {
				return
			}
			const newDescendants =
				originalNode.descendants?.filter((_desc) => _desc.row !== edge.target) ?? []

			const hasCycles = checkLogicForCycles(newDescendants, mappedRecords, originalNode._id)

			if (hasCycles) {
				showSnackbar('Cycles detected, please fix them before saving', { variant: 'error' })
				return
			}

			await updateRecords({
				rows: [
					{
						oldRow: originalNode,
						newRow: {
							...originalNode,
							descendants: newDescendants,
						},
					},
				],
				save: true,
				projectId: selectedProjectId,
			})
		},
		[mappedRecords],
	)

	const handleDeletenode = useCallback(async (nodes: Node[]) => {
		await onDeleteRecords(new Map(nodes.map((node) => [node.id, {}])))
	}, [])

	const handleAddConnection = useCallback(
		async (connection: Connection) => {
			if (!connection.source) {
				return
			}
			const originalNode = mappedRecords.get(connection.source)
			if (!originalNode || !connection.target) {
				return
			}
			const newDecendants = [
				...(originalNode.descendants ?? []),
				{ row: connection.target, _id: v4() },
			]
			await updateRecords({
				rows: [
					{
						oldRow: originalNode,
						newRow: {
							...originalNode,
							descendants: newDecendants,
						},
					},
				],
				save: true,
				projectId: selectedProjectId,
			})
		},
		[mappedRecords],
	)

	const isValidConnection = useCallback(
		(connection: Connection | Edge) => {
			// we are using getNodes and getEdges helpers here
			// to make sure we create isValidConnection function only once
			const nodes = getNodes()
			const edges = getEdges()
			const target = nodes.find((node) => node.id === connection.target)
			if (!target) return false
			const hasCycle = (node: Node, visited = new Set()) => {
				if (visited.has(node.id)) return false

				visited.add(node.id)

				for (const outgoer of getOutgoers(node, nodes, edges)) {
					if (outgoer.id === connection.source) return true
					if (hasCycle(outgoer, visited)) return true
				}
			}

			if (target.id === connection.source) return false
			return !hasCycle(target)
		},
		[getNodes, getEdges],
	)

	useEffect(() => {
		onLayout()
	}, [resolvedRows])

	return (
		<>
			{selectedNode && (
				<NodeModal
					node={selectedNode}
					questionColumnId={questionColumnId}
					optionsColumnId={optionsColumnId}
					onClose={() => setSelectedNode(undefined)}
					allNodes={nodes}
				/>
			)}
			<ReactFlowLibrary
				nodes={nodes}
				edges={edges}
				onEdgesDelete={handleDeleteConnection}
				onNodesDelete={handleDeletenode}
				isValidConnection={isValidConnection}
				onConnect={handleAddConnection}
				onNodesChange={onNodesChange}
				onEdgesChange={onEdgesChange}
				nodeTypes={nodeTypes}
				nodesDraggable={false}
				onNodeClick={(event, _node) => {
					const node = mappedRecords.get(_node.id)
					setSelectedNode(node)
				}}
			>
				<Background />
				<Controls />
				<MiniMap pannable />
			</ReactFlowLibrary>
		</>
	)
}

const QuestionaireContainer: ComponentType = () => {
	return (
		<ReactFlowProvider>
			<Box flex={1} border="3px solid" borderRadius="8px" borderColor={colors.neutral['40']}>
				<LayoutFlow />
			</Box>
		</ReactFlowProvider>
	)
}

export default QuestionaireContainer
