import React, { ComponentType, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Action, ThunkDispatch } from '@reduxjs/toolkit'
import { ChainsSdk } from '@cango-app/sdk/api'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { Skeleton } from '@mui/material'

import noisyGrid from 'src/assets/images/textures/noisy-grid.png'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { selectors as userSelectors } from 'src/store/modules/user'
import type { RootState } from 'src/store/types'
import { Box, Button, Chip, Text } from 'src/components'
import { RouteId } from 'src/constants/routes'
import { errorHandler } from 'src/helpers/api'
import { colors } from 'src/theme/colors'

export const BlueprintMenu: ComponentType = () => {
	const dispatch = useDispatch<ThunkDispatch<RootState, void, Action>>()
	const navigate = useNavigate()
	const [isLoading, setIsLoading] = useState(false)
	const childrenOrganisations = useSelector(userSelectors.getChildrenOrganisations)
	const parentOrganisations = useSelector(userSelectors.getParentOrganisations)
	const [blueprintCards, setBlueprintCards] = useState<ChainsSdk.GetChainsResponse>([])
	const [isCreatingBlueprint, setIsCreatingBlueprint] = useState(false)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)

	const fetchAllBlueprints = async () => {
		setIsLoading(true)
		try {
			const response = await ChainsSdk.getAll(import.meta.env.VITE_API as string, authHeaders, {
				blueprints: 'true',
			})
			setBlueprintCards(response)
		} catch (error) {
			errorHandler({ error, dispatch })
		}
		setIsLoading(false)
	}

	const handleCreateFromScratch = async () => {
		const date = dayjs().format('MM/DD/YYYY')
		const blueprintName = `New Blueprint - ${date}`
		setIsCreatingBlueprint(true)
		try {
			const response = await ChainsSdk.create(import.meta.env.VITE_API as string, authHeaders, {
				isBlueprint: true,
				name: blueprintName,
			})
			navigate(`/${RouteId.Blueprint}/${response._id}`)
		} catch (error) {
			errorHandler({ error, dispatch })
		}
	}

	useEffect(() => {
		fetchAllBlueprints()
	}, [])

	return (
		<Box px={3}>
			<Box py={4} px={2} display="flex" justifyContent="flex-end">
				<Button onClick={() => handleCreateFromScratch()} isLoading={isCreatingBlueprint}>
					New blueprint
				</Button>
			</Box>
			{isLoading && (
				<Box display="flex" flexWrap="wrap">
					<Skeleton animation="wave" height={150} width={300} sx={{ mr: 3, mb: 3 }} />
					<Skeleton animation="wave" height={150} width={300} sx={{ mr: 3, mb: 3 }} />
					<Skeleton animation="wave" height={150} width={300} sx={{ mr: 3, mb: 3 }} />
					<Skeleton animation="wave" height={150} width={300} sx={{ mr: 3, mb: 3 }} />
					<Skeleton animation="wave" height={150} width={300} sx={{ mr: 3, mb: 3 }} />
				</Box>
			)}
			{!isLoading && (
				<Box display="flex" flexWrap="wrap">
					{blueprintCards.map((_card) => {
						const foundChildOrganisation = childrenOrganisations?.find(
							({ _id }) => _card.organisationId === _id,
						)
						const foundParentOrganisation = parentOrganisations?.find(
							({ _id }) => _card.organisationId === _id,
						)
						return (
							<Box
								key={_card._id}
								mb={3}
								mr={3}
								px={2}
								py={4}
								onClick={() => navigate(`/${RouteId.Blueprint}/${_card._id}`)}
								sx={{
									cursor: 'pointer',
									backgroundColor: '#42b1ed',
									':hover': {
										backgroundColor: '#8dd0f4',
									},
									borderRadius: 2,
									width: 300,
									backgroundImage: `url(${noisyGrid})`,
								}}
								borderBottom="1px solid"
								borderColor={colors.neutral['40']}
							>
								<Box>
									<Text fontWeight="bold" fontSize={16} mb={1} color="white">
										{_card.name}
									</Text>
									<Text fontSize={12}>
										Created: <b>{dayjs.unix(_card.created_at).format('MMM DD, YYYY ')}</b>
									</Text>
									{_card.isPublic && (
										<Chip
											label={
												foundParentOrganisation
													? `Shared by ${foundParentOrganisation.name}`
													: 'Public'
											}
											color={foundParentOrganisation ? 'sunglow' : 'primary'}
											sx={{
												marginRight: 1,
											}}
										/>
									)}
									{foundChildOrganisation && (
										<Chip label={`From: ${foundChildOrganisation.name}`} color="info" />
									)}
								</Box>
							</Box>
						)
					})}
				</Box>
			)}
		</Box>
	)
}
