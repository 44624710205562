import { Stack } from '@mui/material'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import { useContext } from 'react'
import { ChainTypes } from '@cango-app/sdk/types'

import { Box, Text, Button, TextField, Select, IconButton, Modal } from 'src/components'

import { ChainContext } from '../../chain-provider'
import { useChainPermissions } from '../../useChainPermissions'

type Form = {
	actions: Pick<ChainTypes.DatabaseCTAsChains, 'chain' | 'label'>[]
}

type Chain = {
	_id: string
	label: string
}

type Props = {
	chainList: Chain[]
	isOpen: boolean
	onClose: () => void
}

export const BluepritnCtaDatabaseModal = ({ chainList, isOpen, onClose }: Props) => {
	const { updateChain, chain } = useContext(ChainContext)
	const { canEditChain } = useChainPermissions()
	const { control, handleSubmit } = useForm<Form>({
		defaultValues: {
			actions: chain?.database_chain_cta ?? [],
		},
	})

	const { fields, append, remove } = useFieldArray({ control, name: 'actions' })

	const onSave = ({ actions }: Form) => {
		updateChain({
			database_chain_cta: actions,
		})
		onClose()
	}

	return (
		<Modal open={isOpen} onClose={onClose}>
			<Stack direction="column" spacing={2} minHeight="50vh" paddingX={1}>
				<Box>
					<Text>Database CTAs</Text>
					<Text variant="caption">
						CTAs will call chains with the filtered information from your task
					</Text>
				</Box>

				<Stack flex={1} justifyContent="flex-start" direction="column" spacing={1}>
					{fields.map((action, index) => {
						return (
							<>
								<Stack key={action.id} direction="row" spacing={0.5}>
									<Box flex={1}>
										<Controller
											control={control}
											name={`actions.${index}.label`}
											render={({ field: { value, onChange } }) => (
												<TextField
													fullWidth
													label="Name"
													disabled={!canEditChain}
													value={value}
													onChange={onChange}
												/>
											)}
										/>
									</Box>
									{canEditChain && (
										<Box display="flex" alignItems="flex-end">
											<IconButton size="small" onClick={() => remove(index)}>
												<DeleteIcon />
											</IconButton>
										</Box>
									)}
								</Stack>
								<Controller
									control={control}
									rules={{
										required: 'Chain cannot be empty',
									}}
									name={`actions.${index}.chain`}
									render={({ field: { value, onChange }, formState: { errors } }) => (
										<Select
											options={chainList ?? []}
											label="Chain"
											value={value}
											disabled={!canEditChain}
											onChange={onChange}
											helperText={errors && errors?.actions?.[index]?.chain?.message}
											helperError={true}
										/>
									)}
								/>
							</>
						)
					})}
				</Stack>
				{canEditChain && (
					<>
						<Button
							variant="outlined"
							onClick={() => {
								append({
									label: 'New RFQ',
									chain: '',
								})
							}}
						>
							Add new
						</Button>
						<Button onClick={handleSubmit(onSave)}>Save changes</Button>
					</>
				)}
			</Stack>
		</Modal>
	)
}
