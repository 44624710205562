import { StepTypes } from '@cango-app/sdk/types'
import capitalize from 'lodash/capitalize'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)
dayjs.extend(duration)

export const readableList = (arr: string[] | string, operator = '&'): string => {
	if (!arr.length) return ''
	if (typeof arr === 'string') return arr
	return arr.length > 1
		? arr.slice(0, -1).join(', ') + ' ' + operator + ' ' + arr.slice(-1)
		: arr[0]
}

export const relativeTimeLabel = (timestamp: number): string => {
	return timestamp ? String(dayjs.unix(timestamp).fromNow()) : 'Just now'
}

export const getActionLabel = (action: StepTypes.Action.ActionEnum | string): string => {
	switch (action) {
		case StepTypes.Action.ActionEnum.FileTemplate: {
			return 'Templated file'
		}
		case StepTypes.Action.ActionEnum.FileUpload: {
			return 'Upload file'
		}
		case StepTypes.Action.ActionEnum.Contact: {
			return 'Add a contact'
		}
		case StepTypes.Action.ActionEnum.SetResources: {
			return 'Set resources'
		}
		case StepTypes.Action.ActionEnum.TaskReference: {
			return 'Task reference(s)'
		}
		default: {
			return capitalize(action)
		}
	}
}

export const getTrainingTypeLabel = (trainingType: StepTypes.Training.TrainingEnum): string => {
	switch (trainingType) {
		case StepTypes.Training.TrainingEnum.InPerson:
			return 'In person training'
		case StepTypes.Training.TrainingEnum.Online:
			return 'Online training'
		default:
			return 'No training required'
	}
}
