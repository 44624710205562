import { ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Popover from '@mui/material/Popover'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'

import { selectors as persistedConfigSelectors } from 'src/store/modules/persisted-config'
import { RouteId } from 'src/constants/routes'
import { ProjectsIcon } from 'src/assets/icons'
import { colors } from 'src/theme/colors'
import { AnalyticsEvent, analytics } from 'src/biz'
import { Box, Text } from 'src/components'

type ProjectsMenuItemProps = {
	routeId: RouteId
	projectsPopover: HTMLButtonElement | null
	onProjectsClick: (event: React.MouseEvent<HTMLButtonElement>) => void
	onProjectsClose: () => void
	onRouteClick: (routeId: RouteId) => void
}

export const ProjectsMenuItem: ComponentType<ProjectsMenuItemProps> = ({
	routeId,
	projectsPopover,
	onProjectsClose,
	onRouteClick,
}) => {
	const navigate = useNavigate()
	const recentlyViewedProjects = useSelector(persistedConfigSelectors.getRecentlyViewedProjects)

	const handleMenuItemClick = (projectId: string) => {
		analytics.track({ eventName: AnalyticsEvent.navProjectClicked })
		navigate(`/${RouteId.Project}/${projectId}`)
		onProjectsClose()
	}

	const handleViewAllProjects = () => {
		analytics.track({ eventName: AnalyticsEvent.navAllProjectsClicked })
		onRouteClick(routeId)
	}

	return (
		<Popover
			open={!!projectsPopover}
			anchorEl={projectsPopover}
			onClose={onProjectsClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
		>
			<Box width={300} pt={2}>
				<Box>
					<Text variant="overline" fontWeight="bold" sx={{ px: 2 }}>
						Recently opened
					</Text>
					<List>
						{recentlyViewedProjects.length ? (
							recentlyViewedProjects.map((project) => {
								return (
									<ListItemButton
										sx={{ px: 2 }}
										key={project._id}
										onClick={() => handleMenuItemClick(project._id)}
									>
										<ListItemIcon sx={{ minWidth: 40 }}>
											<Avatar
												variant="rounded"
												sx={{
													width: 24,
													height: 24,
													bgcolor: colors.sunglow['60'],
												}}
											>
												<ProjectsIcon width={16} fill={colors.feldgrau['80']} />
											</Avatar>
										</ListItemIcon>
										<ListItemText
											primaryTypographyProps={{ fontSize: 14, fontWeight: 'bold' }}
											secondaryTypographyProps={{ fontSize: 12 }}
											primary={project.name}
											secondary={project.blueprintName}
										/>
									</ListItemButton>
								)
							})
						) : (
							<ListItem>
								<Text fontSize={14} color={colors.neutral['60']}>
									Nothing recently viewed
								</Text>
							</ListItem>
						)}
					</List>
					<Divider sx={{ borderColor: colors.neutral['40'], borderWidth: 1 }} />
					<ListItemButton onClick={handleViewAllProjects} sx={{ cursor: 'pointer', py: 2 }}>
						<Text fontSize={14}>View all projects</Text>
					</ListItemButton>
				</Box>
			</Box>
		</Popover>
	)
}
