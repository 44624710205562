import { ComponentType } from 'react'
import { TaskTypes } from '@cango-app/sdk/types'

import { ChevronRight } from 'src/assets/icons'

import { MultiUseCta } from './multi-use-cta'

type TaskListItemCtaProps = {
	task: TaskTypes.PopulatedTask
}

export const TaskListItemCta: ComponentType<TaskListItemCtaProps> = ({ task }) => {
	if (task.isMultiUse) {
		return <MultiUseCta task={task} />
	}

	return <ChevronRight style={{ marginRight: 8 }} />
}
