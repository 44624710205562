import { UserTypes } from '@cango-app/sdk/types'
import { Stack } from '@mui/material'
import { ComponentType, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Button, Select, Text } from 'src/components'
import { actions as userActions, selectors as userSelectors } from 'src/store/modules/user'
import { AsyncDispatchType } from 'src/store/types'

type DeleteUserProps = {
	user: UserTypes.PopulatedUser
	closeDrawer: () => void
}

export const DeleteUser: ComponentType<DeleteUserProps> = ({ user, closeDrawer }) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const users = useSelector(userSelectors.getAllUsersForSelect)
	const [substituteUserId, setSubstituteUserId] = useState('')
	const [isDeleting, setIsDeleting] = useState(false)

	const handleDeleteUser = async () => {
		setIsDeleting(true)
		const response = await dispatch(
			userActions.deleteUser({
				substituteUserId: substituteUserId,
				userId: user._id,
			}),
		)
		setIsDeleting(false)
		if (response.meta.requestStatus === 'fulfilled') {
			closeDrawer()
		}
	}

	const filteredUsers = useMemo(() => users.filter(({ _id }) => user._id !== _id), [user, users])

	return (
		<Box width={400} p={3} role="presentation">
			<Text sx={{ mb: 3 }} variant="h4">
				{user.name} {user.surname}
			</Text>
			<Stack direction="column" spacing={2} my={2}>
				<Text>Tasks and roles assigments will be changed to the selected user if needed</Text>
				<Select
					label="Substitute user:"
					options={filteredUsers}
					value={substituteUserId}
					onChange={(e) => setSubstituteUserId(e.target.value as string)}
				/>
			</Stack>
			<Button
				onClick={handleDeleteUser}
				disabled={!substituteUserId}
				variant="contained"
				color="error"
				isLoading={isDeleting}
			>
				Delete User
			</Button>
		</Box>
	)
}
