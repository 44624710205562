import { StepTypes, TaskTypes } from '@cango-app/sdk/types'
import { v4 } from 'uuid'

import { SelectedOptions } from 'src/providers/task-provider/types'

import { buildFilterModel, buildParentChains } from '../utils'
import { CompletingTask } from '../types'

type StepWithOption = {
	stepId: string
	option: TaskTypes.CompletedOption
}

const getStepsWithOptions = ({
	descendant,
	selectedOptions,
}: {
	descendant: TaskTypes.PopulatedStep.ProjectDescendant
	selectedOptions: TaskTypes.CompletedOption[]
}): StepWithOption[] => {
	const stepId = descendant.step?._id
	if (!stepId) {
		return []
	}
	const stepsWithOptions: StepWithOption[] = []

	const optionCondition = descendant.option_condition

	const isAnyCondition =
		!optionCondition || optionCondition?.operator === StepTypes.ChildConditionOperator.Any

	selectedOptions.forEach((_option) => {
		const isMatchingCondition =
			optionCondition?.operator === StepTypes.ChildConditionOperator.Is &&
			optionCondition.values.some((_val) => _val === _option._id)
		const isNotMatchingCondition =
			optionCondition?.operator === StepTypes.ChildConditionOperator.IsNot &&
			descendant.option_condition?.values.every((_val) => _val !== _option._id)

		if (isAnyCondition || isMatchingCondition || isNotMatchingCondition) {
			stepsWithOptions.push({
				stepId: stepId,
				option: _option,
			})
			return
		}
	})

	return stepsWithOptions
}

const createChain = ({
	completingTask,
	descendant,
	stepWithOption,
	mods,
}: {
	completingTask: CompletingTask
	descendant: TaskTypes.PopulatedStep.ProjectDescendant
	stepWithOption: StepWithOption
	mods: {
		_id: string
		mod: string
	}[]
}): TaskTypes.Chain.ProjectChain => {
	const parentChains = completingTask?.chain?.parent_chains ?? []

	const newLabel: TaskTypes.Chain.ProjectChain['label'] = {
		prefix: descendant.thread?.prefix,
		selected_option: stepWithOption.option.label,
		columnType: stepWithOption.option.column?.type,
		color: descendant.thread?.color,
		table: descendant.thread?.table,
	}

	const newOptions = {
		columnId: stepWithOption.option.column?._id,
		labels: [stepWithOption.option.label],
	}

	const modOnSelection = descendant.database_changes?.find(
		(_change) =>
			_change.operator === 'custom_input' && _change.field === stepWithOption.option.column?._id,
	)
	const mod = mods.find((_mod) => modOnSelection && String(_mod._id) === String(modOnSelection.id))

	if (
		mod &&
		descendant.createForEveryOption &&
		modOnSelection &&
		descendant.duplication?.activated &&
		(!descendant.duplication?.selected_options?.length ||
			descendant.duplication?.selected_options.some(
				(_option) =>
					_option._id === stepWithOption.option._id ||
					_option.label === stepWithOption.option.label,
			))
	) {
		newLabel.selected_option = mod.mod
		newOptions.labels = [mod.mod]
	}

	return {
		...(descendant.thread ?? {}),
		_id: v4(),
		parent_chains: buildParentChains({ parentChains, parentTask: completingTask, descendant }),
		option_id: stepWithOption.option._id,
		label: newLabel,
		database_chain_logic: {
			filters: buildFilterModel({
				descendant,
				options: newOptions,
			}),
		},
		original_descendant_id: descendant._id,
	}
}

const createUnlinkedTasksWithChain = ({
	stepsWithOptions,
	descendant,
	completingTask,
	mods,
}: {
	stepsWithOptions: StepWithOption[]
	descendant: TaskTypes.PopulatedStep.ProjectDescendant
	completingTask: CompletingTask
	mods: {
		_id: string
		mod: string
	}[]
}): { tasks: TaskTypes.Task[]; chains: TaskTypes.Chain.ProjectChain[] } => {
	return stepsWithOptions.reduce(
		(
			_acc: {
				tasks: TaskTypes.Task[]
				chains: TaskTypes.Chain.ProjectChain[]
			},
			_stepWithOption,
		) => {
			const blueprintStep = descendant.step
			if (!blueprintStep) {
				return _acc
			}
			const chain = createChain({
				completingTask,
				descendant,
				stepWithOption: _stepWithOption,
				mods,
			})
			_acc.chains.push(chain)
			_acc.tasks.push({
				...blueprintStep,
				_id: v4(),
				chain,
				step: _stepWithOption.stepId,
				project_id: completingTask.project_id,
				notes: [],
				annotations: [],
				parent: completingTask._id,
				assignees: [],
				lifecycle: {
					complete: false,
					completed_meta: [],
					completed_options: [],
				},
				unblock_task: undefined,
				section: completingTask.section,
				iteration: 1,
				actions: [],
				attachments: [],
			})

			return _acc
		},
		{
			tasks: [],
			chains: [],
		},
	)
}

export const createTasksFromMultiSelect = ({
	descendant,
	selectedOptions,
	completingTask,
	mods,
}: {
	descendant: TaskTypes.PopulatedStep.ProjectDescendant
	selectedOptions: SelectedOptions
	completingTask: CompletingTask
	mods: {
		_id: string
		mod: string
	}[]
}): { tasks: TaskTypes.Task[]; chains: TaskTypes.Chain.ProjectChain[] } => {
	const stepsWithOptions = getStepsWithOptions({ selectedOptions, descendant })
	return createUnlinkedTasksWithChain({
		stepsWithOptions,
		descendant,
		completingTask,
		mods,
	})
}
