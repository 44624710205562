import { ComponentType, useContext, useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form'
import { TableTypes } from '@cango-app/sdk/types'
import { useSelector } from 'react-redux'
import { v4 } from 'uuid'
import { Collapse, Stack } from '@mui/material'
import { TransitionGroup } from 'react-transition-group'
import _isString from 'lodash/isString'
import CheckIcon from '@mui/icons-material/Check'
import { Edge } from '@xyflow/react'

import { TableContext } from 'src/providers'
import {
	Box,
	Button,
	Divider,
	Grid,
	IconButton,
	Modal,
	Select,
	Text,
	TextField,
} from 'src/components'
import { colors } from 'src/theme/colors'
import { EditPenIcon, PlusIcon, TrashIcon } from 'src/assets/icons'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { detectCycles } from 'src/modules/chains/get-element-layout'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'

import { BasicQuestionaireData, CangoQuestionaireNode } from './types'
import { ListLetter, numberToLetter } from './list-letter'

type NodeModalProps = {
	allNodes: CangoQuestionaireNode[]
	node: TableTypes.TableRow
	questionColumnId: string
	optionsColumnId: string
	onClose: () => void
}

type DescendantConditionForm = {
	descendants: TableTypes.Descendant[]
}

export const checkLogicForCycles = (
	descendants: TableTypes.Descendant[],
	mappedRecords: Map<string, TableTypes.TableRow>,
	rowId: string,
) => {
	const updatedRecords: TableTypes.TableRow[] = [...mappedRecords.values()].map((_record) => {
		if (_record._id !== rowId) {
			return _record
		}
		return {
			..._record,
			descendants: descendants,
		}
	})

	const newEdges = updatedRecords.reduce((_acc: Edge[], _record) => {
		const recordDependants = _record.descendants ?? []
		const edges = recordDependants.reduce((_acc: Edge[], _child) => {
			if (
				_acc.find((_edge) => _edge.source === _record._id && _edge.target === _child.row) ||
				!_child.row
			) {
				return _acc
			}

			_acc.push({
				id: `e${_record._id}--${_child.row}`,
				source: _record._id,
				target: _child.row,
			})

			return _acc
		}, [])
		_acc.push(...edges)
		return _acc
	}, [])

	return !!detectCycles(newEdges).length
}

const OptionConditionValueInput: ComponentType<{
	fromQuestion: BasicQuestionaireData
	index: number
}> = ({ fromQuestion, index }) => {
	const { control } = useFormContext<DescendantConditionForm>()

	if (fromQuestion.answerConfig.options.length) {
		return (
			<Controller
				control={control}
				name={`descendants.${index}.option_condition.values`}
				render={({ field: { value, onChange } }) => (
					<Select
						options={fromQuestion.answerConfig.options.map((_option, index) => ({
							_id: _option._id,
							label: (
								<Box>
									<Stack direction="row" spacing={1} alignItems="center">
										<ListLetter alphabetIndex={index + 1} />
										<Text>{_option.label}</Text>
									</Stack>
								</Box>
							),
						}))}
						renderValue={(selected) => {
							if (_isString(selected)) {
								selected = [selected]
							}
							return (selected as string[])
								.map((_id) => {
									const listItemIndex = fromQuestion.answerConfig.options.findIndex(
										(_option) => _option._id === _id,
									)
									if (listItemIndex === -1) {
										return
									}
									const listItemLabel = fromQuestion.answerConfig.options[listItemIndex]?.label
									if (!listItemLabel) {
										return numberToLetter(listItemIndex + 1)
									}
									return listItemLabel
								})
								.filter(Boolean)
								.join(', ')
						}}
						value={_isString(value) ? [] : (value ?? [])}
						onChange={onChange}
						fullWidth
						multiple
						disableOrdering
					/>
				)}
			/>
		)
	}

	return (
		<Controller
			control={control}
			name={`descendants.${index}.option_condition.values.0`}
			render={({ field: { value, onChange } }) => (
				<TextField value={value} onChange={onChange} fullWidth />
			)}
		/>
	)
}

const ConditionField: ComponentType<{
	selectedNode: TableTypes.TableRow
	index: number
	allNodes: CangoQuestionaireNode[]
	onAddRule: () => void
	onRemove?: () => void
}> = ({ index, allNodes, onAddRule, onRemove, selectedNode }) => {
	const { control, watch, setValue } = useFormContext<DescendantConditionForm>()
	const allConditions = watch('descendants')
	const condition = watch(`descendants.${index}`)

	const label = useMemo(() => {
		if (condition.option_condition) {
			return 'then go to'
		}

		if (allConditions.some((_cond) => _cond.option_condition)) {
			return 'All other cases go to'
		}

		return 'Always go to'
	}, [allConditions, index])

	const fromRow = useMemo(() => {
		if (!condition.option_condition?.from) {
			return
		}

		return allNodes.find((_node) => _node.id === condition.option_condition?.from)?.data
	}, [condition.option_condition?.from])

	const handleFromChange = (currentFromId: string, fromId: string) => {
		if (currentFromId === fromId) {
			return
		}
		const fromNode = allNodes.find((_node) => _node.id === fromId)
		if (!fromNode) {
			return
		}
		const fromIsString = [TableTypes.AnswerType.ShortText, TableTypes.AnswerType.LongText].includes(
			fromNode.data?.answerConfig?.answerType,
		)
		const conditionValues: string[] = []
		if (!fromIsString) {
			conditionValues.push('')
		}
		setValue(`descendants.${index}.option_condition`, {
			from: fromId,
			operator: TableTypes.FilterOperator.is,
			values: conditionValues,
		})
	}

	const { nodeToList, nodeFromList } = useMemo(() => {
		const indexOfSelectedNode = allNodes.findIndex((_node) => _node.id === selectedNode._id)
		return allNodes.reduce(
			(
				_acc: {
					nodeToList: { _id: string; label: string }[]
					nodeFromList: { _id: string; label: string }[]
				},
				_node,
				nodeIndex,
			) => {
				if (nodeIndex <= indexOfSelectedNode) {
					_acc.nodeFromList.push({
						_id: _node.id,
						label: _node.data.label || 'Row with no name',
					})
				} else {
					_acc.nodeToList.push({
						_id: _node.id,
						label: _node.data.label || 'Row with no name',
					})
				}
				return _acc
			},
			{
				nodeToList: [],
				nodeFromList: [],
			},
		)
	}, [allNodes])

	return (
		<Box mb={1}>
			<Grid container py={2} px={2} bgcolor={colors.neutral['20']} borderRadius="8px">
				{!!condition.option_condition && (
					<>
						<Grid xs={3} display="flex" alignItems="center">
							If
						</Grid>
						<Grid xs={9}>
							<Stack>
								<Controller
									control={control}
									name={`descendants.${index}.option_condition.from`}
									render={({ field: { value } }) => (
										<Select
											options={nodeFromList}
											value={value}
											onChange={(event) =>
												handleFromChange(value ?? '', event.target.value as string)
											}
										/>
									)}
								/>
								<Controller
									control={control}
									name={`descendants.${index}.option_condition.operator`}
									render={({ field: { value, onChange } }) => (
										<Select
											options={Object.entries(TableTypes.FilterOperator).map(([_id, label]) => ({
												_id,
												label,
											}))}
											value={value}
											onChange={onChange}
											containerProps={{ width: 100 }}
										/>
									)}
								/>
								{!!fromRow && <OptionConditionValueInput fromQuestion={fromRow} index={index} />}
							</Stack>
						</Grid>
						<Grid xs={12} py={2}>
							<Divider variant="fullWidth" />
						</Grid>
					</>
				)}
				<Grid xs={3} display="flex" alignItems="center">
					{label}
				</Grid>
				<Grid xs={9} display="flex" alignItems="center">
					<Stack>
						<Controller
							control={control}
							name={`descendants.${index}.row`}
							render={({ field: { value, onChange } }) => (
								<Select
									options={nodeToList}
									value={value}
									onChange={onChange}
									fullWidth
									containerProps={{ width: 400 }}
									withNoneOption
								/>
							)}
						/>
					</Stack>
				</Grid>
				<Button
					variant="text"
					startIcon={
						onRemove ? <TrashIcon width={20} stroke={colors.error.main} /> : <PlusIcon width={20} />
					}
					sx={{ minWidth: 75 }}
					color={onRemove ? 'error' : 'primary'}
					onClick={onRemove ?? onAddRule}
				>
					{onRemove ? 'Remove' : 'Add'} rule
				</Button>
			</Grid>
		</Box>
	)
}

const NameInput: ComponentType<{ name?: string; onNameSave: (newName: string) => void }> = ({
	name: defaultName,
	onNameSave,
}) => {
	const [name, setName] = useState(defaultName ?? '')

	const handleNameSave = () => {
		if (!name) return
		onNameSave(name)
	}

	return (
		<Stack direction="row" mb={2} alignItems="center">
			<TextField
				label="Question name"
				value={name}
				onChange={(e) => setName(e.target.value as string)}
				fullWidth
				sx={{ flex: 1, width: 300 }}
			/>
			<Box sx={{ mt: 2, ml: 2 }}>
				<IconButton size="small" onClick={handleNameSave} disabled={!name}>
					<CheckIcon />
				</IconButton>
			</Box>
		</Stack>
	)
}

export const NodeModal: ComponentType<NodeModalProps> = ({
	node,
	questionColumnId,
	onClose,
	allNodes,
}) => {
	const { updateRecords, mappedRowData, mappedRecords } = useContext(TableContext)
	const selectedProjectId = useSelector(projectSelectors.getSelectedProjectId)
	const nodeName = (mappedRowData.get(node._id)?.[questionColumnId] as string | undefined) ?? ''
	const form = useForm<DescendantConditionForm>({
		defaultValues: {
			descendants: node.descendants ?? [],
		},
	})
	const { fields, insert, remove } = useFieldArray({ control: form.control, name: 'descendants' })
	const allDescendants = form.watch('descendants')
	const [isEditingName, setIsEditingName] = useState(!node.data[questionColumnId] || false)

	const handleAddDescendant = (index = 0, hasNoElseCase = false) => {
		if (allDescendants.length > 0 && !hasNoElseCase) {
			insert(index, {
				_id: v4(),
				row: '',
				option_condition: {
					from: node._id,
					operator: TableTypes.FilterOperator.is,
					values: [],
				},
			})
			return
		}
		insert(index, {
			_id: v4(),
			row: '',
		})
	}

	const handleNameSave = async (newName: string) => {
		setIsEditingName(false)
		const originalRecord = mappedRecords.get(node._id)
		if (!originalRecord) {
			return
		}
		const newRow = {
			...originalRecord,
			data: {
				...originalRecord.data,
				[questionColumnId]: newName,
			},
		}
		await updateRecords({
			rows: [
				{
					oldRow: originalRecord,
					newRow,
				},
			],
			save: true,
			projectId: selectedProjectId,
		})
	}

	const handleSave = (data: DescendantConditionForm) => {
		if (isEditingName && !nodeName) {
			showSnackbar('Missing question name', { variant: 'error' })
			return
		}

		if (isEditingName) {
			showSnackbar('Please save the question name first', { variant: 'warning' })
			return
		}

		const cleanedDescendants = data.descendants
			.map((_desc) => ({
				..._desc,
				option_condition: _desc.option_condition?.values.length
					? _desc.option_condition
					: undefined,
			}))
			.filter((_desc) => {
				return !(!_desc.row && !_desc.option_condition?.values.length)
			})

		const originalRecord = mappedRecords.get(node._id)
		if (!originalRecord) {
			return
		}

		const hasCycles = checkLogicForCycles(cleanedDescendants, mappedRecords, originalRecord._id)

		if (hasCycles) {
			showSnackbar('Cycles detected in the logic chain', { variant: 'error' })
			return
		}

		updateRecords({
			rows: [
				{
					oldRow: originalRecord,
					newRow: {
						...originalRecord,
						descendants: cleanedDescendants,
					},
				},
			],
			save: true,
			projectId: selectedProjectId,
		})

		onClose()
	}

	useEffect(() => {
		const doesNotHaveElseCase = !fields.some((_field) => !_field.option_condition)
		if (!fields.length || doesNotHaveElseCase) {
			handleAddDescendant(fields.length, doesNotHaveElseCase)
		}
	}, [fields])

	return (
		<FormProvider {...form}>
			<Modal open={true} onClose={onClose}>
				<Box width={600} height={600}>
					{isEditingName ? (
						<NameInput name={nodeName} onNameSave={handleNameSave} />
					) : (
						<Stack direction="row" mb={2}>
							<Text variant="h6">{nodeName}</Text>
							<IconButton size="small" onClick={() => setIsEditingName(true)}>
								<EditPenIcon stroke={colors.feldgrau['60']} />
							</IconButton>
						</Stack>
					)}
					<TransitionGroup>
						{fields.map((field, index) => (
							<Collapse key={field.id}>
								<ConditionField
									selectedNode={node}
									index={index}
									allNodes={allNodes}
									onRemove={index !== fields.length - 1 ? () => remove(index) : undefined}
									onAddRule={handleAddDescendant}
								/>
							</Collapse>
						))}
					</TransitionGroup>
					<Button onClick={form.handleSubmit(handleSave)}>Save</Button>
				</Box>
			</Modal>
		</FormProvider>
	)
}
