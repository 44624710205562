import { MyTasksV3Sdk } from '@cango-app/sdk/api'

import { How, TrainingPhase } from '../helpers/enumerations'

export const ACTIONS: { label: string; value: string }[] = [
	{ label: '—', value: '' },
	{ label: 'Call', value: How.Call },
	{ label: 'Email', value: How.Email },
	{ label: 'Invite', value: How.Invite },
	{ label: 'Meeting', value: How.Meeting },
	{ label: 'Template File', value: How.FileTemplate },
	{ label: 'Upload File(s)', value: How.FileUpload },
	{ label: 'Check File(s)', value: How.FileView },
	{ label: 'Update File(s)', value: How.FileEdit },
	{ label: 'Software', value: How.Software },
	{ label: 'Video', value: How.Video },
	{ label: 'Note', value: How.Note },
	// {label: "Cango Checklist", value: 'cform'},
]

export const TIMEFRAMES: { label: string; value: string }[] = [
	{ label: '—', value: '' },
	{ label: 'ASAP', value: 'asap' },
	{ label: 'Dependant on task', value: 'taskdependant' },
	{ label: 'Dependant on milestone', value: 'milestonedependant' },
	{ label: 'Date', value: 'date' },
	{ label: 'Recurring', value: 'recurring' },
]

export const WEEKDAYS: { label: string; value: string }[] = [
	{ value: '1', label: 'Monday' },
	{ value: '2', label: 'Tuesday' },
	{ value: '3', label: 'Wednesday' },
	{ value: '4', label: 'Thursday' },
	{ value: '5', label: 'Friday' },
	{ value: '6', label: 'Saturday' },
	{ value: '7', label: 'Sunday' },
]

export const INTERVALS: { label: string; value: string }[] = [
	{ value: 'day', label: 'Day' },
	{ value: 'week', label: 'Week' },
	{ value: 'month', label: 'Month' },
	{ value: 'year', label: 'Year' },
]

export const STEPTYPES: any[] = [
	{ label: 'Regular', value: 'regular', icon: 'check' },
	{ label: 'As Required', value: 'required', icon: 'question' },
	{ label: 'Linked', value: 'linked', icon: 'link' },
	{ label: 'Milestone', value: 'milestone', icon: 'calendar-check' },
]

export const CONTACTTYPES: { label: string; value: string }[] = [
	{ value: 'individual', label: 'Individual' },
	{ value: 'business', label: 'Business' },
]

export const TRAININGPHASES: any[] = [
	{ phase: TrainingPhase.Uninitiated, label: 'Uninitiated', next: 'Start' },
	{ phase: TrainingPhase.Initiated, label: 'In progress', next: 'Complete' },
	{ phase: TrainingPhase.Complete, label: 'Completed', next: 'Redo' },
	{ phase: TrainingPhase.Redoing, label: 'Redoing', next: 'Start' },
]

export const WORDTOOLBAR: any[] = [
	'Separator',
	'Undo',
	'Redo',
	'Separator',
	'Image',
	'Table',
	'Hyperlink',
	'Bookmark',
	'TableOfContents',
	'Separator',
	'Header',
	'Footer',
	'PageSetup',
	'PageNumber',
	'Break',
	'InsertFootnote',
	'InsertEndnote',
	'Separator',
	'Find',
	'Separator',
	'Comments',
	'TrackChanges',
	'Separator',
	'LocalClipboard',
	'RestrictEditing',
	'Separator',
	'FormFields',
	'UpdateFields',
]

export const GENERIC_BLOCKED_REASONS: MyTasksV3Sdk.BlockedTaskReason[] = [
	MyTasksV3Sdk.BlockedTaskReason.Understanding,
	MyTasksV3Sdk.BlockedTaskReason.WrongMoment,
	MyTasksV3Sdk.BlockedTaskReason.NotMyResponsibility,
	MyTasksV3Sdk.BlockedTaskReason.WrongResources,
	MyTasksV3Sdk.BlockedTaskReason.Other,
]
