import React, { ComponentType, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Modal } from 'src/components'
import { TaskProvider, DriveFilesContext } from 'src/providers'
import { useParentFolderFiles } from 'src/components/drive-upload/use-parent-folder-files'
import { invariantDefined } from 'src/helpers/invariant'
import { actions as tableActions } from 'src/store/modules/tables'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'

import { DeleteTask } from './delete-task'
import ConfigureTask from './configure-task'

type TaskDrawerProps = {
	parentId?: string
	onRevertInstance: (instanceId: string, sectionId: string) => Promise<void>
	taskId?: string
	onClose: () => void
}

export type SectionAssignationType = {
	assigned: boolean
	assignee: string
	role: string
}

export const TaskDrawer: ComponentType<TaskDrawerProps> = ({
	parentId,
	onRevertInstance,
	taskId,
	onClose,
}) => {
	const dispatch = useDispatch()
	const [shouldPromptDelete, setShouldPromptDelete] = useState(false)
	const [_, setIsUpdating] = useState(false)
	const { parentFolderName, parentFolderId } = useContext(DriveFilesContext)
	const { fetchFiles } = useParentFolderFiles(
		invariantDefined(parentFolderId, 'parentFolderId is required'),
		parentFolderName as string,
	)
	const selectedProjectId = useSelector(projectSelectors.getSelectedProjectId)
	const selectedProjectTableId = useSelector(projectSelectors.getSelectedProjectTableId)

	const handleCloseDrawer = () => {
		setShouldPromptDelete(false)
		if (selectedProjectTableId) {
			dispatch(
				tableActions.discardAllChanges({
					projectId: selectedProjectId,
					tableId: selectedProjectTableId,
				}),
			)
		}
		onClose()
	}

	const onCancel = () => {
		if (selectedProjectTableId) {
			dispatch(
				tableActions.discardAllChanges({
					projectId: selectedProjectId,
					tableId: selectedProjectTableId,
				}),
			)
		}
		handleCloseDrawer()
	}

	const content = () => {
		if (!taskId) {
			return null
		}

		if (shouldPromptDelete) {
			return <DeleteTask onCloseDrawer={handleCloseDrawer} />
		}

		return (
			<ConfigureTask
				onDelete={() => setShouldPromptDelete(true)}
				parentId={parentId}
				setIsUpdating={setIsUpdating}
				onRevertInstance={onRevertInstance}
			/>
		)
	}

	return (
		<Modal open={!!taskId} onClose={onCancel}>
			<TaskProvider taskId={taskId} onClose={onClose} fetchFiles={fetchFiles}>
				<Box
					maxWidth="90vw"
					minWidth={1000}
					minHeight={1000}
					maxHeight="90vh"
					px={3}
					my={3}
					role="presentation"
				>
					{content()}
				</Box>
			</TaskProvider>
		</Modal>
	)
}
