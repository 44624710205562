import { TableTypes, StepTypes } from '@cango-app/sdk/types'
import _trim from 'lodash/trim'
import { useMemo } from 'react'
import _isArray from 'lodash/isArray'
import { GridFilterModel } from '@mui/x-data-grid-premium'
import { useSelector } from 'react-redux'

import {
	ColumnFilterList,
	MappedValueOptions,
	ResolvedRowData,
	selectors as tableSelectors,
} from 'src/store/modules/tables'
import { RootState } from 'src/store/types'

import { applyFilterModelToRow, columnValueGetter } from '../modules/tables/utils'

export const getListFromTable = ({
	optionsWithFilter,
	mappedColumns,
	resolvedRows,
	filterModel = {
		items: [],
	},
	mappedValueOptions,
	isBlueprint,
	columnFilterList,
}: {
	optionsWithFilter?: StepTypes.StepOptionsWithFilter
	mappedColumns: Map<string, TableTypes.Field>
	resolvedRows: ResolvedRowData[]
	filterModel?: GridFilterModel
	mappedValueOptions: MappedValueOptions
	isBlueprint?: boolean
	columnFilterList: ColumnFilterList
}): {
	_id: string
	label: string
}[] => {
	if (!optionsWithFilter?.column_options) {
		return []
	}

	const columnId = optionsWithFilter.column_options

	const field = mappedColumns.get(columnId)

	if (!field) {
		return []
	}

	const filterModelWithOptionFilters: GridFilterModel = {
		...(!isBlueprint ? optionsWithFilter.filter : {}),
		items: [
			...(filterModel.items ? filterModel.items : []),
			...(!isBlueprint && optionsWithFilter.filter?.items ? optionsWithFilter.filter.items : []),
		],
	}

	return (
		resolvedRows.reduce((_reducedOptions: { _id: string; label: string }[], _record) => {
			const resolvedRow = resolvedRows.find((row) => row._id === _record._id)
			if (!resolvedRow) {
				return _reducedOptions
			}

			const value = columnValueGetter(resolvedRow[columnId], field, mappedValueOptions)
			const valueOptionId = field.valueOptions.find((option) => option.label === value)?._id

			if (filterModelWithOptionFilters.items.length) {
				const isFiltered = applyFilterModelToRow({
					columns: columnFilterList,
					filterModel: filterModelWithOptionFilters,
					row: resolvedRow,
				})

				if (!isFiltered) {
					return _reducedOptions
				}
			}

			if (_reducedOptions.some((_reducedOption) => _reducedOption.label === value)) {
				return _reducedOptions
			}

			if (!value) {
				return _reducedOptions
			}

			return [
				..._reducedOptions,
				{
					_id: valueOptionId ?? _record._id,
					label: String(value),
					originalValue: resolvedRow[columnId],
				},
			]
		}, []) ?? []
	)
}

export const useListOptions = ({
	optionsWithFilter,
	filterModel,
	isBlueprint,
}: {
	optionsWithFilter: StepTypes.StepOptionsWithFilter
	filterModel?: GridFilterModel
	isBlueprint?: boolean
}): {
	_id: string
	label: string
	dueTime?: {
		time: string
		when: StepTypes.WhenDueTime
	}
	originalValue?: TableTypes.RecordDataItem
}[] => {
	const columnId = optionsWithFilter.column_options
	const tableId = optionsWithFilter.table_id ?? ''
	const mappedColumns = useSelector((state: RootState) =>
		tableSelectors.selectMappedColumns(state, tableId),
	)
	const resolvedRows = useSelector((state: RootState) =>
		tableSelectors.selectResolvedRowData(state, tableId),
	)
	const mappedValueOptions = useSelector((state: RootState) =>
		tableSelectors.selectValueOptions(state, tableId),
	)
	const columnFilterList = useSelector((state: RootState) =>
		tableSelectors.selectColumnFilterList(state, tableId),
	)
	return useMemo(() => {
		if (!optionsWithFilter) {
			return []
		}

		if (_isArray(optionsWithFilter.options) && !_trim(columnId)) {
			return optionsWithFilter.options
		}

		if (!resolvedRows.length) {
			return []
		}

		return getListFromTable({
			optionsWithFilter,
			mappedColumns,
			resolvedRows,
			filterModel,
			mappedValueOptions,
			isBlueprint,
			columnFilterList,
		})
	}, [optionsWithFilter, mappedColumns, filterModel, resolvedRows, isBlueprint])
}
