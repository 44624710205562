import { ComponentType, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { useNavigate } from 'react-router-dom'
import RefreshIcon from '@mui/icons-material/Refresh'
import Stack from '@mui/material/Stack/Stack'

import { selectors as projectSelectors } from 'src/store/modules/projects-v3'
import { actions as tableActions } from 'src/store/modules/tables'
import {
	selectors as persistedConfigSelectors,
	actions as persistedConfigActions,
	ProjectGroupingType,
} from 'src/store/modules/persisted-config'
import { Box, Grid, Text, Button, TabPanel, Select, IconButton } from 'src/components'
import { AnalyticsEvent, analytics } from 'src/biz'
import { RouteId } from 'src/constants/routes'
import { AsyncDispatchType, RootState } from 'src/store/types'
import { usePrevious } from 'src/hooks/usePrevious'
import { useProject } from 'src/modules/projects-v3/useProject'
import { TableContext } from 'src/providers'

import { ProjectTasks } from './project-tasks'
import { FileTable } from './file-table'
import { TaskDrawer } from './task-drawer'
import { DatabaseTable } from './database-table'
import { ProjectSettings } from './project-settings'
import { ProjectAssignments } from './project-assignments'

import 'src/assets/css/Task.css'

type ProjectComponentType = {
	onRevertInstance: (instanceId: string, sectionId: string) => Promise<void>
	selectedTaskId?: string
}

enum TABS {
	TASKS = 'show_tasks',
	DATABASE = 'show_database',
	FILES = 'view_files',
	SETTINGS = 'project_settings',
	ASSIGNMENTS = 'project_assignments',
}

export const ProjectComponent: ComponentType<ProjectComponentType> = ({
	onRevertInstance,
	selectedTaskId,
}) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const project = useSelector(projectSelectors.getSelectedProject)
	const tableId = useSelector(projectSelectors.getSelectedProjectTableId)

	const projectGrouping = useSelector((state: RootState) =>
		persistedConfigSelectors.getProjectGroupingConfig(state, project?._id ?? ''),
	)
	const navigate = useNavigate()
	const previousProjectGrouping = usePrevious(projectGrouping)
	const [isProjectGroupingLoading, setIsProjectGroupingLoading] = useState(false)
	const { goToTask } = useProject()
	const { tableConfig } = useContext(TableContext)

	// const showSettings = userPermissions?.routes.includes('project-settings')
	const showSettings = true

	const [currentTab, setCurrentTab] = useState<TABS>(TABS.TASKS)

	const handleTabChange = (_e: any, newTab: TABS) => {
		analytics.track({
			eventName: AnalyticsEvent.buttonClicked,
			properties: {
				name: newTab,
				screen: RouteId.Project,
			},
		})

		setCurrentTab(newTab)
	}

	const toggleGrouping = (grouping: ProjectGroupingType) => {
		setIsProjectGroupingLoading(true)
		dispatch(
			persistedConfigActions.setProjectGrouping({
				projectId: project?._id ?? '',
				groupingType: grouping,
			}),
		)
	}

	const fetchTable = (_tableId: string) => {
		dispatch(
			tableActions.fetchTable({
				newTableId: _tableId,
				forceFetch: true,
			}),
		)
	}

	useEffect(() => {
		if (previousProjectGrouping && previousProjectGrouping !== projectGrouping) {
			setTimeout(() => {
				navigate(0)
			}, 1000)
		}
	}, [previousProjectGrouping, projectGrouping])

	return (
		<>
			<TaskDrawer onRevertInstance={onRevertInstance} taskId={selectedTaskId} onClose={goToTask} />
			<Grid container>
				<Grid xs={2} />
				<Grid xs={8}>
					<Text variant="h3" my={3} textAlign="center">
						{project?.name}
					</Text>
				</Grid>
			</Grid>
			{!!project?.archived.state && (
				<Box display="flex" justifyContent="center">
					<Alert
						severity="warning"
						sx={{
							width: 600,
						}}
						action={
							<Button
								size="small"
								onClick={() => setCurrentTab(TABS.SETTINGS)}
								variant="text"
								color="warning"
							>
								Go to settings
							</Button>
						}
					>
						<AlertTitle>Project Archived</AlertTitle>
						You can restore this project from the settings panel
					</Alert>
				</Box>
			)}

			<Grid container>
				<Grid item xs={10}>
					<Tabs sx={{ paddingX: 4 }} value={currentTab} onChange={handleTabChange}>
						<Tab
							value={TABS.TASKS}
							label="tasks"
							id={`tab-${TABS.TASKS}`}
							aria-controls={`tabpanel-${TABS.TASKS}`}
						/>
						{tableId ? (
							<Tab
								value={TABS.DATABASE}
								label="database"
								id={`tab-${TABS.DATABASE}`}
								aria-controls={`tabpanel-${TABS.DATABASE}`}
							/>
						) : null}
						{project ? (
							<Tab
								value={TABS.FILES}
								label="files"
								id={`tab-${TABS.FILES}`}
								aria-controls={`tabpanel-${TABS.FILES}`}
							/>
						) : null}
						{showSettings ? (
							<Tab
								value={TABS.ASSIGNMENTS}
								label="assignments"
								id={`tab-${TABS.ASSIGNMENTS}`}
								aria-controls={`tabpanel-${TABS.ASSIGNMENTS}`}
							/>
						) : null}
						{showSettings ? (
							<Tab
								value={TABS.SETTINGS}
								label="settings"
								id={`tab-${TABS.SETTINGS}`}
								aria-controls={`tabpanel-${TABS.SETTINGS}`}
							/>
						) : null}
					</Tabs>
				</Grid>
				<Grid item display="flex" justifyContent="flex-end" xs={2}>
					<Stack sx={{ mr: 4 }} direction="row" alignItems="center">
						<Select
							label="Group by"
							options={[
								{
									label: 'Tags',
									_id: ProjectGroupingType.Tags,
								},
								{
									label: 'Sections',
									_id: ProjectGroupingType.Sections,
								},
							]}
							value={projectGrouping}
							disableOrdering
							onChange={(e) => toggleGrouping(e.target.value as ProjectGroupingType)}
							containerProps={{ width: 300 }}
							isLoading={isProjectGroupingLoading}
						/>
						{!!tableConfig?._id && (
							<IconButton onClick={() => fetchTable(tableConfig._id)}>
								<RefreshIcon />
							</IconButton>
						)}
					</Stack>
				</Grid>
			</Grid>
			<TabPanel value={currentTab} index={TABS.TASKS}>
				<ProjectTasks />
			</TabPanel>
			{tableId && (
				<TabPanel value={currentTab} index={TABS.DATABASE}>
					<DatabaseTable />
				</TabPanel>
			)}

			{!!project && (
				<TabPanel value={currentTab} index={TABS.FILES}>
					<FileTable projectId={project._id} driveFolderId={project.google_drive_folder_id} />
				</TabPanel>
			)}

			{showSettings && (
				<TabPanel value={currentTab} index={TABS.ASSIGNMENTS}>
					<ProjectAssignments />
				</TabPanel>
			)}
			{showSettings && (
				<TabPanel value={currentTab} index={TABS.SETTINGS}>
					<ProjectSettings />
				</TabPanel>
			)}
		</>
	)
}
