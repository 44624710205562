import { ComponentType } from 'react'

import { Select } from 'src/components'

import { DependencyType } from './types'

export const DependencyTypeSelection: ComponentType<{
	onChange: (newDependencyType: DependencyType) => void
	value: DependencyType
}> = ({ onChange, value }) => {
	return (
		<Select
			label="I want the next task to start:"
			onChange={(e) => onChange(e.target.value as DependencyType)}
			fullWidth
			containerProps={{
				width: '100%',
				mb: 4,
			}}
			value={value}
			options={[
				{
					_id: DependencyType.WHEN_THIS_TASK_COMPLETES,
					label: 'When my task completes',
				},
				{
					_id: DependencyType.WHEN_AN_OPTION_SELECTED,
					label: 'When an option is selected',
				},
			]}
		/>
	)
}
