import { ComponentType, useState } from 'react'
import { TaskTypes } from '@cango-app/sdk/types'

import { Box, Button, Divider, Modal, TextField } from 'src/components'
import { colors } from 'src/theme/colors'

import { TaskTitle } from '../../../components/task-title'

type CreateInstanceModalProps = {
	open: boolean
	onClose: () => void
	task: TaskTypes.PopulatedTask
	onSubmit: (instanceName: string) => Promise<void>
}

export const CreateInstanceModal: ComponentType<CreateInstanceModalProps> = ({
	open,
	onClose,
	task,
	onSubmit,
}) => {
	const [newInstanceName, setNewInstanceName] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	const handleOnSubmit = async () => {
		setIsLoading(true)
		await onSubmit(newInstanceName)
		setIsLoading(false)
		setNewInstanceName('')
	}

	return (
		<Modal
			open={open}
			containerStyle={{
				width: 660,
				maxWidth: '100%',
				bgcolor: colors.background.modal,
			}}
			onClose={onClose}
		>
			<Box width="100%" p={1}>
				<TaskTitle task={task} containerProps={{ flex: 1 }} fontSize={18} iconWidth={34} />
				<Divider sx={{ my: 2 }} />
				<Box width="100%">
					<TextField
						label="Instance name"
						placeholder="Street # 2"
						fullWidth
						sx={{ mb: 2 }}
						value={newInstanceName}
						onChange={(e) => setNewInstanceName(e.target.value)}
					/>
					<Box display="flex" flexDirection={{ mobile: 'column', laptop: 'row' }}>
						<Button sx={{ flex: 1, mr: { laptop: 1 } }} variant="text" onClick={onClose}>
							Cancel
						</Button>
						<Button
							sx={{ flex: 1, ml: { laptop: 1 } }}
							disabled={!newInstanceName}
							onClick={handleOnSubmit}
							isLoading={isLoading}
						>
							Create instance
						</Button>
					</Box>
				</Box>
			</Box>
		</Modal>
	)
}
