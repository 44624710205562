import { StepTypes } from '@cango-app/sdk/types'
import React, { ComponentType, useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useListOptions } from 'src/hooks/use-list-options'
import { ChainContext } from 'src/modules/chains/chain-provider'
import { Box, GroupedSelect, Select } from 'src/components'

import { DependencyForm } from './types'

type OptionConditionProps = {
	source: string
}

export const OptionConditions: ComponentType<OptionConditionProps> = ({ source }) => {
	const { control, setValue, watch } = useFormContext<DependencyForm>()
	const { groupListedNodes, nodeMap } = useContext(ChainContext)
	const fromNodeId = watch('option_condition.from') ?? source
	const fromNode = nodeMap.get(fromNodeId)
	const listOptions = useListOptions({
		optionsWithFilter: fromNode?.data.complete_options ?? {
			options: [],
			column_options: undefined,
			table_id: undefined,
		},
		isBlueprint: true,
	})

	const isMenu = !!fromNode?.data.isMenu
	const isAnySelected = watch('option_condition.operator') === StepTypes.ChildConditionOperator.Any

	return (
		<Box mb={4}>
			<Controller
				control={control}
				name="option_condition.from"
				render={({ field: { value, onChange } }) => {
					return (
						<GroupedSelect
							label="When the options from"
							value={value ?? source}
							defaultValue={source}
							onChange={(e) => {
								onChange(e.target.value)
							}}
							options={groupListedNodes}
							containerProps={{
								mb: 0.5,
								maxWidth: 600,
							}}
						/>
					)
				}}
			/>
			<Controller
				control={control}
				name="option_condition.operator"
				render={({ field: { value, onChange } }) => {
					const handleChangeValue = (newValue: StepTypes.ChildConditionOperator) => {
						if (newValue === StepTypes.ChildConditionOperator.Any) {
							setValue('option_condition.values', [])
						}
						onChange(newValue)
					}
					return (
						<Select
							value={value}
							onChange={(e) =>
								handleChangeValue(e.target.value as StepTypes.ChildConditionOperator)
							}
							options={[
								{
									_id: StepTypes.ChildConditionOperator.Is,
									label: isMenu ? 'includes' : 'is',
								},
								{
									_id: StepTypes.ChildConditionOperator.IsNot,
									label: isMenu ? 'does not include' : 'is not',
								},
								{
									_id: StepTypes.ChildConditionOperator.Any,
									label: 'is anything',
								},
							]}
						/>
					)
				}}
			/>
			{!isAnySelected && (
				<Controller
					control={control}
					name="option_condition.values"
					render={({ field: { value: selectedOptions, onChange: onSelectedOptionsChange } }) => (
						<Select
							options={listOptions}
							value={selectedOptions}
							multiple={isMenu}
							disabled={isAnySelected}
							onChange={(event) => {
								if (!isMenu) {
									onSelectedOptionsChange([event.target.value as string])
									return
								}
								onSelectedOptionsChange(event.target.value as string[])
							}}
							containerProps={{ maxWidth: 600 }}
							helperText={
								!isMenu
									? 'The parent task only allows one option to be selected'
									: 'Multiple options can be selected'
							}
						/>
					)}
				/>
			)}
		</Box>
	)
}
